import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setChatbotLoader,
  setChatbotPage,
  setChatbotDetails,
  setChatbotCount,
} from "../reducers/chatbotSlice";
import { AppThunk } from "../store";
import { langMap } from "../../utils/lang";

export const fetchChatbotsList =
  (
    page: number,
    type: string,
    filterId: string,
    searchText: string,
    sortOrder: string,
    sortColumn: string,
    languageFilter: any
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setChatbotLoader(true));
      let url = `/bots?page=${page + 1}&size=15&type=${type}`;
      if (searchText) {
        url += `&search=${searchText}`;
      }
      if (filterId) {
        url += `&status=${filterId}`;
      }
      if (languageFilter.length > 0) {
        url += `&lang=${languageFilter.join(",")}`;
      }
      if (sortColumn && sortOrder) {
        url += `&sortBy=${sortColumn}&orderBy=${sortOrder}`;
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;

      if (data?.bots.length === 0 && data?.count > 0 && page > 0) {
        dispatch(setChatbotPage(0));
        return;
      }

      const newBots = data?.bots?.map((bot: any) => {
        return {
          id: bot?.id,
          name: bot?.name,
          externalName: bot?.externalName,
          type: bot?.type,
          creator: bot?.creator,
          langLabel: langMap[bot?.lang] || "English",
          lang: bot?.lang || "en",
          updatedAt: DateTime.fromISO(bot?.updatedAt).toFormat(
            "dd'-'LL'-'yyyy"
          ),
          status: bot?.status,
          exitLessonId: bot?.exitLessonId || null,
          exitLesson: bot?.exitLesson || null,
          exitBotId: bot?.exitBotId || null,
          exitBot: bot?.exitBot || null,
          exitLessonDelay: bot?.exitLessonDelay || 0,
          exitBotDelay: bot?.exitBotDelay || 0,
          imageUrl: bot?.imageUrl || "",
          isVisible: bot?.isVisible || false,
          userType: bot?.userType || 0,
        };
      });

      dispatch(setChatbotDetails(newBots));
      dispatch(setChatbotCount(data?.count));
      dispatch(setChatbotLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setChatbotLoader(false));
    }
  };
