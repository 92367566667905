import { Box, Typography } from "@mui/material";
import { useDrag } from "react-dnd";
import { CMSIconMap } from "../../CMSTypes";
import { TypeCard } from "../styles";

type Props = {
  card: any;
};

const CMSType = (props: Props) => {
  const { card } = props;

  const [, dragRef] = useDrag(() => {
    return {
      type: card.type,
      item: {
        type: card.type,
      },
    };
  }, [card]);

  const Icon = CMSIconMap[card.type];
  return (
    <Box key={card.key + "card-details"} ref={dragRef} sx={TypeCard}>
      <Box sx={{ mb: 1 }}>
        <Icon />
      </Box>
      <Typography variant="subtitle1" fontWeight={"medium"}>
        {card.name}
      </Typography>
      <Typography variant="body1" fontWeight={"regular"}>
        {card.description}
      </Typography>
    </Box>
  );
};

export default CMSType;
