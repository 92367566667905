import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { resetState } from "../../Redux/actions/resetAction";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { initializeFirebaseApp } from "../../utils/firebase";
import http from "../../utils/http";

const Header = () => {
  const hasAdminAccess = useAppSelector((state) => state.user.hasAdminAccess);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (hasAdminAccess) {
      initializeFirebaseApp();
    }
  }, [hasAdminAccess]);

  const logout = async () => {
    try {
      await http.post("/auth/logout");
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      dispatch(resetState());
    }
  };

  return (
    <Box
      sx={{
        height: "75px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E5E7EB",
        padding: "0px 30px",
      }}
    >
      <IconButton onClick={handleClick} sx={{ ml: "auto" }}>
        <Avatar sx={{ width: 40, height: 40 }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;
