import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { HeaderLeftContent, StyledHeader } from "../Common/styles/header";

const PasswordRequest = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [rowId, setRowId] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res = await http.get(
          `/doctors/password-requests?size=${pageSize}&page=${page + 1}`
        );
        setTotal(res.data?.data?.count);
        const requests = res.data?.data?.rows?.map((request: any) => {
          return {
            id: request?.id,
            expiresAt: request?.expiresAt,
            code: request?.code,
            doctor: request?.user?.code || "-",
          };
        });
        setData(requests);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setPage, setData, page, toggleLoader]);

  const regenerateCode = async (id: string) => {
    try {
      setRowId(id);
      setButtonLoader(true);
      await http.post(`/doctors/password-requests/${id}/regenerate`);
      toastMessage("success", "Code regenerated successfully");
      setButtonLoader(false);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Password Requests
          </Typography>
        </Box>
      </StyledHeader>
      <Box sx={{ p: 2.5, height: "calc(100% - 85px)", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Doctor Number</StyledTableCell>
              <StyledTableCell>Code</StyledTableCell>
              <StyledTableCell>Expires At</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && data.length > 0 && (
            <>
              <TableBody>
                {data?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell>{row?.doctor}</StyledTableCell>
                    <StyledTableCell>{row?.code}</StyledTableCell>
                    <StyledTableCell>
                      {DateTime.fromISO(row?.expiresAt).toFormat(
                        "dd'-'LL'-'yyyy hh:mm a"
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        sx={{ borderRadius: 2, textTransform: "capitalize" }}
                        onClick={() => regenerateCode(row?.id)}
                        endIcon={
                          buttonLoader &&
                          rowId === row?.id && (
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                          )
                        }
                        disabled={buttonLoader && rowId === row?.id}
                      >
                        Regenerate Code
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {total > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={total}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {!loading && data.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default PasswordRequest;
