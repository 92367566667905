import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setCmsType } from "../../Redux/reducers/cmsSlice";
import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../Common/UI/TabPanel";
import CMSHeader from "./CMSHeader";
import CMSUrlLoader from "./CMSUrlLoader";
import CMSUrlSetter from "./CMSUrlSetter";
import EducationModules from "./Modules/EducationModule.tsx/EducationModules";
import LearningModules from "./Modules/LearningModule/LearningModules";

const CMS = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded } = useAppSelector((state) => state.cms);

  const handleChange = (_: any, newValue: string) => {
    dispatch(setCmsType(newValue));
  };

  return urlLoaded ? (
    <>
      <CMSHeader />
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Learning Modules"
            value="learning"
            {...a11yProps(0)}
          />
          <StyledTab
            label="Education Modules"
            value="education"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
          bgcolor: "#fafafa",
        }}
      >
        <MainTabPanel value={type} index={"learning"}>
          <LearningModules />
        </MainTabPanel>
        <MainTabPanel value={type} index={"education"}>
          <EducationModules />
        </MainTabPanel>
      </Box>
      <CMSUrlSetter />
    </>
  ) : (
    <CMSUrlLoader />
  );
};

export default CMS;
