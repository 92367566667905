import { SxProps } from "@mui/material";

export const pathElement: SxProps = {
  flex: 1,
  minWidth: "1px",
  p: 2,
  backgroundColor: "#FAFAFA",
  mb: 2,
};

export const pathHeader: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const PathFormElement: SxProps = {
  display: "flex",
  alignItems: "center",
  mb: 2,
};
