import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosResponse } from "axios";

import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  refreshAdministratorsPage,
  setAdministratorsPage,
  setAdministratorsSort,
} from "../../../Redux/reducers/administratorsSlice";
import { fetchAdministratorsList } from "../../../Redux/actions/administratorsAction";

const AdminList = () => {
  const dispatch = useAppDispatch();
  const {
    loading,
    administratorsData,
    totalAdministrators,
    page,
    type,
    searchText,
    sortOrder,
    sortColumn,
    toggleLoader,
  } = useAppSelector((state) => state.administrators);

  const [menuLoader, setMenuLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(
      fetchAdministratorsList(page, type, sortColumn, sortOrder, searchText)
    );
  }, [dispatch, page, type, sortOrder, sortColumn, searchText, toggleLoader]);

  const handleChangePage = (_1: any, newPage: number) => {
    dispatch(setAdministratorsPage(newPage));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setAdministratorsSort({ column: "", order: "" }));
    } else {
      dispatch(setAdministratorsSort({ column, order }));
    }
  };

  const modifyUser = async (data: any) => {
    try {
      setMenuLoader(true);
      const newData = {
        status: data?.status === "inactive" ? "active" : "inactive",
      };
      const res: AxiosResponse = await http.patch(
        `/admins/${data?.id}`,
        newData
      );
      handleMenuClose();
      setMenuLoader(false);
      dispatch(refreshAdministratorsPage());
      toastMessage("success", res.data.message);
    } catch (err) {
      setMenuLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {type === "doctor" ? (
              <StyledTableCell>Study Number</StyledTableCell>
            ) : (
              <StyledTableCell>
                Name
                <SortLabel column="firstName" />
              </StyledTableCell>
            )}
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && administratorsData.length > 0 && (
          <>
            <TableBody>
              {administratorsData?.map((row: any) => (
                <TableRow key={row?.id}>
                  {type === "doctor" ? (
                    <StyledTableCell>{row?.studyNumber}</StyledTableCell>
                  ) : (
                    <StyledTableCell
                      sx={{ display: "flex", gap: 2, alignItems: "center" }}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          fontSize: 14,
                          bgcolor: "lightgray",
                          color: "#000",
                        }}
                      >
                        {row?.firstName &&
                          row?.firstName.charAt(0) + row?.lastName.charAt(0)}
                      </Avatar>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography component={"span"} variant="subtitle1">
                          {row?.firstName + " " + row?.lastName}
                        </Typography>
                        <Typography
                          component={"span"}
                          variant="body1"
                          color="#4B5563"
                        >
                          {row?.email}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  )}
                  <StyledTableCell sx={{ textTransform: "capitalize" }}>
                    {row?.status}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      onClick={(e) => {
                        handleMenuClick(e, row);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalAdministrators > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalAdministrators}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && administratorsData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem onClick={() => modifyUser(selectedRow)} disabled={menuLoader}>
          <ListItemText>
            {selectedRow?.status === "inactive" ? "Restore" : "Archive"}
          </ListItemText>
          {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={20} sx={{ ml: 1.5 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AdminList;
