import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import * as yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { DualInput } from "./settings.style";
import { inactivityDataInterface } from "./Settings";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: inactivityDataInterface[];
  refreshPage: () => void;
  type: string;
  title: string;
};

const EditInactivitySettingsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
  type,
  title,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState<inactivityDataInterface[]>(
    JSON.parse(JSON.stringify(data))
  );

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      for (let i = 0; i < formData.length; i++) {
        const title = formData[i].title;
        const body = formData[i].body;
        const days = formData[i].days;
        let schema = yup
          .number()
          .min(1, formData[i].label + " days should be greater than zero")
          .typeError(formData[i].label + " days should be a valid number");
        await schema.validate(days);
        if (!title || !title.trim()) {
          toastMessage("warning", formData[i].label + " title cannot be empty");
          setButtonLoader(false);
          return;
        }
        if (!body || !body.trim()) {
          toastMessage("warning", formData[i].label + " body cannot be empty");
          setButtonLoader(false);
          return;
        }
      }
      const res: AxiosResponse = await http.put(
        `/app/settings/${type}-inactivity-reminder`,
        {
          values: formData.map((item) => {
            return {
              title: item.title,
              body: item.body,
              days: item.days,
              lang: item.lang,
            };
          }),
        }
      );
      toastMessage("success", res?.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string,
    label: string
  ) => {
    setFormData((formData) => {
      return formData.map((item) => {
        if (item.key === key) {
          const newItem = {
            ...item,
            [label]: event.target.value,
          };
          return newItem;
        } else {
          return item;
        }
      });
    });
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={`Edit ${title} Inactivity Settings`}
          onCloseClick={closeModal}
        />
        {formData.map((item) => {
          return (
            <div key={item.key}>
              <FormControl sx={{ ...InputWrapper, mb: 2 }} key={"title"}>
                <FormLabel sx={LabelStyle}>{item.label + " Title"}</FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    placeholder="Value"
                    value={item.title}
                    onChange={(e) => changeHandler(e, item.key, "title")}
                  />
                </Box>
              </FormControl>
              <FormControl sx={{ ...InputWrapper, mb: 2 }} key={"body"}>
                <FormLabel sx={LabelStyle}>{item.label + " Body"}</FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    placeholder="Value"
                    value={item.body}
                    onChange={(e) => changeHandler(e, item.key, "body")}
                  />
                </Box>
              </FormControl>
              <FormControl sx={{ ...InputWrapper, mb: 2 }} key={"Days"}>
                <FormLabel sx={LabelStyle}>{item.label + " Days"}</FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    placeholder="Value"
                    value={item.days}
                    onChange={(e) => changeHandler(e, item.key, "days")}
                    type="number"
                  />
                </Box>
              </FormControl>
            </div>
          );
        })}

        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditInactivitySettingsModal;
