import { SxProps } from "@mui/material";

export const PreviewContainer: SxProps = {
  height: "100%",
  width: "100%",
  padding: "50px",
  background: "#ebf5ff",
  display: "flex",
  position: "relative",
  alignItems: "flex-start",
};

export const PinkBg: SxProps = {
  background: "rgba(252, 236, 255, 0.9)",
  borderRadius: "50%",
  width: "100vh",
  height: "100vh",
  position: "absolute",
  top: "-35vh",
  left: "-10vw",
  zIndex: 1,
};

export const StaticContentWrapper: SxProps = {
  maxWidth: "60%",
  zIndex: 2,
  position: "relative",
  top: "30%",
};

export const ChatWrapper: SxProps = {
  padding: "10px",
  flex: 1,
  minWidth: "1px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  height: "100%",
};

export const UserResponseStyle: SxProps = {
  maxWidth: "60%",
  alignSelf: "flex-end",
  padding: "11px 19px",
  color: "#ffffff",
  background: "#164642",
  borderRadius: "16px 4px 16px 16px",
  marginBottom: "20px",
  "& .bot-image": {
    maxWidth: "200px",
    maxHeight: "200px",
    objectFit: "contain",
    marginBottom: "10px",
  },
};

export const BotMessageWrapper: SxProps = {
  maxWidth: "60%",
  marginBottom: "10px",
  padding: "16px",
  background: "#ffffff",
  border: "2px solid rgba(45, 156, 219, 0.1)",
  boxSizing: "border-box",
  borderRadius: "4px 16px 16px 16px",
  fontWeight: 500,
  fontSize: "17px",
  lineHeight: "22px",
  "& .bot-image": {
    maxWidth: "200px",
    maxHeight: "200px",
    objectFit: "contain",
    marginBottom: "10px",
  },
  "& .bot-video": {
    maxWidth: "100%",
    maxHeight: "250px",
    marginBottom: "10px",
  },
};

export const BotOptionWrapper: SxProps = {
  alignSelf: "flex-end",
  marginTop: "20px",
  maxWidth: "60%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

export const BotOptionsStyle: SxProps = {
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  padding: "11px 19px",
  background: "#164642",
  borderRadius: "8px",
  color: "#ffffff",
  mb: 2,
  "& .bot-image": {
    width: "200px",
    height: "200px",
    objectFit: "contain",
    mr: 1.25,
  },
};
