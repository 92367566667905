import {
  Star,
  ChevronLeft,
  Add,
  Visibility,
  Edit,
  Delete,
  Publish,
  IosShare,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../Common/styles/header";
import {
  ContainerStyle,
  LoadingContainer,
  ModuleCardsContainer,
  ModulesHeaderContainer,
} from "../../cms.style";
import AddUnitModal from "../../modals/AddUnitModal";
import RearrangeCMSModal from "../../modals/RearrangeCMSModal";
import EducationModuleCard from "./EducationModuleCard";
import { createNewUnits, downloadFile } from "../../../../utils/education";

const Units = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [toggleLoader, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publishLoading, setPublishLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [lessonData, setLessonData] = useState<any>({});
  const [lessonUnits, setLessonUnits] = useState<any>([]);
  const [showRearrangeModal, setRearrangeModal] = useState(false);
  const [unitId, setUnitId] = useState("");
  const [selectedUnit, setSelectedUnit] = useState<any>();

  const closeAddUnitModal = () => {
    closeMenu();
    setUnitId("");
  };

  const closeRearrangeModal = () => {
    setRearrangeModal(false);
  };

  const callback = () => {
    setToggle((pre) => !pre);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { units } = lessonData;

  const modifySearchTerm = (val: string) => setSearchTerm(val);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, unit: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedUnit(unit);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedUnit(null);
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (units && units.length) {
      if (searchTerm) {
        const newUnits = units.filter((item: any) => {
          return item.title.toLowerCase().includes(searchTerm);
        });
        setLessonUnits(newUnits);
      } else {
        setLessonUnits([...units]);
      }
    } else {
      setLessonUnits([]);
    }
  }, [searchTerm, units]);

  useEffect(() => {
    const fetchLessonDetails = async (id: string) => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/lms/lessons/${id}`);
        const lesson = res.data.data;
        lesson?.units.sort((a: any, b: any) => {
          return a.position - b.position;
        });
        lesson.units = lesson?.units.map((unit: any, index: number) => {
          return {
            ...unit,
            position: index + 1,
          };
        });
        setLessonData(lesson);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchLessonDetails(id);
    }
  }, [id, toggleLoader]);

  const handleNavigate = (unitId: string) => {
    navigate(`/app/cms/units/${id}?unitId=${unitId}`);
  };

  const viewReview = () => {
    navigate(`/app/cms/review/${id}?lesson=${lessonData?.name}`);
  };

  const publishModule = async () => {
    try {
      setPublishLoading(true);
      let body = {
        name: lessonData.name,
        description: lessonData.description,
        imageUrl: lessonData.imageUrl,
        status: "active",
      };
      if (!body.imageUrl) {
        delete body.imageUrl;
      }
      if (!body.description) {
        delete body.description;
      }
      const res: AxiosResponse = await http.put(`/lms/lessons/${id}`, body);
      toastMessage("success", res.data.message);
      setPublishLoading(false);
      setToggle((prev) => !prev);
    } catch (err) {
      setPublishLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const deleteUnit = async () => {
    try {
      const unitId = selectedUnit.id;
      closeMenu();
      setLoading(true);
      const res = await http.delete(`/lms/lessons/${id}/units/${unitId}`);
      toastMessage("success", res.data.message);
      setToggle((prev) => !prev);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const importUnit = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          var result = JSON.parse(JSON.stringify(e.target.result));
          await createNewUnits([JSON.parse(result)], id);
          setLoading(false);
          toastMessage("success", "Unit created successfully");
          setToggle((prev) => !prev);
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      };
      reader.readAsText(file);

      return false;
    }
  };

  const exportUnit = async (uid: string) => {
    try {
      closeMenu();
      setLoading(true);
      const res: AxiosResponse = await http.get(
        `/lms/lessons/${id}/units/${uid}`
      );
      await downloadFile(res.data?.data);
      setLoading(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setLoading(false);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ChevronLeft
              onClick={handleBack}
              sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
            />
            <Typography fontSize={24} fontWeight="medium">
              {lessonData?.name}
            </Typography>
          </Box>
          <TextField
            onChange={(e) => modifySearchTerm(e.target.value)}
            style={{ width: "300px" }}
            placeholder="Search Units"
            defaultValue={searchTerm}
          />
        </Box>
        {lessonData?.status !== undefined && (
          <Box sx={HeaderRightContent}>
            {lessonData?.status !== "draft" && (
              <>
                <Button
                  variant="outlined"
                  startIcon={<Star />}
                  onClick={viewReview}
                >
                  View Reviews
                </Button>
              </>
            )}
            {lessonData?.status !== "active" &&
              (publishLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<Publish />}
                  onClick={publishModule}
                >
                  Publish
                </Button>
              ))}
            {lessonData?.status === "draft" && units?.length > 1 && (
              <Button
                variant="outlined"
                onClick={() => setRearrangeModal(true)}
              >
                Rearrange
              </Button>
            )}
            {lessonData?.status === "draft" && (
              <>
                <Button variant="outlined" component="label">
                  Import
                  <input
                    hidden
                    accept="application/json"
                    type="file"
                    onChange={importUnit}
                  />
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => setUnitId("new")}
                >
                  Add Unit
                </Button>
              </>
            )}
          </Box>
        )}
      </StyledHeader>
      {!loading ? (
        <Box sx={ContainerStyle}>
          <Box sx={ModulesHeaderContainer}>
            <Typography variant="subtitle1" fontWeight={600}>
              All {lessonUnits.length} units
            </Typography>
          </Box>
          {lessonUnits.length > 0 ? (
            <Box sx={ModuleCardsContainer}>
              {lessonUnits.map((unit: any, index: number) => (
                <EducationModuleCard
                  key={unit?.id}
                  index={index}
                  data={unit}
                  handleNavigate={handleNavigate}
                  type="unit"
                  openMenu={openMenu}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "10vh",
              }}
            >
              <Typography variant="h2">
                {searchTerm ? "No matches found" : "No Units present"}
              </Typography>
            </Box>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                minWidth: "225px",
              },
            }}
          >
            {/* {lessonData?.status === "draft" && ( */}
            <MenuItem
              onClick={() => {
                setUnitId(selectedUnit.id);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            {/* )} */}
            <MenuItem onClick={() => exportUnit(selectedUnit?.id)}>
              <ListItemIcon>
                <IosShare fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem>
            {lessonData?.status === "draft" && <Divider />}
            {lessonData?.status === "draft" && (
              <MenuItem onClick={deleteUnit}>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
            {/* {lessonData?.status !== "draft" && (
              <MenuItem
                onClick={() => {
                  setUnitId(selectedUnit.id);
                }}
              >
                <ListItemIcon>
                  <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            )} */}
          </Menu>
          {showRearrangeModal && (
            <RearrangeCMSModal
              callback={callback}
              closeModal={closeRearrangeModal}
              parent="lesson"
              parentId={id || ""}
              items={units}
              title="Rearrange Units"
            />
          )}
          {unitId && (
            <AddUnitModal
              callback={callback}
              closeModalHandler={closeAddUnitModal}
              unitId={unitId}
              lessonId={id || ""}
              unit={selectedUnit}
              viewOnly={false}
              lang={lessonData?.lang}
            />
          )}
        </Box>
      ) : (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Units;
