import { ChangeEvent, useMemo, useState } from "react";
import { Box, Button, debounce, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setChatbotLoader,
  setChatbotSearchText,
} from "../../Redux/reducers/chatbotSlice";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import CreateChatbotModal from "./CreateChatbotModal";
import OnboardingSetupModal from "./OnboardingSetupModal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { importChat } from "../../utils/chatbotBuilder";
import FaqSetupModal from "./FaqSetupModal";

const ChatbotHeader = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showOnboardModal, setShowOnboardModal] = useState(false);
  const dispatch = useAppDispatch();
  const { type, searchText } = useAppSelector((state) => state.chatbot);
  const navigate = useNavigate();

  const viewBrokenLink = () => {
    navigate(`/app/chatbot/broken-link`);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setChatbotSearchText(val));
      }, 500),
    [dispatch]
  );

  const handleCreateClick = () => {
    setShowCreateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const openSetupModal = () => {
    setShowSetupModal(true);
  };

  const closeSetupModal = () => {
    setShowSetupModal(false);
  };

  // const handleOnboardClick = () => {
  //   setShowOnboardModal(true);
  // };

  const closeOnboardModal = () => {
    setShowOnboardModal(false);
  };

  const viewOnboardingBuilder = () => {
    navigate("/app/onboarding");
  };

  const importChatHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch(setChatbotLoader(true));
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          var result = JSON.parse(JSON.stringify(e.target.result));
          const id = await importChat(JSON.parse(result), type);
          dispatch(setChatbotLoader(false));
          if (id) {
            toastMessage("success", "Chatbot created successfully");
            navigate(`/app/chatbot/builder/${id}`);
          }
        } catch (err) {
          dispatch(setChatbotLoader(false));
          errorToastMessage(err as Error);
        }
      };
      reader.readAsText(file);

      return false;
    }
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Chatbot
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Chatbot"
          defaultValue={searchText}
          key={type}
        />
      </Box>
      <Box sx={HeaderRightContent}>
        <Button variant="outlined" component="label">
          Import
          <input
            hidden
            accept="application/json"
            type="file"
            onChange={importChatHandler}
          />
        </Button>
        <Button variant="contained" onClick={viewBrokenLink}>
          View Broken link
        </Button>
        <Button variant="contained" onClick={viewOnboardingBuilder}>
          Onboarding setup
        </Button>
        <Button variant="contained" onClick={handleCreateClick}>
          {"Create new " + (type === "faq" ? "FAQ" : "chatbot")}
        </Button>
        {type === "faq" && (
          <Button variant="contained" onClick={openSetupModal}>
            FAQ Start Setup
          </Button>
        )}
      </Box>
      {showCreateModal && (
        <CreateChatbotModal
          type={type}
          showModal={showCreateModal}
          closeModal={closeCreateModal}
        />
      )}
      {showOnboardModal && (
        <OnboardingSetupModal
          showModal={showOnboardModal}
          closeModal={closeOnboardModal}
        />
      )}
      {showSetupModal && (
        <FaqSetupModal
          showModal={showSetupModal}
          closeModal={closeSetupModal}
        />
      )}
    </StyledHeader>
  );
};

export default ChatbotHeader;
