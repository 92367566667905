import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OnboardingQuestion,
  onboardingQuestionType,
} from "../../types/onboarding";
import {
  cloneQuestion,
  createQuestion,
  createNewOption,
  modifySubquestion,
} from "../../utils/onboarding";

export interface OnboardingState {
  activeQuestionIndex: number;
  questions: OnboardingQuestion[];
  loading: boolean;
}

const initialState: OnboardingState = {
  activeQuestionIndex: 0,
  questions: [],
  loading: false,
};

export const onboardingBuilderSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    setActiveIndex: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      if (action.payload.index === state.activeQuestionIndex) {
        return;
      }
      if (action.payload.index < state.questions.length) {
        state.activeQuestionIndex = action.payload.index;
      } else {
        state.activeQuestionIndex = state.questions.length - 1;
      }
    },
    setOnboardingState: (
      state,
      action: PayloadAction<{
        questions: OnboardingQuestion[];
        loading: boolean;
      }>
    ) => {
      state.questions = action.payload.questions;
      state.loading = action.payload.loading;
    },
    setOnboardingLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addQuestion: (
      state,
      action: PayloadAction<{
        type: onboardingQuestionType;
        subquestion?: boolean;
      }>
    ) => {
      const newQuestion = createQuestion(action.payload.type);
      if (action.payload.subquestion) {
        state.questions[state.activeQuestionIndex]?.childSteps?.push(
          newQuestion
        );
      } else {
        state.questions.push(newQuestion);
        state.activeQuestionIndex = state.questions.length - 1;
      }
    },
    duplicateQuestion: (state) => {
      const newQuestion = cloneQuestion(
        state.questions[state.activeQuestionIndex]
      );
      state.questions.splice(state.activeQuestionIndex + 1, 0, newQuestion);
    },
    deleteQuestion: (
      state,
      action: PayloadAction<{
        subIndex?: number;
      }>
    ) => {
      if (action.payload.subIndex !== undefined) {
        const group = state.questions[state.activeQuestionIndex];
        if (group.childSteps && group.childSteps?.length > 1) {
          group.childSteps.splice(action.payload.subIndex, 1);
        }
      } else {
        const markedQuestionId = state.questions[state.activeQuestionIndex].id;
        state.questions.splice(state.activeQuestionIndex, 1);
        state.questions.forEach((question) => {
          if (question.choices) {
            question.choices.forEach((o) => {
              if (o.nextStepId === markedQuestionId) {
                o.nextStepId = "";
              }
            });
          }
        });
        if (state.questions.length <= state.activeQuestionIndex) {
          state.activeQuestionIndex = state.questions.length - 1;
        }
      }
    },
    handleQuestionMove: (
      state,
      action: PayloadAction<{
        dragIndex: number;
        dropIndex: number;
      }>
    ) => {
      const swapItem = state.questions[action.payload.dragIndex];
      const swapToItem = state.questions[action.payload.dropIndex];
      state.questions[action.payload.dropIndex] = swapItem;
      state.questions[action.payload.dragIndex] = swapToItem;
      state.activeQuestionIndex = action.payload.dropIndex;
    },
    changeQuestionProperty: (
      state,
      action: PayloadAction<{
        type: string;
        value: any;
        subIndex?: number;
      }>
    ) => {
      if (action.payload.subIndex !== undefined) {
        const subQ =
          state.questions[state.activeQuestionIndex]?.childSteps?.[
            action.payload.subIndex
          ];
        //@ts-ignore
        if (subQ) subQ[action.payload.type] = action.payload.value;
      } else {
        //@ts-ignore
        state.questions[state.activeQuestionIndex][action.payload.type] =
          action.payload.value;
      }
    },
    addQuestionOption: (
      state,
      action: PayloadAction<{
        subIndex?: number;
      }>
    ) => {
      const newOption = createNewOption();
      let question = state.questions[state.activeQuestionIndex];
      if (action.payload.subIndex !== undefined) {
        const q = question?.childSteps?.[action.payload.subIndex];
        if (q) {
          question = q;
        }
      }
      question?.choices?.push(newOption);
    },
    deleteQuestionOption: (
      state,
      action: PayloadAction<{
        optionIndex: number;
        subIndex?: number;
      }>
    ) => {
      let question = state.questions[state.activeQuestionIndex];
      if (action.payload.subIndex !== undefined) {
        const q = question?.childSteps?.[action.payload.subIndex];
        if (q) {
          question = q;
        }
      }
      if (question.choices && question.choices?.length > 1) {
        question?.choices?.splice(action.payload.optionIndex, 1);
      }
    },
    changeQuestionOptionProperty: (
      state,
      action: PayloadAction<{
        optionIndex: number;
        type: string;
        value: any;
        subIndex?: number;
      }>
    ) => {
      let question: OnboardingQuestion =
        state.questions[state.activeQuestionIndex];
      if (action.payload.subIndex !== undefined) {
        const q = question?.childSteps?.[action.payload.subIndex];
        if (q) {
          question = q;
        }
      }
      //@ts-ignore
      question.choices[action.payload.optionIndex][action.payload.type] =
        action.payload.value;
    },
    changeSubQuestionType: (
      state,
      action: PayloadAction<{ subIndex: number; type: onboardingQuestionType }>
    ) => {
      let question =
        state.questions[state.activeQuestionIndex]?.childSteps?.[
          action.payload.subIndex
        ];
      if (question) {
        const newQuestion = modifySubquestion(question, action.payload.type);
        //@ts-ignore
        state.questions[state.activeQuestionIndex].childSteps[
          action.payload.subIndex
        ] = newQuestion;
      }
    },
    reset: () => initialState,
  },
});

export const {
  setActiveIndex,
  addQuestion,
  deleteQuestion,
  duplicateQuestion,
  handleQuestionMove,
  changeQuestionProperty,
  changeQuestionOptionProperty,
  deleteQuestionOption,
  addQuestionOption,
  changeSubQuestionType,
  setOnboardingLoader,
  setOnboardingState,
  reset,
} = onboardingBuilderSlice.actions;

export default onboardingBuilderSlice.reducer;
