import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useAppSelector } from "../../../../Redux/hooks";
import {
  editorBlock,
  editorBlockHeader,
  EditorWrapper,
} from "../chatbotBuilder.style";
import AnswerOptions from "./AnswerOptions";
import BotMessage from "./BotMessage";
import BotResponses from "./BotResponses";
import ReminderConfig from "./ReminderConfig";
import SliderConfig from "./SliderConfig";
import StepName from "./StepName";

const StepEditor = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { steps, activeStepIndex, isFaq, editable } = useAppSelector(
    (state) => state.chatbotBuilder
  );
  const selectedStep = steps[activeStepIndex];

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [activeStepIndex]);

  if (!selectedStep) {
    return null;
  }

  return (
    <Box sx={EditorWrapper}>
      <Box ref={messagesEndRef} key="start-div" />
      <Box sx={editorBlock} mb={2}>
        <StepName step={selectedStep} isFaq={isFaq} />
      </Box>
      {selectedStep?.type === "calendar" && (
        <Box sx={editorBlock} mb={2}>
          <Typography variant="subtitle1" sx={editorBlockHeader}>
            Reminder Settings
          </Typography>
          <ReminderConfig
            isReminder={selectedStep?.isReminder}
            reminderBotId={selectedStep?.reminderBotId}
            editable={editable}
          />
        </Box>
      )}
      <Box sx={editorBlock} mb={2}>
        <Typography variant="subtitle1" sx={editorBlockHeader}>
          Bot Message
        </Typography>
        <BotMessage
          botMessages={selectedStep?.botMessages}
          editable={editable}
        />
      </Box>
      <Box sx={editorBlock} mb={2}>
        <Typography variant="subtitle1" sx={editorBlockHeader}>
          Answer Options
        </Typography>
        <AnswerOptions
          type={selectedStep?.type}
          options={selectedStep?.options}
          editable={editable}
        />
      </Box>
      {selectedStep?.type === "slider" && (
        <Box sx={editorBlock} mb={2}>
          <Typography variant="subtitle1" sx={editorBlockHeader}>
            Slider Values
          </Typography>
          <SliderConfig sliderValues={selectedStep?.sliderConfig} />
        </Box>
      )}
      <Box sx={editorBlock} mb={2}>
        <Typography variant="subtitle1" sx={editorBlockHeader}>
          Bot Responses
        </Typography>
        <BotResponses
          botResponses={selectedStep?.botResponses}
          options={selectedStep?.options}
          steps={steps}
          stepType={selectedStep?.type}
          currentStepId={selectedStep?.id}
          isFaq={isFaq}
          editable={editable}
        />
      </Box>
    </Box>
  );
};

export default StepEditor;
