import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";

const LinkList = ({ type, toggleLoader }: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchList = async (type: string, page: number) => {
      try {
        setLoading(true);
        let url = `/bots/broken-links/${
          type === "option" ? "general" : "faq"
        }?page=${page + 1}&size=15`;
        const res: AxiosResponse = await http.get(url);
        let newData = [];
        if (type === "option") {
          newData = res.data.data.rows.map((entry: any) => {
            return {
              id: entry?.id,
              botId: entry?.botStep?.bot?.id,
              botName: entry?.botStep?.bot?.name,
              botStepName: entry?.botStep?.name,
            };
          });
        } else {
          newData = res.data.data.rows.map((entry: any) => {
            return {
              id: entry?.id,
              botId: entry?.stepResponse?.botStep?.bot?.id,
              botName: entry?.stepResponse?.botStep?.bot?.name,
              botStepName: entry?.stepResponse?.botStep?.name,
            };
          });
        }
        if (res.data.data.count > 0 && newData.length === 0 && page > 1) {
          setPage(1);
        } else {
          setData(newData);
          setTotal(res.data.data.count);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchList(type, page);
  }, [toggleLoader, type, page, setTotal, setData]);

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Bot Name</StyledTableCell>
            <StyledTableCell>Step Name</StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data.length > 0 && (
          <>
            <TableBody>
              {data?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell
                    onClick={() =>
                      window.open(
                        `/app/chatbot/builder/${row?.botId}`,
                        "_blank"
                      )
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {row?.botName}
                  </StyledTableCell>
                  <StyledTableCell>{row?.botStepName}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {total > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={total}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default LinkList;
