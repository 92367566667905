import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { DualInput } from "./settings.style";

const schema = yup.object().shape({
  ptpAccessTknExp: yup.object().shape({
    value: yup
      .number()
      .integer("Please Enter a Whole number")
      .min(1, "Expiry Time must be greater than 0")
      .required("*Expiry Time is required"),
  }),
  ptpRefreshTknExp: yup.object().shape({
    value: yup
      .number()
      .integer("Please Enter a Whole number")
      .min(1, "Expiry Time must be greater than 0")
      .required("*Expiry Time is required"),
  }),
  AdmAccessTknExp: yup.object().shape({
    value: yup
      .number()
      .integer("Please Enter a Whole number")
      .min(1, "Expiry Time must be greater than 0")
      .required("*Expiry Time is required"),
  }),
  AdmRefreshTknExp: yup.object().shape({
    value: yup
      .number()
      .integer("Please Enter a Whole number")
      .min(1, "Expiry Time must be greater than 0")
      .required("*Expiry Time is required"),
  }),
});

const metrics = [
  {
    label: "minute(s)",
    value: "m",
  },
  {
    label: "day(s)",
    value: "d",
  },
];

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const EditSettingsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body = {
        appAccessTokenExpiration: `${values?.ptpAccessTknExp.value}${values?.ptpAccessTknExp.unit}`,
        appRefreshTokenExpiration: `${values?.ptpRefreshTknExp.value}${values?.ptpRefreshTknExp.unit}`,
        cmsAccessTokenExpiration: `${values?.AdmAccessTknExp.value}${values?.AdmAccessTknExp.unit}`,
        cmsRefreshTokenExpiration: `${values?.AdmRefreshTknExp.value}${values?.AdmRefreshTknExp.unit}`,
      };
      const res: AxiosResponse = await http.put("auth-settings", body);
      toastMessage("success", res?.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"Edit Settings"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            ptpAccessTknExp: {
              value: data[0]?.value || "15",
              unit: data[0]?.unitValue || "m",
            },
            ptpRefreshTknExp: {
              value: data[1]?.value || "15",
              unit: data[1]?.unitValue || "m",
            },
            AdmAccessTknExp: {
              value: data[2]?.value || "15",
              unit: data[2]?.unitValue || "m",
            },
            AdmRefreshTknExp: {
              value: data[3]?.value || "15",
              unit: data[3]?.unitValue || "m",
            },
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="ptpAccessTknExp.value">
                  Participant Access token Expiry time
                </FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    id="ptpAccessTknExp.value"
                    placeholder="Expiry Time"
                    fullWidth
                    type="number"
                    inputProps={{ min: 1 }}
                    error={
                      touched?.ptpAccessTknExp?.value &&
                      errors?.ptpAccessTknExp?.value
                        ? true
                        : false
                    }
                    helperText={
                      touched?.ptpAccessTknExp?.value &&
                      errors?.ptpAccessTknExp?.value
                        ? errors?.ptpAccessTknExp?.value
                        : " "
                    }
                    {...getFieldProps("ptpAccessTknExp.value")}
                  />
                  <Select
                    id="ptpAccessTknExp.unit"
                    fullWidth
                    value={values?.ptpAccessTknExp.unit}
                    onChange={(e) =>
                      setFieldValue("ptpAccessTknExp.unit", e.target.value)
                    }
                  >
                    {metrics?.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="ptpRefreshTknExp.value">
                  Participant Refresh token Expiry time
                </FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    id="ptpRefreshTknExp.value"
                    placeholder="Expiry Time"
                    type="number"
                    inputProps={{ min: 1 }}
                    error={
                      touched?.ptpRefreshTknExp?.value &&
                      errors?.ptpRefreshTknExp?.value
                        ? true
                        : false
                    }
                    helperText={
                      touched?.ptpRefreshTknExp?.value &&
                      errors?.ptpRefreshTknExp?.value
                        ? errors?.ptpRefreshTknExp?.value
                        : " "
                    }
                    {...getFieldProps("ptpRefreshTknExp.value")}
                  />
                  <Select
                    id="ptpRefreshTknExp.unit"
                    fullWidth
                    value={values?.ptpRefreshTknExp.unit}
                    onChange={(e) =>
                      setFieldValue("ptpRefreshTknExp.unit", e.target.value)
                    }
                  >
                    {metrics?.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="AdmAccessTknExp.value">
                  Admin Panel Access token Expiry time
                </FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    id="AdmAccessTknExp.value"
                    placeholder="Expiry Time"
                    type="number"
                    inputProps={{ min: 1 }}
                    error={
                      touched?.AdmAccessTknExp?.value &&
                      errors?.AdmAccessTknExp?.value
                        ? true
                        : false
                    }
                    helperText={
                      touched?.AdmAccessTknExp?.value &&
                      errors?.AdmAccessTknExp?.value
                        ? errors?.AdmAccessTknExp?.value
                        : " "
                    }
                    {...getFieldProps("AdmAccessTknExp.value")}
                  />
                  <Select
                    id="AdmAccessTknExp.unit"
                    fullWidth
                    value={values?.AdmAccessTknExp.unit}
                    onChange={(e) =>
                      setFieldValue("AdmAccessTknExp.unit", e.target.value)
                    }
                  >
                    {metrics?.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="AdmRefreshTknExp.value">
                  Admin Panel Refresh token Expiry time
                </FormLabel>
                <Box sx={DualInput}>
                  <TextField
                    fullWidth
                    id="AdmRefreshTknExp.value"
                    placeholder="Expiry Time"
                    type="number"
                    inputProps={{ min: 1 }}
                    error={
                      touched?.AdmRefreshTknExp?.value &&
                      errors?.AdmRefreshTknExp?.value
                        ? true
                        : false
                    }
                    helperText={
                      touched?.AdmRefreshTknExp?.value &&
                      errors?.AdmRefreshTknExp?.value
                        ? errors?.AdmRefreshTknExp?.value
                        : " "
                    }
                    {...getFieldProps("AdmRefreshTknExp.value")}
                  />
                  <Select
                    id="AdmRefreshTknExp.unit"
                    fullWidth
                    value={values?.AdmRefreshTknExp.unit}
                    onChange={(e) =>
                      setFieldValue("AdmRefreshTknExp.unit", e.target.value)
                    }
                  >
                    {metrics?.map((item) => {
                      return (
                        <MenuItem value={item.value} key={item.label}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </FormControl>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditSettingsModal;
