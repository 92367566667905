import { useState, useEffect, useMemo, useCallback } from "react";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  GridColDef,
  GridActionsCellItem,
  GridPagination,
  GridCellParams,
} from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledDataGrid,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { fetchParticipantsList } from "../../../Redux/actions/participantsAction";
import {
  setParticipantsFilterId,
  setParticipantsPage,
  setParticipantToggle,
  setSelectedRows,
} from "../../../Redux/reducers/participantsSlice";
import { useNavigate } from "react-router";

function CustomPagination(props: any) {
  return (
    <GridPagination
      sx={TablePaginationStyle}
      labelDisplayedRows={paginationLabel}
      {...props}
    />
  );
}

const ParticipantList = () => {
  const [menuLoader, setMenuLoader] = useState(false);

  const dispatch = useAppDispatch();
  const {
    loading,
    type,
    searchText,
    doctorsList,
    participantsData,
    totalParticipants,
    filterId,
    paginationModel,
    toggleLoader,
    selectedRows,
  } = useAppSelector((state) => state.participants);
  const { hasAdminAccess, hasMasterAdminAccess } = useAppSelector(
    (state) => state.user
  );
  const [selectedOption, setSelectedOption] = useState(filterId);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      fetchParticipantsList(paginationModel.page, type, filterId, searchText)
    );
  }, [paginationModel, type, filterId, searchText, dispatch, toggleLoader]);

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleFilterItem = (id: string) => {
    setSelectedOption(id);
  };

  const handleClickOK = () => {
    setAnchorEl(null);
    if (selectedOption !== filterId) {
      dispatch(setParticipantsFilterId(selectedOption));
    }
  };

  const modifyUser = useCallback(
    async (data: any) => {
      try {
        setMenuLoader(true);
        const newData = {
          status: data?.status === "inactive" ? "active" : "inactive",
        };
        const res: AxiosResponse = await http.put(
          `/participants/${data?.id}`,
          newData
        );
        setMenuLoader(false);
        dispatch(setParticipantToggle());
        toastMessage("success", res.data.message);
      } catch (err) {
        setMenuLoader(false);
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: "studyNumber",
        headerName: "Study Number",
        flex: 1,
        sortable: false,
        cellClassName: "clickable-column",
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        sortable: false,
      },
      {
        field: "doctor",
        headerName: "Doctor",
        flex: 1,
        sortable: false,
      },
      {
        field: "id",
        type: "actions",
        flex: 1,
        headerAlign: "right",
        renderHeader: () => (
          <IconButton onClick={openFilterMenu}>
            <FilterAltIcon color={filterId ? "primary" : "disabled"} />
          </IconButton>
        ),
        align: "right",
        getActions: (params: any) => [
          <GridActionsCellItem
            showInMenu
            label={params?.row?.status === "inactive" ? "Restore" : "Archive"}
            onClick={() => modifyUser(params?.row)}
            disabled={menuLoader}
            sx={{
              flexDirection: "row-reverse",
              "& .MuiListItemIcon-root": {
                minWidth: 0,
              },
            }}
            icon={
              menuLoader ? <CircularProgress size={18} sx={{ ml: 1 }} /> : <></>
            }
          />,
        ],
      },
    ],
    [modifyUser, filterId, menuLoader]
  );

  const handleCellClick = (params: GridCellParams) => {
    const { field, row } = params;
    if (field === "studyNumber" && type !== "unregistered") {
      navigate(`/app/participants/${row?.id}`, {
        state: { user: row?.studyNumber },
      });
    }
  };

  return (
    <>
      <StyledDataGrid
        rows={loading ? [] : participantsData}
        loading={loading}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          pagination:
            totalParticipants < pageSize ? () => <></> : CustomPagination,
          noRowsOverlay: () => (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          ),
        }}
        columns={columns}
        columnVisibilityModel={{
          type: type !== "unregistered",
          id: hasMasterAdminAccess && type !== "unregistered",
          doctor: hasAdminAccess,
        }}
        onCellClick={handleCellClick}
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        checkboxSelection={hasAdminAccess && type !== "unregistered"}
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          dispatch(setSelectedRows(newRowSelectionModel));
        }}
        rowSelectionModel={selectedRows}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(model) =>
          dispatch(setParticipantsPage(model))
        }
        pageSizeOptions={[pageSize]}
        rowCount={totalParticipants}
        disableColumnMenu
        autoHeight
        rowHeight={65}
      />
      {type !== "unregistered" && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClickOK}
          PaperProps={{
            style: {
              maxHeight: "400px",
              width: "20ch",
            },
          }}
        >
          {doctorsList?.map((item: any) => (
            <MenuItem
              selected={item?.value === selectedOption}
              key={item?.value}
              onClick={() => handleFilterItem(item?.value)}
            >
              <ListItemIcon>
                {item?.value === selectedOption ? (
                  <RadioButtonCheckedIcon fontSize="small" color="primary" />
                ) : (
                  <RadioButtonUncheckedIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText> {item?.text}</ListItemText>
            </MenuItem>
          ))}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              bgcolor: "#fff",
              p: 1,
            }}
          >
            <Button variant="text" onClick={() => handleFilterItem("")}>
              Reset
            </Button>
            <Button variant="contained" onClick={handleClickOK}>
              OK
            </Button>
          </Box>
        </Menu>
      )}
    </>
  );
};

export default ParticipantList;
