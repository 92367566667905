import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";
import {
  // AboutUsIcon,
  AdminIcon,
  ChatBotIcon,
  CloudIcon,
  CMSIcon,
  // DashboardIcon,
  Logo,
  NotificationIcon,
  ParticipantIcon,
  PasswordIcon,
  SettingsIcon,
  DailyActivitiesIcon,
  LearningPathIcon,
} from "../Common/assets/Sidebar";

const Sidebar = () => {
  const { hasAdminAccess, hasMasterAdminAccess } = useAppSelector(
    (state) => state.user
  );

  return (
    <Box
      sx={{
        height: "100vh",
        width: "60px",
        backgroundColor: "#164642",
      }}
      className="sidebar"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderBottomColor: "rgba(255, 255, 255, 0.1)",
          paddingBottom: 2,
          margin: "20px 12px",
        }}
      >
        <Logo />
      </Box>

      {/* {hasAdminAccess && (
        <NavLink
          to="/app/dashboard"
          title="Dashboard"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
        </NavLink>
      )} */}
      <NavLink
        title="Participants"
        to="/app/participants"
        className={({ isActive }) =>
          isActive ? "sider-menu active" : "sider-menu"
        }
      >
        <ParticipantIcon />
      </NavLink>
      {hasAdminAccess && (
        <NavLink
          to="/app/administrators"
          title="Administrators"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AdminIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/chatbot"
          title="Chatbot"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ChatBotIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/cms"
          title="CMS"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <CMSIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Tags and Highlights"
          to="/app/tags-highlights"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <CloudIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Password Request"
          to="/app/password-request"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <PasswordIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Notification Center"
          to="/app/notification"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <NotificationIcon />
        </NavLink>
      )}
      {/* {hasAdminAccess && (
        <NavLink
          title="About Us"
          to="/app/about"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AboutUsIcon />
        </NavLink>
      )} */}
      {hasMasterAdminAccess && (
        <NavLink
          title="Settings"
          to="/app/settings"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <SettingsIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Learning Path"
          to="/app/learning-path"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <LearningPathIcon />
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Daily Activities"
          to="/app/daily-activities"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DailyActivitiesIcon />
        </NavLink>
      )}
      {hasMasterAdminAccess && (
        <NavLink
          title="Mobile Settings"
          to="/app/mobile-settings"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <SettingsIcon />
        </NavLink>
      )}
    </Box>
  );
};

export default Sidebar;
