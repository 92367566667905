import { Box, Button, CircularProgress, Modal, TextField } from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

type PrivacyPolicyEditProps = {
  lang: string;
  closeModal: Function;
};

const PrivacyPolicyEditModal: React.FC<PrivacyPolicyEditProps> = ({
  lang,
  closeModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await http.get(`/app/settings/privacy-policy?lang=${lang}`);
        const newText = DOMPurify.sanitize(res.data.data.value || "");
        setText(newText);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        // setLoading(false);
      }
    };
    fetchData();
  }, [lang, setLoading]);

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      const reqText = DOMPurify.sanitize(text);
      if (!reqText) {
        toastMessage("warning", "Privacy Policy cannot be empty");
        setButtonLoader(false);
        return;
      }
      const res = await http.put("/app/settings/privacy-policy", {
        value: reqText,
        lang,
      });
      toastMessage("success", res?.data.message);
      closeModal();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  return (
    <Modal open={true} onClose={() => closeModal()}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"Edit Privacy Policy"}
          onCloseClick={() => closeModal()}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <TextField
              rows={10}
              multiline
              value={text}
              onChange={(e) => setText(e.target.value)}
              fullWidth
            />

            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              {!buttonLoader ? (
                <>
                  <Button variant="contained" onClick={submitHandler}>
                    Save
                  </Button>
                  <Button variant="outlined" onClick={() => closeModal()}>
                    Cancel
                  </Button>
                </>
              ) : (
                <CircularProgress size={25} />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyEditModal;
