import { MoreVertRounded } from "@mui/icons-material";
import { Avatar, Box, Chip, IconButton, Typography } from "@mui/material";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

import {
  ModuleCardStyle,
  EducationDetails,
  CardMainText,
  CardAvatar,
  CustomChip,
} from "../cms.style";

export const StatusMap: any = {
  active: "Published",
  inactive: "Inactive",
  draft: "Draft",
  archived: "Archived",
};

type Props = {
  module: any;
  type: "learning" | "education";
  handleAction: Function;
  openMenu: Function;
};
const ModuleCard: React.FC<Props> = ({
  module,
  type,
  handleAction,
  openMenu,
}) => {
  return (
    <>
      <Box sx={ModuleCardStyle} onClick={() => handleAction("click", module)}>
        <Avatar variant="rounded" src={module?.imageUrl} sx={CardAvatar}>
          <PhotoSizeSelectActualOutlinedIcon
            sx={{ width: "45px", height: "45px" }}
          />
        </Avatar>
        <Box sx={EducationDetails}>
          <Typography fontSize={"24px"} fontWeight="medium" sx={CardMainText}>
            {module?.name}
          </Typography>
          {type === "learning" && (
            <Box>
              <Typography variant="subtitle1" color="#4b5563">
                {module?.lessonCount} Education Modules
              </Typography>
              <Chip
                sx={CustomChip}
                label={module.langLabel}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
          {type === "education" && (
            <>
              <Typography variant="subtitle1" color="#4b5563">
                {module?.pageCount} units
              </Typography>
              <Chip
                sx={{ ...CustomChip, mr: 1 }}
                label={module.langLabel}
                variant="outlined"
                color="primary"
              />
              <Chip
                sx={CustomChip}
                label={StatusMap[module?.status]}
                variant={module?.status === "draft" ? "filled" : "outlined"}
                color={module?.status === "draft" ? "default" : "primary"}
              />
            </>
          )}
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton onClick={(e) => openMenu(e, module)}>
            <MoreVertRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ModuleCard;
