import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";

const schema = yup.object().shape({
  title: yup.string().required("*Notification Title is Required"),
  body: yup.string().required("* Notification Body is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  userId: any;
};

const NotificationModal = ({ showModal, closeModal, userId }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: { title: string; body: string }) => {
    try {
      setSubmitLoader(true);
      const body = {
        userId,
        title: values?.title,
        body: values?.body,
      };
      const res = await http.post("/notifications/broadcast/instant", body);
      toastMessage("success", res.data.message);
      closeModal();
      setSubmitLoader(false);
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"Send Notification"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            title: "",
            body: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="title">
                  Notification Title
                </FormLabel>
                <TextField
                  id="title"
                  placeholder={`Title`}
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="body">
                  Notification Body
                </FormLabel>
                <TextField
                  id="body"
                  placeholder={`Body`}
                  error={touched?.body && errors?.body ? true : false}
                  helperText={
                    touched?.body && errors?.body ? errors?.body : " "
                  }
                  {...getFieldProps("body")}
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  mt: 1,
                }}
              >
                {!submitLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Send Notification
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
