import { Add, Clear, Delete, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  addSpacedChoice,
  deleteSpacedChoice,
  handleSpacedChange,
  handleSpacedChoiceChange,
  handleSpacedNotifChange,
  setLoading,
} from "../../../../Redux/reducers/cmsBuilderSlice";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { InputWrapper } from "../../../Common/styles/form";
import { ImageUploadIcon } from "../../Icons";
import {
  ArrayIconButtonStyle2,
  CMSInputLabel,
  MiniUploadWrapper,
  UploadWrapper,
} from "../styles";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { NumberMap } from "../../../../utils/chatbotBuilder";

type UploadProps = {
  choiceIndex: number;
  sectionIndex: number;
  image: any;
};

const UploadItem: React.FC<UploadProps> = ({
  choiceIndex,
  sectionIndex,
  image,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleSpacedChoiceChange({
              optionIndex: choiceIndex,
              sectionIndex,
              type: "imageUrl",
              value: url,
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [sectionIndex, dispatch, choiceIndex]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const clearImage = () => {
    dispatch(
      handleSpacedChoiceChange({
        optionIndex: choiceIndex,
        sectionIndex,
        type: "image",
        value: "",
      })
    );
  };

  return (
    <>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper, mt: 4 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          sx={ArrayIconButtonStyle2}
          color="error"
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

type QuestionItemProps = {
  question: any;
  index: number;
  botId?: any;
};

const MCQItem: React.FC<QuestionItemProps> = ({ question, index }) => {
  const dispatch = useAppDispatch();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    dispatch(
      handleSpacedChange({
        index,
        value: event.target.value,
        type: type,
      })
    );
  };

  const handleOptionChange = (
    value: any,
    type: string,
    optionIndex: number
  ) => {
    dispatch(
      handleSpacedChoiceChange({
        sectionIndex: index,
        type,
        value,
        optionIndex,
      })
    );
  };

  const addChoice = () => {
    dispatch(
      addSpacedChoice({
        index,
      })
    );
  };

  const deleteChoice = (choiceIndex: number) => {
    dispatch(
      deleteSpacedChoice({
        sectionIndex: index,
        choiceIndex,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Correct Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.correctExplanation}
            onChange={(e) => handleChange(e, "correctExplanation")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Wrong Answer Explanation</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.wrongExplanation}
            onChange={(e) => handleChange(e, "wrongExplanation")}
          />
        </FormControl>
      </Box>
      {question?.choices.map((choice: any, optionIndex: number) => {
        return (
          <Box
            key={choice.key}
            sx={{ display: "flex", mb: 2, alignItems: "center", gap: "10px" }}
          >
            <FormControl sx={InputWrapper}>
              <FormLabel sx={CMSInputLabel}>
                {"Answer Option " + (optionIndex + 1)}
              </FormLabel>
              <TextField
                fullWidth
                placeholder="Type your text here..."
                value={choice.label}
                onChange={(e) =>
                  handleOptionChange(e.target.value, "label", optionIndex)
                }
              />
            </FormControl>
            <Box sx={{ alignSelf: "flex-end", flexShrink: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choice.isCorrect}
                    onChange={(e) =>
                      handleOptionChange(
                        e.target.checked,
                        "isCorrect",
                        optionIndex
                      )
                    }
                  />
                }
                label="Check If option is correct"
              />
            </Box>
            <UploadItem
              choiceIndex={optionIndex}
              image={choice.imageUrl}
              sectionIndex={index}
            />
            {optionIndex === 0 ? (
              <IconButton onClick={addChoice} sx={ArrayIconButtonStyle2}>
                <Add />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => deleteChoice(optionIndex)}
                color="error"
                sx={ArrayIconButtonStyle2}
                disabled={question?.choices.length < 3}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        );
      })}
    </>
  );
};

const EducationTitle: React.FC<QuestionItemProps> = ({ question, index }) => {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleSpacedChange({
        index,
        value: event.target.value,
        type: "title",
      })
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setLoading(true));
          const url = await uploadFile(file, "education_lesson_image");
          dispatch(
            handleSpacedChange({
              index,
              value: url,
              type: "imageUrl",
            })
          );
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    },
    [index, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <Box sx={{ width: "70%", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px", mb: 2 }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Question Title</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.title}
            onChange={handleChange}
          />
        </FormControl>
      </Box>
      <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {question?.imageUrl ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const NotificationItem: React.FC<QuestionItemProps> = ({
  question,
  index,
  botId,
}) => {
  const [bots, setBots] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedBot, setSelectedBot] = useState<any>(null);
  const { lang } = useAppSelector((state) => state.cmsBuilder);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchBots = async (id: string) => {
      try {
        dispatch(setLoading(true));
        const res: AxiosResponse = await http.get(`/bots/${id}/steps`);
        setBots([{ id: res.data.data?.id, label: res.data.data?.name }]);
        setSelectedBot({ id: res.data.data?.id, label: res.data.data?.name });
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        errorToastMessage(err as Error);
      }
    };
    if (botId) {
      fetchBots(botId);
    }
  }, [botId, setBots, dispatch]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=general&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                label: bot?.name,
              };
            });
            setBots(newBots || []);
            setSearchLoader(false);
          } else {
            setBots([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  const handleChange = (value: string | number, type: string) => {
    dispatch(
      handleSpacedNotifChange({
        index,
        value,
        type: type,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Notification Title</FormLabel>
          <TextField
            fullWidth
            placeholder="Title"
            value={question?.notification?.title}
            onChange={(e) => handleChange(e.target.value, "title")}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Notification Body</FormLabel>
          <TextField
            fullWidth
            placeholder="Type your text here..."
            value={question?.notification?.body}
            onChange={(e) => handleChange(e.target.value, "body")}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Chat bot</FormLabel>
          <Autocomplete
            freeSolo
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") {
                handleSearch(value);
              }
            }}
            onChange={(_1: any, value: any) => {
              handleChange(value?.id, "botId");
            }}
            isOptionEqualToValue={(option, value) => {
              return option?.label === value;
            }}
            options={bots}
            clearOnBlur={true}
            disableClearable
            value={selectedBot?.label || null}
            loading={searchLoader}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Chatbot by typing"
              />
            )}
          />
        </FormControl>
        <FormControl sx={InputWrapper}>
          <FormLabel sx={CMSInputLabel}>Trigger in days</FormLabel>
          <Select
            value={question?.notification?.triggerIn}
            onChange={(e) => {
              handleChange(parseInt(e.target.value), "triggerIn");
            }}
            fullWidth
            id="triggerIn"
          >
            {NumberMap.map((number) => (
              <MenuItem key={number} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

const SpacedLearning = ({ section, index }: any) => {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleSpacedChange({
        index,
        value: event.target.checked,
        type: "allowMultipleSelection",
      })
    );
  };

  return (
    <>
      <EducationTitle question={section?.spacedLearning} index={index} />
      <FormControlLabel
        label="Allow Multiple Selection"
        control={
          <Switch
            checked={section?.spacedLearning?.allowMultipleSelection}
            onChange={handleChange}
          />
        }
        sx={{ mb: 1 }}
      />
      <MCQItem question={section?.spacedLearning} index={index} />
      <NotificationItem
        question={section?.spacedLearning}
        index={index}
        botId={section?.spacedLearning?.notification?.botId}
      />
    </>
  );
};

export default SpacedLearning;
