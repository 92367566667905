import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useAppDispatch } from "../../../../Redux/hooks";
import { changeStepProperty } from "../../../../Redux/reducers/chatbotBuilderSlice";
import { CbBuilderStep } from "../../../../types/chatbotBuilder";

type Props = {
  step: CbBuilderStep;
  isFaq: boolean;
};

const StepName = ({ step, isFaq }: Props) => {
  const dispatch = useAppDispatch();

  const { name, codeName, type, optionSearchable } = step;

  const changeValue = (
    type: "name" | "codeName" | "optionSearchable",
    value: any
  ) => {
    dispatch(changeStepProperty({ type, value }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
          Step Name
        </Typography>
        <TextField
          fullWidth
          value={name || ""}
          placeholder="Step name"
          onChange={(e) => changeValue("name", e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
          Code Name
        </Typography>
        <TextField
          fullWidth
          value={codeName || ""}
          placeholder="Step name"
          onChange={(e) => changeValue("codeName", e.target.value)}
        />
      </Box>
      {isFaq && type === "multiple_choice" && (
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={optionSearchable}
                onChange={(e) =>
                  changeValue("optionSearchable", e.target.checked)
                }
              />
            }
            label="Enable options to be searchable"
          />
        </Box>
      )}
    </>
  );
};

export default StepName;
