import React, { useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { Menu } from "@mui/icons-material";

type Props = {
  closeModal: Function;
  items: any[];
  callback: Function;
  title: string;
  parent: string;
  parentId: string;
};

const RearrangeCMSModal: React.FC<Props> = ({
  closeModal,
  callback,
  items,
  title,
  parent,
  parentId,
}) => {
  const closeHandler = () => {
    closeModal();
  };

  return (
    <Modal open={true} onClose={closeHandler}>
      <Box
        sx={{
          ...ModalBaseStyles,
          minHeight: "10vh",
        }}
      >
        <ModalHeader title={title} onCloseClick={closeHandler} />
        <DndProvider backend={HTML5Backend}>
          <RearrangeContainer
            items={items}
            closeModal={closeModal}
            callback={callback}
            title={title}
            parent={parent}
            parentId={parentId}
          />
        </DndProvider>
      </Box>
    </Modal>
  );
};

const RearrangeItem: React.FC<any> = ({ item, index, handleDrop }) => {
  const [, dragRef] = useDrag(() => {
    return {
      type: "card",
      item: {
        type: "card",
        sort: true,
        origin: index,
      },
    };
  }, [index]);

  const [, drop] = useDrop({
    accept: "card",
    drop: (dropItem: any) => {
      if (dropItem.sort) {
        handleDrop(dropItem, index);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  return (
    <div ref={drop}>
      <div className="rearrange-section" ref={dragRef}>
        <Menu />
        <Typography ml={2} fontWeight={"medium"}>
          {item.name || item.title}
        </Typography>
      </div>
    </div>
  );
};

const RearrangeContainer: React.FC<Props> = React.memo(
  ({ items, callback, closeModal, parent, parentId }) => {
    const [sortedItems, setSortedItems] = useState<any[]>(items);
    const [submitLoader, setSubmitLoader] = useState(false);

    const submitHandler = async () => {
      try {
        setSubmitLoader(true);
        const shuffleObj: any = {};
        sortedItems.forEach((step: any, index: number) => {
          shuffleObj[step.id] = index + 1;
        });
        let res: AxiosResponse;
        if (parent === "lesson") {
          res = await http.post(
            `/lms/lessons/${parentId}/units/order`,
            shuffleObj
          );
        } else if (parent === "learning-module") {
          res = await http.post(
            `/lms/categories/${parentId}/order`,
            shuffleObj
          );
        } else {
          res = await http.post(
            `/lms/lessons/${parentId}/pages/order`,
            shuffleObj
          );
        }
        toastMessage("success", res.data.message);
        callback();
        closeModal();
      } catch (err) {
        setSubmitLoader(false);
        errorToastMessage(err as Error);
      }
    };

    const handleDrop = (
      item: { type: string; origin: number },
      index?: number | boolean
    ) => {
      if (typeof index === "number") {
        const selectedItem = { ...sortedItems[item.origin] };
        const changedSections = sortedItems.filter(
          (_: any, i: number) => i !== item.origin
        );
        const prevItems = changedSections.slice(0, index);
        const nextItems = changedSections.slice(index, changedSections.length);
        const modifiedArr = [...prevItems, selectedItem, ...nextItems];
        setSortedItems(modifiedArr);
      }
    };

    const closeHandler = () => {
      closeModal();
    };

    return (
      <>
        {sortedItems.map((item, index) => {
          return (
            <RearrangeItem
              key={item.id}
              item={item}
              index={index}
              handleDrop={handleDrop}
            />
          );
        })}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!submitLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button onClick={closeHandler} variant="outlined">
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </>
    );
  }
);

export default RearrangeCMSModal;
