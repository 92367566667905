import { useMemo } from "react";
import { debounce } from "lodash";
import { Box, Button, TextField, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setCloudModalProps,
  setCloudSearchText,
} from "../../Redux/reducers/cloudSlice";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import AddCloudModal from "./AddCloudModal";

const CloudHeader = () => {
  const dispatch = useAppDispatch();
  const { type, searchText, modalProps } = useAppSelector(
    (state) => state.cloud
  );

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setCloudSearchText(val));
      }, 500),
    [dispatch]
  );

  const openModal = () => {
    dispatch(
      setCloudModalProps({ show: true, title: "", description: "", id: "" })
    );
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Tags and Highlights
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder={`Search for ${type}`}
          defaultValue={searchText}
          key={type}
        />
      </Box>
      <Box sx={HeaderRightContent}>
        <Button variant="contained" onClick={openModal}>
          Add {type}
        </Button>
      </Box>
      {modalProps.show && (
        <AddCloudModal
          showModal={modalProps.show}
          title={modalProps.title}
          description={modalProps.description}
          type={type}
          id={modalProps.id}
        />
      )}
    </StyledHeader>
  );
};

export default CloudHeader;
