import { Box, Typography } from "@mui/material";
import {
  footContainerWrapper,
  footDetails,
  footWrapper,
  feetImageContainer,
  NoDataContainer,
} from "./style";
import { FootAltIcon, FootIcon } from "../../Common/assets/FoodJournalIcons";
import { DateTime } from "luxon";
import { useState } from "react";
import ImagePreview from "./ImagePreview";

const feetData = [
  { position: "top" },
  { position: "bottom" },
  { position: "side" },
];

const FootJournal: React.FC<any> = ({ feet }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const leftFoot =
    feet?.images.filter((foot: any) => foot.foot === "left") || [];
  const rightFoot =
    feet?.images.filter((foot: any) => foot.foot === "right") || [];

  const handleImagePreview = (visible: boolean, url: "string" | null) => {
    setShowPreview(visible);
    setSelectedImage(url);
  };

  return (
    <Box sx={footContainerWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Foot
      </Typography>
      {feet ? (
        <Box sx={footWrapper}>
          <FootIcon />

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3.5,
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#216B64"
                >
                  Left Foot
                </Typography>

                <Box sx={footDetails}>
                  {feetData.map((foot: any, index: number) => {
                    const currentFoot = leftFoot?.find(
                      (item: any) => item?.position === foot?.position
                    );
                    return (
                      <Box key={index}>
                        <Box sx={feetImageContainer}>
                          {currentFoot && currentFoot?.url ? (
                            <img
                              src={currentFoot?.url}
                              className="footImage"
                              alt="foot"
                              onClick={() =>
                                handleImagePreview(true, currentFoot?.url)
                              }
                            />
                          ) : (
                            <Box
                              sx={{
                                bgcolor: "#F9F3F3",
                                height: "70px",
                                width: "70px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FootAltIcon />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            color: "#4D4F56",
                          }}
                        >
                          {foot?.position}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#216B64"
                >
                  Right Foot
                </Typography>

                <Box sx={footDetails}>
                  {feetData.map((foot: any, index: number) => {
                    const currentFoot = rightFoot?.find(
                      (item: any) => item?.position === foot?.position
                    );
                    return (
                      <Box key={index}>
                        <Box sx={feetImageContainer}>
                          {currentFoot && currentFoot?.url ? (
                            <img
                              src={currentFoot?.url}
                              className="footImage"
                              alt="foot"
                              onClick={() =>
                                handleImagePreview(true, currentFoot?.url)
                              }
                            />
                          ) : (
                            <Box
                              sx={{
                                bgcolor: "#F9F3F3",
                                height: "70px",
                                width: "70px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FootAltIcon />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            color: "#4D4F56",
                          }}
                        >
                          {foot?.position}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            {feet?.notes && (
              <Box
                sx={{
                  p: "6px",
                  fontSize: 13,
                  fontWeight: 500,
                  letterSpacing: "0.4px",
                  color: "#4D4F56",
                  backgroundColor: "#F2F2F3",
                  borderRadius: "5px",
                  mt: 2,
                  width: "max-content",
                }}
              >
                Notes: {feet?.notes}
              </Box>
            )}
          </Box>
          {feet?.takenOn && (
            <Box sx={{ position: "absolute", top: 16, right: 16 }}>
              <Typography variant="body1" fontWeight="medium" color="#676970">
                {DateTime.fromISO(feet?.takenOn).toFormat("hh:mm a")}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={NoDataContainer}>
          <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
            Data Unavailable
          </Typography>
        </Box>
      )}
      {showPreview && (
        <ImagePreview
          open={showPreview}
          handleClose={handleImagePreview}
          url={selectedImage}
        />
      )}
    </Box>
  );
};

export default FootJournal;
