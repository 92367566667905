import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DateTime } from "luxon";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { LANGUAGES, langMap } from "../../utils/lang";
import { Ethnicity, Gender } from "../../utils/notification";

const schema = yup.object().shape({
  title: yup.string().required("Notification Title is Required"),
  body: yup.string().required("Notification Body is Required"),
  scheduledOnDate: yup
    .string()
    .required("Schedule Date is Required")
    .typeError("Schedule Date is Required"),
  scheduledOnTime: yup
    .string()
    .required("Schedule Time is Required")
    .typeError("Schedule Time is Required"),
  bot: yup.object().when("entity", {
    is: (val: string) => val === "bot",
    then: (schema) => schema.required("*Bot is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  faq: yup.object().when("entity", {
    is: (val: string) => val === "faq_bot",
    then: (schema) => schema.required("*FAQ is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  educationLesson: yup.object().when("entity", {
    is: (val: string) => val === "lesson",
    then: (schema) => schema.required("*Lesson is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const userTarget = ["Patient and Caregiver", "Patient", "Caregiver"];

const CreateNotificationModal: React.FC<any> = ({
  showModal,
  notification,
  closeModal,
  refreshPage,
}: any) => {
  const [lessons, setLessons] = useState<any[]>([]);
  const [bots, setBots] = useState<any[]>([]);
  const [faqs, setFaqs] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setLoading(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active&lang=${lang}`
            );
            const newLessons = res.data?.data?.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson?.id,
                  name: lesson?.name,
                  lang: langMap[lesson?.lang] || "English",
                };
              }
            );
            setLessons(newLessons);
            setLoading(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const handleBotSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string, type: string) => {
        try {
          if (value) {
            setLoading(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=${type}&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });
            if (type === "general") {
              setBots(newBots);
            } else {
              setFaqs(newBots);
            }
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const handleUserSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/participants?page=1&size=15&search=${value}&lang=${lang}&status=active`
            );
            const newIds = res.data.data.users.map((user: any) => {
              return {
                id: user?.id,
                name: user?.code,
              };
            });
            setUserList(newIds);
            setSearchLoader(false);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const submitHandler = async (values: any) => {
    try {
      const time = DateTime.fromISO(values.scheduledOnTime).toFormat("hh:mm a");
      const date = DateTime.fromISO(values.scheduledOnDate).toFormat(
        "LL'-'dd'-'yyyy"
      );
      const scheduleOn: any = new Date(date + " " + time);
      const currentDate: any = new Date();

      if (scheduleOn - currentDate <= 0) {
        toastMessage("warning", "The schedule time cannot be in the past");
        return;
      }

      const body = {
        title: values.title,
        body: values.body,
        scheduledOn: scheduleOn.toISOString(),
        botId: values.bot?.id || values.faq?.id || null,
        educationLessonId: values.educationLesson?.id || null,
        type: values?.showUserId ? 0 : values.type,
        lang: values.lang,
        userIds:
          values?.users?.length > 0 && values?.showUserId
            ? values.users.map((item: any) => item.id)
            : [],
        filters: {
          gender: values?.showUserId ? null : values.gender || null,
          ethnicity: values?.showUserId ? [] : values?.ethnicity || [],
        },
        entity: values?.entity || "general",
      };
      setButtonLoader(true);
      if (notification) {
        await http.put(`/notifications/broadcast/${notification?.id}`, body);
      } else {
        await http.post("/notifications/broadcast", body);
      }
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    if (notification?.educationLesson) {
      setLessons([
        {
          id: notification?.educationLesson?.id,
          name: notification?.educationLesson?.name,
          lang: notification?.educationLesson?.lang,
        },
      ]);
    }
    if (notification?.bot) {
      setBots([{ id: notification?.bot?.id, name: notification?.bot?.name }]);
    }
    if (notification?.faq) {
      setFaqs([{ id: notification?.faq?.id, name: notification?.faq?.name }]);
    }
    if (notification?.users?.length > 0) {
      setUserList([...(notification.users as any[])]);
    }
  }, [setBots, setLessons, setFaqs, setUserList, notification]);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={notification?.id ? "Edit Notification" : `Create Notification`}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            title: notification?.title || "",
            body: notification?.body || "",
            scheduledOnDate: notification?.scheduledOn
              ? DateTime.fromISO(notification?.scheduledOn)
              : null,
            scheduledOnTime: notification?.scheduledOn
              ? DateTime.fromISO(notification?.scheduledOn)
              : null,
            entity: notification?.entity || "general",
            //Assets
            educationLesson: notification?.educationLesson || null,
            bot: notification?.bot || null,
            faq: notification?.faq || null,
            // Filters
            type: notification?.type || 0,
            lang: notification?.lang || "en",
            gender: notification?.gender || "",
            ethnicity: notification?.ethnicity || [],
            showUserId:
              (notification && notification?.users?.length > 0) || false,
            users: notification?.users || [],
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="title">
                  Notification Title
                </FormLabel>
                <TextField
                  id="title"
                  placeholder="Title"
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="body">
                  Notification Body
                </FormLabel>
                <TextField
                  id="body"
                  placeholder="Body"
                  error={touched?.body && errors?.body ? true : false}
                  helperText={
                    touched?.body && errors?.body ? errors?.body : " "
                  }
                  {...getFieldProps("body")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="lang">
                  Language
                </FormLabel>
                <Select
                  fullWidth
                  id="language"
                  sx={{ mb: 2 }}
                  value={values.lang}
                  onChange={(e) => {
                    setFieldValue("lang", e.target.value);
                    setFieldValue("educationLesson", null);
                    setFieldValue("bot", null);
                    setFieldValue("faq", null);
                  }}
                >
                  {LANGUAGES.map((lang) => (
                    <MenuItem key={lang.value} value={lang.value}>
                      {lang.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="">
                  Scheduled Time
                </FormLabel>
                <Box sx={{ display: "flex", gap: 1.5 }}>
                  <DatePicker
                    disablePast
                    inputFormat="dd/MM/yyyy"
                    value={
                      values?.scheduledOnDate ? values?.scheduledOnDate : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue("scheduledOnDate", newValue);
                    }}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select date",
                        }}
                        error={
                          touched?.scheduledOnDate && errors?.scheduledOnDate
                            ? true
                            : false
                        }
                        helperText={
                          touched?.scheduledOnDate && errors?.scheduledOnDate
                            ? errors?.scheduledOnDate
                            : " "
                        }
                      />
                    )}
                  />
                  <TimePicker
                    inputFormat="hh:mm a"
                    value={
                      values?.scheduledOnTime ? values?.scheduledOnTime : null
                    }
                    onChange={(newValue: any) =>
                      setFieldValue("scheduledOnTime", newValue)
                    }
                    minutesStep={30}
                    renderInput={(
                      params: JSX.IntrinsicAttributes & TextFieldProps
                    ) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "Select time",
                        }}
                        error={
                          touched?.scheduledOnTime && errors?.scheduledOnTime
                            ? true
                            : false
                        }
                        helperText={
                          touched?.scheduledOnTime && errors?.scheduledOnTime
                            ? errors?.scheduledOnTime
                            : " "
                        }
                      />
                    )}
                  />
                </Box>
              </FormControl>
              <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                <FormControlLabel
                  label="Send To User Id's"
                  control={
                    <Checkbox
                      checked={values?.showUserId}
                      onChange={(event) => {
                        setFieldValue("showUserId", event.target.checked);
                      }}
                    />
                  }
                />
              </FormControl>
              {values?.showUserId ? (
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>User Ids</FormLabel>
                  <Autocomplete
                    multiple
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input")
                        handleUserSearch(value, values.lang);
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("users", value);
                    }}
                    options={userList}
                    getOptionLabel={(option) => option?.name}
                    loading={searchLoader}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    value={values?.users || null}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search users by typing..."
                      />
                    )}
                  />
                </FormControl>
              ) : (
                <>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle}>Send To</FormLabel>
                    <Select
                      id="type"
                      fullWidth
                      sx={{ mb: 2 }}
                      value={values?.type}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                    >
                      {userTarget.map((item, index) => (
                        <MenuItem key={item} value={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="gender">
                      Gender
                    </FormLabel>
                    <Select
                      fullWidth
                      id="gender"
                      sx={{ mb: 2 }}
                      value={values.gender}
                      onChange={(e) => {
                        setFieldValue("gender", e.target.value);
                      }}
                    >
                      <MenuItem key="" value="">
                        No Filter
                      </MenuItem>
                      {Gender.map((gender) => (
                        <MenuItem key={gender.value} value={gender.value}>
                          {gender.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="ethnicity">
                      Ethnicity
                    </FormLabel>
                    <Select
                      fullWidth
                      multiple
                      id="ethnicity"
                      value={values.ethnicity}
                      onChange={(e) => {
                        setFieldValue("ethnicity", e.target.value);
                      }}
                    >
                      {Ethnicity.map((lang) => (
                        <MenuItem key={lang.value} value={lang.value}>
                          {lang.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <FormControl sx={{ ...InputWrapper, mt: 2, width: "auto" }}>
                <FormControlLabel
                  label="Link Lesson"
                  control={
                    <Checkbox
                      checked={values?.entity === "lesson"}
                      onChange={(event) => {
                        setFieldValue("bot", null);
                        setFieldValue("faq", null);
                        if (!event.target.checked) {
                          setFieldValue("entity", "general");
                          setFieldValue("educationLesson", null);
                        } else {
                          setFieldValue("entity", "lesson");
                        }
                      }}
                    />
                  }
                />
              </FormControl>
              {values?.entity === "lesson" && (
                <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                  <FormLabel sx={LabelStyle}>Education Module</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleSearch(value, values.lang);
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("educationLesson", value);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    clearOnBlur={true}
                    disableClearable
                    options={lessons}
                    getOptionLabel={(option) => option?.name}
                    value={values?.educationLesson}
                    loading={loading}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Module by typing..."
                      />
                    )}
                  />
                  <FormHelperText
                    error={
                      touched?.educationLesson && errors?.educationLesson
                        ? true
                        : false
                    }
                  >
                    {touched?.educationLesson && errors?.educationLesson
                      ? (errors?.educationLesson as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              )}
              <div>
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Link Bot"
                    control={
                      <Checkbox
                        checked={values?.entity === "bot"}
                        onChange={(event) => {
                          setFieldValue("faq", null);
                          setFieldValue("educationLesson", null);
                          if (!event.target.checked) {
                            setFieldValue("entity", "general");
                            setFieldValue("bot", null);
                          } else {
                            setFieldValue("entity", "bot");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
              {values?.entity === "bot" && (
                <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                  <FormLabel sx={LabelStyle}>Chat bot</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input")
                        handleBotSearch(value, values.lang, "general");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("bot", value);
                    }}
                    options={bots}
                    getOptionLabel={(option) => option?.name}
                    loading={loading}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    value={values?.bot || null}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Chat bot by typing..."
                      />
                    )}
                  />
                  <FormHelperText
                    error={touched?.bot && errors?.bot ? true : false}
                  >
                    {touched?.bot && errors?.bot
                      ? (errors?.bot as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              )}
              <div>
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Link FAQ"
                    control={
                      <Checkbox
                        checked={values?.entity === "faq_bot"}
                        onChange={(event) => {
                          setFieldValue("educationLesson", null);
                          setFieldValue("bot", null);
                          if (!event.target.checked) {
                            setFieldValue("entity", "general");
                            setFieldValue("faq", null);
                          } else {
                            setFieldValue("entity", "faq_bot");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
              {values?.entity === "faq_bot" && (
                <FormControl sx={{ ...InputWrapper, marginY: 1 }}>
                  <FormLabel sx={LabelStyle}>FAQ</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input")
                        handleBotSearch(value, values.lang, "faq");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("faq", value);
                    }}
                    options={faqs}
                    getOptionLabel={(option) => option?.name}
                    loading={loading}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    value={values?.faq || null}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search FAQ by typing..."
                      />
                    )}
                  />
                  <FormHelperText
                    error={touched?.faq && errors?.faq ? true : false}
                  >
                    {touched?.faq && errors?.faq
                      ? (errors?.faq as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              )}
              <div>
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Link Feet Diary"
                    control={
                      <Checkbox
                        checked={values?.entity === "feet_diary"}
                        onChange={(event) => {
                          setFieldValue("faq", null);
                          setFieldValue("educationLesson", null);
                          setFieldValue("bot", null);
                          if (!event.target.checked) {
                            setFieldValue("entity", "general");
                          } else {
                            setFieldValue("entity", "feet_diary");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Link Food Diary"
                    control={
                      <Checkbox
                        checked={values?.entity === "food_diary"}
                        onChange={(event) => {
                          setFieldValue("faq", null);
                          setFieldValue("educationLesson", null);
                          setFieldValue("bot", null);
                          if (!event.target.checked) {
                            setFieldValue("entity", "general");
                          } else {
                            setFieldValue("entity", "food_diary");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                  <FormControlLabel
                    label="Link Medication"
                    control={
                      <Checkbox
                        checked={values?.entity === "medication_diary"}
                        onChange={(event) => {
                          setFieldValue("faq", null);
                          setFieldValue("educationLesson", null);
                          setFieldValue("bot", null);
                          if (!event.target.checked) {
                            setFieldValue("entity", "general");
                          } else {
                            setFieldValue("entity", "medication_diary");
                          }
                        }}
                      />
                    }
                  />
                </FormControl>
              </div>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateNotificationModal;
