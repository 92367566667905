import { Box, TextField, Typography } from "@mui/material";

import { useAppDispatch } from "../../../../Redux/hooks";
import { changeSliderConfig } from "../../../../Redux/reducers/chatbotBuilderSlice";
import { dualInput } from "../chatbotBuilder.style";

const SliderConfig = ({ sliderValues }: any) => {
  const dispatch = useAppDispatch();

  const changeValue = (value: string, type: string) => {
    dispatch(changeSliderConfig({ value, type }));
  };

  return (
    <>
      <Box sx={dualInput}>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Min
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.min}
            placeholder="Min value"
            type="number"
            onChange={(e) => changeValue(e.target.value, "min")}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Max
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.max}
            placeholder="Max value"
            type="number"
            onChange={(e) => changeValue(e.target.value, "max")}
          />
        </Box>
      </Box>
      <Box sx={dualInput}>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Default value
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.default}
            placeholder="Default value"
            type="number"
            onChange={(e) => changeValue(e.target.value, "default")}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Slider step size
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.stepSize}
            type="number"
            placeholder="Step size"
            onChange={(e) => changeValue(e.target.value, "stepSize")}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Unit
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.unit}
            placeholder="units like %, kgs, meters"
            onChange={(e) => changeValue(e.target.value, "unit")}
          />
        </Box>
      </Box>
      <Box sx={dualInput}>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Min Label
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.minLabel}
            placeholder="Min label"
            onChange={(e) => changeValue(e.target.value, "minLabel")}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Max Label
          </Typography>
          <TextField
            fullWidth
            value={sliderValues?.maxLabel}
            placeholder="Max label"
            onChange={(e) => changeValue(e.target.value, "maxLabel")}
          />
        </Box>
      </Box>
    </>
  );
};

export default SliderConfig;
