import { Box, FormLabel } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import { useAppDispatch } from "../../../../Redux/hooks";
import { changeSectionValue } from "../../../../Redux/reducers/cmsBuilderSlice";

import { quillFormats, quillModules } from "../../../../utils/reactquill";
import { CMSInputLabel } from "../styles";

type Props = {
  section: any;
  index: number;
};

const VideoTranscript: React.FC<Props> = ({ index, section }) => {
  const dispatch = useAppDispatch();

  const handleChange = (data: any) => {
    dispatch(
      changeSectionValue({
        value: data,
        type: "transcript",
        index,
      })
    );
  };

  return (
    <>
      <FormLabel sx={CMSInputLabel}>Video Transcript</FormLabel>
      <Box sx={{ my: 2 }}>
        <ReactQuill
          modules={quillModules}
          formats={quillFormats}
          placeholder="Transcript content"
          defaultValue={section.transcript}
          className="quill-container"
          onChange={handleChange}
        />
      </Box>
    </>
  );
};

export default VideoTranscript;
