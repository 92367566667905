import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { BotOptionWrapper, BotOptionsStyle } from "../style";
import { InputWrapper, LabelStyle } from "../../../Common/styles/form";
import { toastMessage } from "../../../../utils/toast";
import { ChevronRight } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const ChoiceType: React.FC<any> = ({ options, submitResponse, step }) => {
  const clickHandler = (opt: any) => {
    submitResponse(
      opt?.id,
      step?.stepId,
      step?.stepType,
      step?.id,
      opt,
      step?.botId
    );
  };

  return (
    <Box sx={BotOptionWrapper}>
      {options?.map((opt: any) => {
        return (
          <Box
            key={opt.id}
            sx={BotOptionsStyle}
            onClick={() => clickHandler(opt)}
          >
            {opt.imageUrl && (
              <img src={opt.imageUrl} alt="answer" className="bot-image" />
            )}
            <Typography component="span" variant="subtitle1" mr={3}>
              {opt?.text}
            </Typography>
            <ChevronRight />
          </Box>
        );
      })}
    </Box>
  );
};

const TextType: React.FC<any> = ({ submitResponse, step }) => {
  const [val, setValue] = useState("");
  const submitHandler = () => {
    if (val || val !== "") {
      const updatedVal = step?.stepType === "number" ? Number(val) : val;
      submitResponse(
        updatedVal,
        step.stepId,
        step.stepType,
        step.id,
        {
          text: val,
        },
        step?.botId
      );
    } else {
      toastMessage("warning", "Enter value to submit");
    }
  };

  const changeHandler = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Box sx={BotOptionWrapper} style={{ width: "100%" }}>
      <FormControl sx={InputWrapper}>
        <FormLabel sx={LabelStyle}>
          {step?.stepType === "short_text" && "Enter your short text here"}
          {step?.stepType === "long_text" && "Enter your long text here"}
          {step?.stepType === "number" && "Enter your number here"}
        </FormLabel>
        <TextField
          value={val}
          onChange={changeHandler}
          type={step?.stepType === "number" ? "number" : "text"}
        />
      </FormControl>
      <Button
        sx={{ my: 2 }}
        onClick={submitHandler}
        endIcon={<ChevronRight />}
        variant="contained"
      >
        Submit
      </Button>
    </Box>
  );
};

const SliderType: React.FC<any> = ({ submitResponse, step }) => {
  const [val, setValue] = useState(step?.stepSettings?.defaultValue);
  const [marks, setMarks] = useState<any>([]);

  useEffect(() => {
    if (step?.stepSettings) {
      const obj = [
        {
          label: step?.stepSettings?.leftLabel,
          value: step?.stepSettings?.minValue,
        },
        {
          label: step?.stepSettings?.rightLabel,
          value: step?.stepSettings?.maxValue,
        },
      ];
      setMarks(obj);
    }
  }, [step, setMarks]);

  const submitHandler = () => {
    if (val || val !== undefined) {
      const updatedVal = Number(val);
      submitResponse(
        updatedVal,
        step?.stepId,
        step?.stepType,
        step?.id,
        {
          text: val,
        },
        step?.botId
      );
    } else {
      toastMessage("warning", "Select value to submit");
    }
  };

  const changeHandler = (_: any, value: any) => {
    setValue(value);
  };

  return (
    <Box sx={BotOptionWrapper} style={{ width: "100%" }}>
      <FormControl
        sx={InputWrapper}
        style={{ width: "100%", marginRight: "10px" }}
      >
        <FormLabel
          sx={LabelStyle}
        >{`Select your value here in ${step?.stepSettings?.unit}`}</FormLabel>
        <Slider
          value={val}
          min={step?.stepSettings?.minValue}
          max={step?.stepSettings?.maxValue}
          step={step?.stepSettings?.stepSize}
          marks={marks}
          onChange={changeHandler}
        />
      </FormControl>
      <Button
        sx={{ my: 2 }}
        onClick={submitHandler}
        endIcon={<ChevronRight />}
        variant="contained"
      >
        Submit
      </Button>
    </Box>
  );
};

const CalendarType: React.FC<any> = ({ submitResponse, step }) => {
  const [val, setValue] = useState<any>("");
  const submitHandler = () => {
    if (val) {
      submitResponse(
        DateTime.fromFormat(val, "dd-LL-yyyy").toISO({ includeOffset: false }),
        step.stepId,
        step.stepType,
        step.id,
        {
          text: val,
        },
        step.botId
      );
    } else {
      toastMessage("warning", "Enter value to submit");
    }
  };

  return (
    <Box sx={BotOptionWrapper}>
      <FormControl sx={InputWrapper}>
        <FormLabel sx={LabelStyle}>Select date here</FormLabel>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(newValue: any) => {
            setValue(newValue?.toFormat("dd'-'LL'-'yyyy"));
          }}
          value={val ? DateTime.fromFormat(val, "dd-LL-yyyy") : null}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ maxWidth: "200px", mb: 1 }}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                placeholder: "DD/MM/YYYY",
              }}
            />
          )}
        />
      </FormControl>
      <Button
        sx={{ my: 2 }}
        onClick={submitHandler}
        endIcon={<ChevronRight />}
        variant="contained"
      >
        Submit
      </Button>
    </Box>
  );
};

const BotOptions: React.FC<any> = ({ step, submitResponse }) => {
  if (step?.stepType === "multiple_choice" || step?.stepType === "yes_no") {
    return (
      <ChoiceType
        options={step.options}
        submitResponse={submitResponse}
        step={step}
      />
    );
  } else if (
    step?.stepType === "short_text" ||
    step?.stepType === "long_text" ||
    step?.stepType === "number"
  ) {
    return <TextType submitResponse={submitResponse} step={step} />;
  } else if (step?.stepType === "calendar") {
    return <CalendarType submitResponse={submitResponse} step={step} />;
  } else if (step?.stepType === "slider") {
    return <SliderType submitResponse={submitResponse} step={step} />;
  }

  return null;
};

export default BotOptions;
