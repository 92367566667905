import {
  Box,
  styled,
  SxProps,
  TableCell,
  tableCellClasses,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F4F6",
    color: "#4B5563",
    padding: "0 15px",
    height: "46px",
    fontSize: "14px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#ffffff",
    padding: "0 15px",
    height: "65px",
    borderBottom: "1px solid",
    borderColor: "#E5E7EB",
    fontSize: "16px",
    fontWeight: 500,
  },
}));

export const StyledSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "& .MuiTableSortLabel-icon": {
    opacity: 0.5,
    color: "#6B7280",
  },
}));

export const TablePaginationStyle: SxProps = {
  backgroundColor: "#ffffff",
  borderBottom: "none",
  "& 	.MuiTablePagination-toolbar": {
    height: "78px",
    paddingLeft: 2.5,
    "& 	.MuiTablePagination-spacer": {
      display: "flex",
      flex: "none",
      order: 3,
    },
    "& 	.MuiTablePagination-actions": {
      display: "flex",
      gap: 1,
      order: 1,
      marginLeft: 0,
      marginRight: 1,
      color: "text.tertiary",
      "& .MuiButtonBase-root": {
        padding: 0,
        "& .MuiSvgIcon-root": {
          width: "34px",
          height: "34px",
        },
      },
    },
    "& .MuiTablePagination-displayedRows": {
      display: "flex",
      order: 2,
    },
  },
};

export const paginationLabel = ({ from, to, count }: any) => {
  return (
    <Typography variant="body1" component="span" color="text.grey">
      Show{" "}
      <Typography component="span" fontWeight="regular" color="text.tertiary">
        {from}-{to}
      </Typography>{" "}
      of{" "}
      <Typography component="span" fontWeight="regular" color="text.tertiary">
        {count}
      </Typography>
    </Typography>
  );
};

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F3F4F6",
    color: "#4B5563",
    fontSize: "14px",
    fontWeight: 600,
    borderBottom: "none",
    "& .MuiDataGrid-columnHeader": {
      "&:focus , &:focus-within": {
        outline: "none",
      },
    },
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    backgroundColor: "#ffffff",
    borderBottom: "1px solid ",
    borderColor: "#E5E7EB",
    fontSize: "16px",
    fontWeight: 500,
    "&:focus , &:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-checkboxInput": {
    color: "#9CA3AF",
  },
  "& .MuiDataGrid-footerContainer": {
    flexDirection: "row-reverse",
    backgroundColor: "#ffffff",
    height: "76px",
  },
  "& .clickable-column": {
    cursor: "pointer",
  },
}));

export const NoDataContainer = styled(Box)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  padding: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const pageSize: number = 15;
