import { MoreVert } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  deleteQuestion,
  duplicateQuestion,
  handleQuestionMove,
  setActiveIndex,
} from "../../../Redux/reducers/onboardingSlice";
import { OnboardingQuestion } from "../../../types/onboarding";
import {
  getQLabelFromType,
  onboardingQuestionTypes,
} from "../../../utils/onboarding";
import {
  QuestionPickerItemSelectedStyle,
  QuestionPickerItemStyle,
} from "./styles";

type Props = {
  index: number;
  question: OnboardingQuestion;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const QuestionPickerItem: React.FC<Props> = ({ question, index }) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [, drop] = useDrop(
    () => ({
      accept: onboardingQuestionTypes.map((q) => q.type),
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const dropIndex = index;

        if (dragIndex === dropIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        dispatch(handleQuestionMove({ dragIndex, dropIndex }));
        item.index = dropIndex;
      },
    }),
    [index]
  );

  const [, drag] = useDrag(() => {
    return {
      type: question.type,
      item: {
        index: index,
      },
    };
  }, [index, question.type]);

  const { activeQuestionIndex } = useAppSelector((state) => state.onboarding);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteItem = () => {
    handleClose();
    dispatch(deleteQuestion({}));
  };

  const duplicateItem = () => {
    handleClose();
    dispatch(duplicateQuestion());
  };

  const selectItem = (index: number) => {
    dispatch(setActiveIndex({ index }));
  };

  drag(drop(ref));

  return (
    <>
      <Box
        sx={
          index === activeQuestionIndex
            ? QuestionPickerItemSelectedStyle
            : QuestionPickerItemStyle
        }
        ref={ref}
        key={question.id}
        onClick={() => selectItem(index)}
      >
        <Typography noWrap variant="subtitle1">
          {question.variableName || getQLabelFromType(question.type)}
        </Typography>
        <IconButton
          onClick={handleClick}
          sx={{ marginLeft: "auto", flexShrink: 0 }}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem key="duplicate" onClick={duplicateItem}>
          Duplicate
        </MenuItem>
        <MenuItem key="delete" onClick={deleteItem}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

const QuestionPickerItems: React.FC = () => {
  const { questions } = useAppSelector((state) => state.onboarding);

  return (
    <>
      {questions.map((question, index) => {
        return (
          <QuestionPickerItem
            question={question}
            index={index}
            key={question.id}
          />
        );
      })}
    </>
  );
};

export default QuestionPickerItems;
