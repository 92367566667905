import { Add } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../Redux/hooks";
import { addQuestion } from "../../../Redux/reducers/onboardingSlice";
import { onboardingQuestionType } from "../../../types/onboarding";
import { onboardingQuestionTypes } from "../../../utils/onboarding";
import QuestionPickerItems from "./QuestionPickerItems";
import { QuestionPickerWrapper } from "./styles";

const QuestionPicker = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewQuestion = (type: onboardingQuestionType) => {
    dispatch(addQuestion({ type }));
    handleClose();
  };

  return (
    <Box sx={QuestionPickerWrapper}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6">Add New Steps</Typography>
        <IconButton sx={{ marginLeft: "auto" }} onClick={handleClick}>
          <Add />
        </IconButton>
      </Box>
      <QuestionPickerItems />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {onboardingQuestionTypes.map((question) => {
          return (
            <MenuItem
              key={question.type}
              onClick={() => addNewQuestion(question.type)}
            >
              {question.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default QuestionPicker;
