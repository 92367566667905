import { Button, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setOnboardingLoader } from "../../../Redux/reducers/onboardingSlice";
import http from "../../../utils/http";
import { validateOnboarding } from "../../../utils/onboarding";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { StyledHeader } from "../../Common/styles/header";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { questions } = useAppSelector((state) => state.onboarding);

  const save = async () => {
    try {
      dispatch(setOnboardingLoader(true));
      const sanitizedQuestions = validateOnboarding(questions);
      const res: AxiosResponse = await http.post("/onboarding/bots/steps", {
        steps: sanitizedQuestions,
      });
      toastMessage("success", res.data.message);
      // console.log(sanitizedQuestions);
      dispatch(setOnboardingLoader(false));
      // navigate("/app/cms");
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setOnboardingLoader(false));
    }
  };

  return (
    <StyledHeader>
      <Typography variant="subtitle2" fontWeight="medium">
        Onboarding Builder
      </Typography>
      <Button variant="contained" sx={{ marginLeft: "auto" }} onClick={save}>
        Save
      </Button>
    </StyledHeader>
  );
};

export default Header;
