import { useEffect } from "react";
import { Box, Chip, CircularProgress, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setCloudLoader,
  setTagsData,
  toggleCloud,
} from "../../Redux/reducers/cloudSlice";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { LoadingContainer } from "./cloud.style";

const Tags = () => {
  const dispatch = useAppDispatch();
  const { searchText, loading, tagsData, toggleLoader } = useAppSelector(
    (state) => state.cloud
  );

  useEffect(() => {
    const fetchTagsData = async () => {
      try {
        dispatch(setCloudLoader(true));
        let url = "/tag";
        if (searchText) {
          url += `?q=${searchText}`;
        }
        const res = await http.get(url);
        dispatch(setTagsData(res.data?.data));
        dispatch(setCloudLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setCloudLoader(false));
      }
    };
    fetchTagsData();
  }, [dispatch, searchText, toggleLoader]);

  const removeTag = async (id: any) => {
    try {
      dispatch(setCloudLoader(true));
      const res = await http.delete(`/tag/${id}`);
      toastMessage("success", res.data.message);
      dispatch(toggleCloud());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCloudLoader(false));
    }
  };

  return !loading ? (
    <>
      {tagsData.length > 0 ? (
        tagsData.map((tag: any) => (
          <Chip
            label={tag?.label}
            key={tag?.id}
            sx={{ mr: "10px", mb: "20px", fontSize: 14, py: 2 }}
            onDelete={() => removeTag(tag?.id)}
            variant="outlined"
          />
        ))
      ) : (
        <Typography variant="h2">
          {searchText ? "No matches found" : "No tags present"}
        </Typography>
      )}
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default Tags;
