import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { v4 as uuid } from "uuid";
import { PathFormElement } from "./styles";

type Props = {
  lang: string;
  category: string;
  callback: Function;
  closeModal: Function;
};

const EditLearningPath: React.FC<Props> = ({
  closeModal,
  callback,
  category,
  lang,
}) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState<any[]>([]);

  const submitHandler = async () => {
    try {
      const ids = lessons.filter((l) => l.lessonId).map((l) => l.lessonId);
      if (ids.length === 0) {
        toastMessage(
          "warning",
          "The Learning path should have atleast one lesson"
        );
        return;
      }
      setButtonLoader(true);
      const body = {
        addIds: ids,
      };
      const res = await http.post(`/learning-workflows/${category}`, body);
      toastMessage("success", res.data.message);
      closeModal();
      callback();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        setLoading(true);
        console.log(lang, category);
        const lessonResponse = await http.get(
          `/lms/lessons/search?lang=${lang}&status=active`
        );
        const newOptions = lessonResponse.data?.data?.educationLessons.map(
          (module: any) => {
            return {
              label: module?.name,
              value: module?.id,
            };
          }
        );
        setOptions(newOptions);
        const res = await http.get(`/learning-workflows/${category}`);
        const newLessons = res.data.data.lessons.map((lesson: any) => {
          return {
            lessonId: lesson.lessonId,
            id: lesson.id,
          };
        });
        if (newLessons.length > 0) {
          setLessons(newLessons);
        } else {
          setLessons([
            {
              lessonId: "",
              id: uuid(),
            },
          ]);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchLessons();
  }, [setOptions, setLoading, lang, category]);

  const modifyPosition = (index: number, id: string) => {
    setLessons((prev) => {
      return prev.map((lesson, it: number) => {
        if (it === index) {
          return { ...lesson, lessonId: id };
        } else {
          return lesson;
        }
      });
    });
  };

  const addChoice = () => {
    setLessons((prev) => {
      return [
        ...prev,
        {
          lessonId: "",
          id: uuid(),
        },
      ];
    });
  };
  const deleteChoice = (delIndex: number) => {
    if (lessons.length > 1) {
      setLessons((prev) => {
        return prev.filter((_1, index) => index !== delIndex);
      });
    }
  };

  return (
    <Modal open={true} onClose={() => closeModal()}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={"Edit Learning Path"}
          onCloseClick={() => closeModal()}
        />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress size={25} />
          </Box>
        ) : (
          <>
            <Typography variant="h6" mb={2}>
              Order Lessons according to the learning path
            </Typography>
            {lessons.map((lesson, index) => {
              return (
                <Box key={lesson.id} sx={PathFormElement}>
                  <Select
                    fullWidth
                    value={lesson.lessonId}
                    onChange={(e) => {
                      modifyPosition(index, e.target.value);
                    }}
                  >
                    {options.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={addChoice} sx={{ ml: 1 }}>
                    <Add />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteChoice(index)}
                    color="error"
                    disabled={lessons.length === 1}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              );
            })}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1.5,
                mt: 3,
              }}
            >
              {!buttonLoader ? (
                <>
                  <Button variant="outlined" onClick={() => closeModal}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={submitHandler}>
                    Save
                  </Button>
                </>
              ) : (
                <CircularProgress size={25} />
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default EditLearningPath;
