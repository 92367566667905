import webviewHttp from "./webviewHttp";

export const addBookmark = async (body: any, lessonId: string) => {
  await webviewHttp.post(`/lms/lessons/${lessonId}/bookmarks`, body);
};

export const removeBookmark = async (body: any, lessonId: string) => {
  await webviewHttp.post(
    `/lms/lessons/${lessonId}/bookmarks/remove_bookmark`,
    body
  );
};

export const getBookmarks = async (unitId: string, lessonId: any) => {
  const id = localStorage.getItem("userId") || "";
  const res = await webviewHttp.get(
    `/lms/lessons/${lessonId}/bookmarks?participantId=${id}&educationLessonUnitId=${unitId}`
  );
  return res;
};
