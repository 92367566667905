import { useCallback, useMemo, useState } from "react";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { debounce } from "lodash";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addHighlight,
  addTag,
  deleteHighlights,
  deleteTag,
  setLoading,
} from "../../../../Redux/reducers/cmsBuilderSlice";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { CMSInputLabel, CMSInputWrapper, TagSearchContainer } from "../styles";
import { Edit } from "@mui/icons-material";

type Props = {
  section: any;
  sectionIndex: number;
};

const Tags: React.FC<Props> = ({ section, sectionIndex }) => {
  const dispatch = useAppDispatch();

  const [tags, setTags] = useState([]);
  const [tagLabel, setTagLabel] = useState("");
  const [createTag, showCreateTag] = useState(false);

  const [tagOpen, setTagOpen] = useState(false);

  const closeTagMenu = () => {
    setTagOpen(false);
  };

  const handleTagLabel = (event: any) => {
    setTagLabel(event.target.value);
  };

  const handleTags = (event: any, tag: any) => {
    if (event.target.checked) {
      dispatch(
        addTag({
          sectionIndex,
          tag: tag,
        })
      );
    } else {
      dispatch(
        deleteTag({
          sectionIndex,
          tag: tag,
        })
      );
    }
  };

  const removeTag = (label: string) => {
    dispatch(
      deleteTag({
        sectionIndex,
        tag: label,
      })
    );
  };

  const fetchTagsData = useCallback(
    async (search: string) => {
      try {
        if (!search) {
          return;
        }
        dispatch(setLoading(true));
        const res = await http.get(`/tag?q=${search}`);
        const tags = res.data.data.map((tag: any) => {
          return tag.label;
        });
        setTags(tags);
        dispatch(setLoading(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setLoading(false));
      }
    },
    [dispatch, setTags]
  );

  const addNewTag = async () => {
    try {
      if (!tagLabel) {
        return;
      }
      dispatch(setLoading(true));
      await http.post("/tag", { label: tagLabel });
      setTags([]);
      showCreateTag(false);
      setTagLabel("");
      dispatch(setLoading(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoading(false));
    }
  };

  const tagSearch = useMemo(
    () =>
      debounce((event: any) => {
        fetchTagsData(event.target.value);
      }, 500),
    [fetchTagsData]
  );

  const tagMenu = createTag ? (
    <>
      <DialogContent>
        <FormControl sx={CMSInputWrapper}>
          <FormLabel sx={CMSInputLabel}>Tag name</FormLabel>
          <TextField
            fullWidth
            value={tagLabel}
            onChange={handleTagLabel}
            placeholder="Tag name"
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={() => showCreateTag(false)}>
          Select Tags
        </Button>
        <Button variant="contained" onClick={addNewTag}>
          Create a new tag
        </Button>
      </DialogActions>
    </>
  ) : (
    <>
      <DialogTitle>Tags</DialogTitle>
      <DialogContent>
        <DialogContentText>Search for Tags</DialogContentText>
        <TextField
          fullWidth
          onChange={tagSearch}
          placeholder="Search for tag"
          sx={{ my: 2 }}
        />
        <Box sx={TagSearchContainer}>
          {tags.length > 0 ? (
            tags.map((tag: any) => {
              const checked = !!section.tags.find(
                (selTag: any) => selTag === tag
              );
              return (
                <FormControlLabel
                  key={tag}
                  control={
                    <Checkbox
                      onChange={(event) => handleTags(event, tag)}
                      checked={checked}
                    />
                  }
                  label={tag}
                />
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <DialogContentText>No Matches Found.</DialogContentText>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={() => showCreateTag(true)}>
          Create new tag instead
        </Button>
        <Button variant="contained" onClick={() => setTagOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Box>
      <Typography sx={CMSInputLabel} mb={2}>
        Tags
      </Typography>
      <Box>
        {section.tags.map((tag: any) => (
          <Chip
            label={tag}
            key={tag}
            sx={{ mr: "10px", mb: "20px", fontSize: 14, py: 2 }}
            onDelete={() => removeTag(tag)}
            variant="outlined"
          />
        ))}
      </Box>
      <Dialog
        open={tagOpen}
        onClose={closeTagMenu}
        fullWidth
        maxWidth="xs"
        sx={{ zIndex: (theme) => theme.zIndex.drawer }}
      >
        {tagMenu}
      </Dialog>
      <Button
        startIcon={<Edit />}
        onClick={() => setTagOpen(true)}
        variant="contained"
        sx={{ mb: 2 }}
      >
        Add or edit Tags
      </Button>
    </Box>
  );
};

const Hightlights: React.FC<Props> = ({ section, sectionIndex }) => {
  const dispatch = useAppDispatch();

  const [highlights, setHighlights] = useState([]);
  const [highlightLabel, setHighlightLabel] = useState("");
  const [highlightDesc, setHighlightDesc] = useState("");
  const [createHighlight, showCreateHighlight] = useState(false);
  const [highlightVisible, setHighlightVisible] = useState(false);

  const handleHighlightVisible = (visibility: any) => {
    setHighlightVisible(visibility);
  };

  const handleHighlightLabel = (event: any) => {
    setHighlightLabel(event.target.value);
  };

  const handleHighlightDesc = (event: any) => {
    setHighlightDesc(event.target.value);
  };

  const handleHighlights = (event: any, highlight: any) => {
    if (event.target.checked) {
      dispatch(
        addHighlight({
          highlight: highlight,
          sectionIndex,
        })
      );
    } else {
      dispatch(
        deleteHighlights({
          highlightId: highlight.id,
          sectionIndex,
        })
      );
    }
  };

  const removeHighlight = (id: string) => {
    dispatch(
      deleteHighlights({
        highlightId: id,
        sectionIndex,
      })
    );
  };

  const fetchHighlightsData = useCallback(
    async (search: string) => {
      try {
        if (!search) {
          return;
        }
        dispatch(setLoading(true));
        const res = await http.get(`/highlight?size=15&page=1&q=${search}`);
        const highlights = res.data.data.rows.map((highlight: any) => {
          return {
            id: highlight.id,
            label: highlight.label,
            description: highlight.description,
          };
        });
        setHighlights(highlights);
        dispatch(setLoading(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setLoading(false));
      }
    },
    [dispatch, setHighlights]
  );

  const highlightSearch = useMemo(
    () =>
      debounce((event: any) => {
        fetchHighlightsData(event.target.value);
      }, 500),
    [fetchHighlightsData]
  );

  const addNewHighlight = async () => {
    try {
      if (!highlightLabel || !highlightDesc) {
        toastMessage("warning", "Please enter all the fields");
        return;
      }
      dispatch(setLoading(true));
      await http.post("/highlight", {
        label: highlightLabel,
        description: highlightDesc,
      });
      setHighlights([]);
      showCreateHighlight(false);
      setHighlightLabel("");
      setHighlightDesc("");
      dispatch(setLoading(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setLoading(false));
    }
  };

  const highlightsMenu = createHighlight ? (
    <>
      <DialogContent>
        <FormControl sx={CMSInputWrapper}>
          <FormLabel sx={CMSInputLabel}>Highlight name</FormLabel>
          <TextField
            fullWidth
            value={highlightLabel}
            onChange={handleHighlightLabel}
            placeholder="Highlight name"
          />
        </FormControl>
        <FormControl sx={CMSInputWrapper}>
          <FormLabel sx={CMSInputLabel}>Highlight description</FormLabel>
          <TextField
            multiline
            rows={3}
            fullWidth
            value={highlightDesc}
            onChange={handleHighlightDesc}
            placeholder="Highlight description"
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={() => showCreateHighlight(false)}>
          Select Highlights
        </Button>
        <Button variant="contained" onClick={addNewHighlight}>
          Create a new Highlight
        </Button>
      </DialogActions>
    </>
  ) : (
    <>
      <DialogTitle>Highlight</DialogTitle>
      <DialogContent>
        <DialogContentText>Search for Highlights</DialogContentText>
        <TextField
          fullWidth
          onChange={highlightSearch}
          placeholder="Search for highlight"
          sx={{ my: 2 }}
        />
        <Box sx={TagSearchContainer}>
          {highlights.length > 0 ? (
            highlights.map((highlight: any) => {
              const checked = !!section.highlights.find(
                (selected: any) => selected.id === highlight.id
              );
              return (
                <FormControlLabel
                  key={highlight.id}
                  control={
                    <Checkbox
                      onChange={(event) => handleHighlights(event, highlight)}
                      checked={checked}
                    />
                  }
                  label={highlight.label}
                />
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <DialogContentText>No Matches Found.</DialogContentText>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={() => showCreateHighlight(true)}>
          Create a new highlight instead
        </Button>
        <Button
          variant="contained"
          onClick={() => handleHighlightVisible(false)}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <Box>
        <Typography sx={CMSInputLabel} mb={2}>
          Highlights
        </Typography>
        <Box>
          {section.highlights.map((high: any) => (
            <Chip
              label={high.label}
              key={high.id}
              sx={{ mr: "10px", mb: "20px", fontSize: 14, py: 2 }}
              onDelete={() => removeHighlight(high.id)}
              variant="outlined"
            />
          ))}
        </Box>
      </Box>
      <Dialog
        open={highlightVisible}
        onClose={() => handleHighlightVisible(false)}
        fullWidth
        maxWidth="xs"
        sx={{ zIndex: (theme) => theme.zIndex.drawer }}
      >
        {highlightsMenu}
      </Dialog>
      <Button
        startIcon={<Edit />}
        onClick={() => handleHighlightVisible(true)}
        variant="contained"
        sx={{ mb: 2 }}
      >
        Add or edit Highlights
      </Button>
    </>
  );
};

const TagsAndHighlights: React.FC<Props> = ({ section, sectionIndex }) => {
  return (
    <>
      <Tags section={section} sectionIndex={sectionIndex} />
      <Hightlights section={section} sectionIndex={sectionIndex} />
    </>
  );
};

export default TagsAndHighlights;
