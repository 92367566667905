import React, { useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import * as yup from "yup";
import { Formik } from "formik";
import { UploadWrapper } from "../../CMS/cms.style";
import { uploadFile } from "../../../utils/upload";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "../../CMS/Icons";

const UploadItem: React.FC<any> = ({ image, setFieldValue, type }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "unsorted_file");
          if (type === "dark") {
            setFieldValue("darkLogoUrl", url);
          } else {
            setFieldValue("lightLogoUrl", url);
          }
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, mb: 2.5 }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <>
              <ImageUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

let schema = yup.object().shape({
  appName: yup.string().required("App Name is Required"),
  // darkColor: yup.string().required("Dark Theme Primary Color is Required"),
  // lightColor: yup.string().required("Light Theme Primary Color is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  data: any;
};

const ThemeConfigModal: React.FC<Props> = ({
  showModal,
  closeModal,
  refreshPage,
  data,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      if (!values?.darkLogoUrl) {
        throw new Error("Dark Theme logo cannot be empty");
      }
      if (!values?.lightLogoUrl) {
        throw new Error("Light Theme logo cannot be empty");
      }
      const body = {
        config: {
          appName: values?.appName,
          theme: {
            dark: {
              logoUrl: values?.darkLogoUrl || null,
              primaryColor: "#ffffff",
            },
            light: {
              logoUrl: values?.lightLogoUrl || null,
              primaryColor: "#ffffff",
            },
          },
        },
      };
      const res: AxiosResponse = await http.patch("/settings/app-theme", body);
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"Edit Theme Configurations Modal"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            appName: data?.appName || "",
            darkLogoUrl: data?.theme?.dark?.logoUrl || "",
            // darkColor: data?.theme?.dark?.primaryColor || "",
            lightLogoUrl: data?.theme?.light?.logoUrl || "",
            // lightColor: data?.theme?.light?.primaryColor || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="app-name">
                  App Name
                </FormLabel>
                <TextField
                  placeholder="App Name"
                  id="app-name"
                  {...getFieldProps("appName")}
                  error={touched?.appName && errors?.appName ? true : false}
                  helperText={
                    touched?.appName && errors?.appName
                      ? (errors?.appName as string)
                      : " "
                  }
                />
              </FormControl>
              <Box>
                <Typography variant="subtitle2" fontWeight="regular" mb={2}>
                  Dark Theme
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={{ ...LabelStyle, mb: 0 }}>
                    Image Upload
                  </FormLabel>
                  <FormLabel
                    sx={{
                      fontSize: "14px",
                      mb: "10px",
                      color: "gray",
                    }}
                  >
                    Please upload logo in circular dimensions
                  </FormLabel>

                  <UploadItem
                    image={values?.darkLogoUrl}
                    setFieldValue={setFieldValue}
                    type="dark"
                  />
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="dark-primary-color">
                    Primary Color
                  </FormLabel>
                  <TextField
                    placeholder="Primary Color"
                    id="dark-primary-color"
                    {...getFieldProps("darkColor")}
                    error={
                      touched?.darkColor && errors?.darkColor ? true : false
                    }
                    helperText={
                      touched?.darkColor && errors?.darkColor
                        ? (errors?.darkColor as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>
              <Box>
                <Typography variant="subtitle2" fontWeight="regular" mb={2}>
                  Light Theme
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={{ ...LabelStyle, mb: 0 }}>
                    Image Upload
                  </FormLabel>
                  <FormLabel
                    sx={{
                      fontSize: "14px",
                      mb: "10px",
                      color: "gray",
                    }}
                  >
                    Please upload logo in circular dimensions
                  </FormLabel>
                  <UploadItem
                    image={values?.lightLogoUrl}
                    setFieldValue={setFieldValue}
                    type="light"
                  />
                </FormControl>
                {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="light-primary-color">
                    Primary Color
                  </FormLabel>
                  <TextField
                    placeholder="Primary Color"
                    id="light-primary-color"
                    {...getFieldProps("lightColor")}
                    error={
                      touched?.lightColor && errors?.lightColor ? true : false
                    }
                    helperText={
                      touched?.lightColor && errors?.lightColor
                        ? (errors?.lightColor as string)
                        : " "
                    }
                  />
                </FormControl> */}
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ThemeConfigModal;
