import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
export interface UserState {
  isUserAuthenticated: boolean;
  role: string;
  hasAdminAccess: boolean;
  hasMasterAdminAccess: boolean;
}

const initialState: UserState = {
  isUserAuthenticated: false,
  role: "",
  hasAdminAccess: false,
  hasMasterAdminAccess: false,
};

const getIntialState = (): UserState => {
  let isUserAuthenticated = false;
  let role = "";
  let hasAdminAccess = false;
  let hasMasterAdminAccess = false;
  const token = localStorage.getItem("wellfeet-access-token") || "";
  if (token) {
    try {
      const decoded: any = jwt_decode(token);
      isUserAuthenticated = true;
      role = decoded.role;
      hasAdminAccess = ["master_admin", "admin"].includes(role);
      hasMasterAdminAccess = ["master_admin"].includes(role);
    } catch (err) {}
  }
  return {
    ...initialState,
    isUserAuthenticated: isUserAuthenticated,
    hasAdminAccess: hasAdminAccess,
    hasMasterAdminAccess: hasMasterAdminAccess,
    role: role,
  };
};

export const userSlice = createSlice({
  name: "user",
  initialState: getIntialState,
  reducers: {
    setUserAuth: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        role: string;
      }>
    ) => {
      state.role = action.payload.role;
      state.isUserAuthenticated = action.payload.authenticated;
      state.hasAdminAccess = ["master_admin", "admin"].includes(
        action.payload.role
      );
      state.hasMasterAdminAccess = ["master_admin"].includes(
        action.payload.role
      );
    },
    reset: () => initialState,
  },
});

export const { setUserAuth, reset } = userSlice.actions;

export default userSlice.reducer;
