import { Box, Button, Typography } from "@mui/material";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";

type Props = {
  refreshPage: () => void;
};

const BrokenListHeader = ({ refreshPage }: Props) => {
  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography variant="subtitle2" fontWeight="medium">
          Chatbot Broken links
        </Typography>
      </Box>
      <Box sx={HeaderRightContent}>
        <Button variant="contained" onClick={refreshPage}>
          Refresh
        </Button>
      </Box>
    </StyledHeader>
  );
};

export default BrokenListHeader;
