import { Add, ChevronLeft, Delete, Edit, IosShare } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../../Common/styles/header";
import {
  ContainerStyle,
  LoadingContainer,
  ModuleCardsContainer,
  ModulesHeaderContainer,
} from "../../cms.style";
import RearrangeCMSModal from "../../modals/RearrangeCMSModal";
import EducationModuleCard from "./EducationModuleCard";
import { downloadFile, importPage } from "../../../../utils/education";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Pages = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const unitId = useQuery().get("unitId");

  const [toggleLoader, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [unitData, setUnitData] = useState<any>({});
  const [unitPages, setUnitPages] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [showRearrangeModal, setRearrangeModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState<any>();

  const { pages } = unitData;

  const modifySearchTerm = (val: string) => {
    setSearchTerm(val);
  };

  useEffect(() => {
    if (pages && pages.length) {
      if (searchTerm) {
        const newUnits = pages.filter((item: any) => {
          return item.title.toLowerCase().includes(searchTerm);
        });
        setUnitPages(newUnits);
      } else {
        setUnitPages([...pages]);
      }
    } else {
      setUnitPages([]);
    }
  }, [searchTerm, pages]);

  useEffect(() => {
    const fetchLessonDetails = async () => {
      if (id) {
        try {
          setLoading(true);
          const res: AxiosResponse = await http.get(
            `/lms/lessons/${id}/units/${unitId}`
          );
          const unit = res.data.data;
          unit.pages.sort((a: any, b: any) => {
            return a.position - b.position;
          });
          unit.pages.map((page: any, index: number) => {
            return {
              ...page,
              position: index + 1,
            };
          });
          setUnitData(unit);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      }
    };
    fetchLessonDetails();
  }, [id, unitId, toggleLoader]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleNavigate = (pageId?: string) => {
    navigate(
      `/app/cms/preview/${unitId}?${
        pageId ? "pageId=" + pageId + "&" : ""
      }lessonId=${id}`
    );
  };

  const editPage = () => {
    navigate(
      `/app/cms/builder/${unitId}?pageId=${selectedPage?.id}&lessonId=${id}`
    );
    closeMenu();
  };

  const exportPage = async () => {
    try {
      closeMenu();
      setLoading(true);
      const response: AxiosResponse = await http.get(
        `/lms/lessons/${id}/pages/${selectedPage?.id}`
      );
      downloadFile(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, page: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const deleteHandler = async () => {
    try {
      setLoading(true);
      const deleteId = selectedPage?.id;
      closeMenu();
      const res = await http.delete(`/lms/lessons/${unitId}/pages/${deleteId}`);
      toastMessage("success", res.data.message);
      setToggle((prev) => !prev);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  const importHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          var result = JSON.parse(JSON.stringify(e.target.result));
          await importPage(JSON.parse(result), unitId || "");
          setToggle((prev) => !prev);
        } catch (err) {
          setLoading(false);
          errorToastMessage(err as Error);
        }
      };
      reader.readAsText(file);

      return false;
    }
  };

  const callback = () => {
    setToggle((pre) => !pre);
  };

  const closeRearrangeModal = () => {
    setRearrangeModal(false);
  };

  const addPage = () => {
    navigate(`/app/cms/builder/${unitId}?lessonId=${id}`);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ChevronLeft
              onClick={handleBack}
              sx={{ cursor: "pointer", height: 50, width: 50, mr: 1 }}
            />
            <Typography fontSize={24} fontWeight="medium">
              {unitData?.title}
            </Typography>
          </Box>
          <TextField
            onChange={(e) => modifySearchTerm(e.target.value)}
            style={{ width: "300px" }}
            placeholder="Search Pages"
            defaultValue={searchTerm}
          />
        </Box>
        <Box sx={HeaderRightContent}>
          {unitData?.educationLesson?.status === "draft" && (
            <>
              {pages.length > 0 && (
                <Button variant="outlined" onClick={() => handleNavigate()}>
                  Preview
                </Button>
              )}
              <Button variant="outlined" component="label">
                Import Page
                <input
                  hidden
                  accept="application/json"
                  type="file"
                  onChange={importHandler}
                />
              </Button>
              {pages.length > 1 && (
                <Button
                  variant="outlined"
                  onClick={() => setRearrangeModal(true)}
                >
                  Rearrange
                </Button>
              )}
              <Button variant="outlined" startIcon={<Add />} onClick={addPage}>
                Add Page
              </Button>
            </>
          )}
        </Box>
      </StyledHeader>
      {!loading ? (
        <Box sx={ContainerStyle}>
          <Box sx={ModulesHeaderContainer}>
            <Typography variant="subtitle1" fontWeight={600}>
              All {unitPages.length} pages
            </Typography>
          </Box>
          {unitPages.length > 0 ? (
            <Box sx={ModuleCardsContainer}>
              {unitPages.map((page: any, index: number) => (
                <EducationModuleCard
                  key={page?.id}
                  index={index}
                  data={page}
                  handleNavigate={handleNavigate}
                  type={"page"}
                  openMenu={openMenu}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "10vh",
              }}
            >
              <Typography variant="h2">
                {searchTerm ? "No matches found" : "No Pages present"}
              </Typography>
            </Box>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                minWidth: "225px",
              },
            }}
          >
            {/* {unitData?.educationLesson?.status === "draft" && ( */}
            <MenuItem onClick={editPage}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            {/* )} */}
            <MenuItem onClick={exportPage}>
              <ListItemIcon>
                <IosShare fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem>
            {unitData?.educationLesson?.status === "draft" && <Divider />}
            {unitData?.educationLesson?.status === "draft" && (
              <MenuItem onClick={deleteHandler}>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
          </Menu>
          {showRearrangeModal && (
            <RearrangeCMSModal
              callback={callback}
              closeModal={closeRearrangeModal}
              parent="unit"
              parentId={unitId || ""}
              items={pages}
              title="Rearrange Pages"
            />
          )}
        </Box>
      ) : (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Pages;
