import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ChevronLeft, Visibility } from "@mui/icons-material";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setCbBuilderLoader,
  setFormDirty,
} from "../../../Redux/reducers/chatbotBuilderSlice";
import { stepSubmitFormat } from "../../../utils/chatbotBuilder";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { CbBuilderStep } from "../../../types/chatbotBuilder";

type Props = {
  refreshPage: () => void;
  steps: CbBuilderStep[];
};

const Header = ({ refreshPage, steps }: Props) => {
  const dispatch = useAppDispatch();
  const { isFaq, isPublished, editable, formDirty } = useAppSelector(
    (state) => state.chatbotBuilder
  );
  const navigate = useNavigate();
  const { id } = useParams();

  const submit = async (draft?: boolean) => {
    try {
      if (!editable) {
        return;
      }
      dispatch(setCbBuilderLoader(true));
      const sanitizedSteps = stepSubmitFormat(steps, isFaq);
      const body = {
        id: id,
        status: draft ? "draft" : "active",
        botSteps: sanitizedSteps,
      };
      await http.post(`/bots/${id}/build`, body);
      dispatch(setFormDirty(false));
      if (draft) {
        toastMessage("success", "Draft saved");
      } else {
        toastMessage("success", "Published");
      }
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCbBuilderLoader(false));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const previewHandler = () => {
    if (formDirty) {
      toastMessage("warning", "Save your changes before previewing");
    } else {
      navigate(`/chatbot/preview/${id}`);
    }
  };

  return (
    <StyledHeader>
      <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
        <IconButton onClick={handleBack}>
          <ChevronLeft fontSize="large" htmlColor="#111928" />
        </IconButton>
        <Typography fontSize={24} fontWeight="bold">
          Chat bot
        </Typography>
      </Box>
      <Box sx={HeaderRightContent}>
        {editable && !isPublished && (
          <Button variant="outlined" onClick={() => submit(true)}>
            Draft
          </Button>
        )}
        <Button
          variant="outlined"
          startIcon={<Visibility />}
          onClick={previewHandler}
        >
          Preview
        </Button>
        <Button variant="outlined" onClick={handleBack}>
          Cancel
        </Button>
        {editable && (
          <Button variant="contained" onClick={() => submit()}>
            Publish
          </Button>
        )}
      </Box>
    </StyledHeader>
  );
};

export default Header;
