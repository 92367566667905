import { Box, Typography } from "@mui/material";
import { UserResponseStyle } from "../style";

const UserResponse: React.FC<any> = ({ step }) => {
  return (
    <Box sx={UserResponseStyle}>
      {step?.value?.imageUrl && (
        <img src={step?.value?.imageUrl} alt="answer" className="bot-image" />
      )}
      <Typography variant="subtitle1">{step?.value?.text}</Typography>
    </Box>
  );
};

export default UserResponse;
