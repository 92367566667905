import { cards, CMSIconMap } from "../../CMSTypes";
import { ShuffleIcon } from "../../Icons";
import { useDrag, useDrop } from "react-dnd";
import { MouseEventHandler } from "react";
import { Section } from "../styles";
import { Box, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addSection,
  deleteSection,
  moveSection,
} from "../../../../Redux/reducers/cmsBuilderSlice";

type Props = {
  card: any;
  index: number;
};

const SectionItem: React.FC<Props> = ({ card, index }) => {
  const dispatch = useAppDispatch();
  const Icon = CMSIconMap[card.type];

  const [, dragRef] = useDrag(() => {
    return {
      type: card.type,
      item: {
        type: card.type,
        sort: true,
        origin: index,
      },
    };
  }, [card, index]);

  const [, drop] = useDrop(
    {
      accept: cards.map((card) => card.type),
      drop: (dropItem: any) => {
        if (dropItem.sort) {
          dispatch(
            moveSection({ dragIndex: dropItem.origin, dropIndex: index })
          );
        } else {
          dispatch(addSection({ type: dropItem.type, dropIndex: index }));
        }
      },
    },
    [index]
  );

  const deleteItem: MouseEventHandler = () => {
    dispatch(deleteSection({ index }));
  };

  return (
    <div ref={drop}>
      <Box sx={Section} ref={dragRef}>
        <Box mr={2}>
          <ShuffleIcon />
        </Box>
        <Box mr={2}>
          <Icon />
        </Box>
        <Box>
          <Typography variant="subtitle1" fontWeight={"medium"}>
            {card.name}
          </Typography>
          <Typography variant="body1" fontWeight={"regular"}>
            {card.description}
          </Typography>
        </Box>
        <IconButton sx={{ ml: "auto" }} onClick={deleteItem} color="error">
          <Delete />
        </IconButton>
      </Box>
    </div>
  );
};

export default SectionItem;
