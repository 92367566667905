import { Button } from "@mui/material";
import React from "react";
import { indxToAlpha } from "../../../utils/education";
import { getTranslation } from "../../../utils/webviewLang";

type Props = {
  polls: any;
  choices: any;
  sectionId: string;
  modifyPollResponse: Function;
  submitPoll: Function;

  lang: string;
};

const PollQuestion: React.FC<Props> = ({
  choices,
  sectionId,
  polls,
  modifyPollResponse,
  submitPoll,
  lang,
}) => {
  const poll = polls[sectionId];
  const changeHandler = (id: string) => {
    modifyPollResponse(sectionId, id);
  };

  let submitted = poll?.submitted;

  return (
    <>
      <div className="question-choices">
        {choices.map((choice: any, index: number) => {
          let text = "";
          if (submitted && poll?.aggregate) {
            text = poll?.aggregate[choice.id] || "0%";
          }
          return (
            <div
              key={choice.id}
              className={
                poll?.value === choice.id
                  ? "question-choice selected"
                  : "question-choice"
              }
              onClick={() => changeHandler(choice.id)}
            >
              {choice.image && (
                <img
                  src={choice.image}
                  className="question-choice-image"
                  alt="choice"
                />
              )}
              <span>{indxToAlpha(index) + ". " + choice.label}</span>
              {text && <span className="aggregate">{text}</span>}
            </div>
          );
        })}
      </div>
      <Button
        variant="contained"
        sx={{ my: 2 }}
        onClick={() => submitPoll(sectionId)}
      >
        {getTranslation("submit", lang)}
      </Button>
    </>
  );
};

export default PollQuestion;
