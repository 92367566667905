import { Box, CircularProgress, Modal } from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { ModalHeader } from "../Common/styles/modal";

type AboutUsPreviewProps = {
  lang: string;
  closeModal: Function;
};

const AboutUsPreview: React.FC<AboutUsPreviewProps> = ({
  lang,
  closeModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await http.get(`/app/settings/about-us?lang=${lang}`);
        const newText = DOMPurify.sanitize(res.data.data.value);
        setText(newText);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        // setLoading(false);
      }
    };
    fetchData();
  }, [lang, setLoading]);

  return (
    <Modal open={true} onClose={() => closeModal()}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          height: "700px",
          boxShadow: 4,
          bgcolor: "white",
          borderRadius: "0.7rem",
          padding: 3,
          paddingTop: 2,
          outline: "none",
        }}
      >
        <ModalHeader
          title={"About us Preview"}
          onCloseClick={() => closeModal()}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ width: "100%", height: "600px" }}>
            <iframe
              srcDoc={text}
              sandbox=""
              title="preview"
              className="iframe-container"
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AboutUsPreview;
