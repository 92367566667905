import React, { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import http from "../../utils/http";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { Search } from "@mui/icons-material";

const OnboardingSetupModal: React.FC<any> = ({
  showModal,
  closeModal,
}: any) => {
  const [data, setData] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active`
            );
            const options = res.data?.data?.educationLessons?.map(
              (lesson: any) => {
                return {
                  value: lesson?.id,
                  label: lesson?.name,
                };
              }
            );
            setLessons(options);
            setSearchLoader(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res = await http.get("/users/onboarding-settings");
        const settings = res.data.data.settings || {};
        const lessons: any[] = [];
        const newData = res.data.data.options.map((option: any) => {
          const lesson = settings?.[option.id]?.["default"];
          const caretaker = settings?.[option.id]?.["caretaker"];
          if (lesson) {
            const found = lessons?.findIndex((oldLesson) => {
              return oldLesson?.value === lesson.id;
            });
            if (found === -1) {
              lessons.push({
                value: lesson?.id,
                label: lesson?.name,
              });
            }
          }
          if (caretaker) {
            const found = lessons?.findIndex((oldLesson) => {
              return oldLesson?.value === caretaker?.id;
            });
            if (found === -1) {
              lessons.push({
                value: caretaker?.id,
                label: caretaker?.name,
              });
            }
          }
          return {
            id: option.id,
            text: option.text,
            lessonId: lesson?.id || undefined,
            caretakerId: caretaker?.id || undefined,
            lessonLabel: lesson?.name || undefined,
            caretakerLabel: caretaker?.name || undefined,
          };
        });
        setLessons(lessons);
        setData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setData, setLessons]);

  const submitHandler = async () => {
    try {
      setSubmitLoader(true);
      const payload: any = {};
      data.forEach((option) => {
        payload[option.id] = {
          default: option?.lessonId || null,
          caretaker: option?.caretakerId || null,
        };
      });
      let res: AxiosResponse = await http.post(
        `/users/onboarding-settings`,
        payload
      );
      setSubmitLoader(false);
      toastMessage("success", res.data.message);
      closeModal();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const changeValue = (key: string, value: string, type: string) => {
    setData((prev: any[]) => {
      const newData = prev.map((goal) => {
        if (goal.id === key) {
          return {
            ...goal,
            [type]: value,
          };
        } else {
          return goal;
        }
      });
      return newData;
    });
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title="Onboarding Goal settings"
          onCloseClick={closeModal}
        />
        {!loading ? (
          data?.map((goal: any) => {
            return (
              <FormControl key={goal?.id} sx={{ ...InputWrapper, mb: 3 }}>
                <Typography sx={LabelStyle}>{goal?.text}</Typography>
                <Typography sx={LabelStyle}>Person with diabetes</Typography>
                <Autocomplete
                  freeSolo
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") {
                      handleSearch(value);
                    }
                  }}
                  onChange={(_1: any, value: any) => {
                    changeValue(goal?.id, value?.value, "lessonId");
                    changeValue(goal?.id, value?.label, "lessonLabel");
                  }}
                  options={lessons}
                  value={goal?.lessonLabel || null}
                  isOptionEqualToValue={(option, value) => {
                    return option.label === value;
                  }}
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={true}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search lessons by typing..."
                    />
                  )}
                />
                <Typography sx={{ ...LabelStyle, mt: 2 }}>Caregiver</Typography>
                <Autocomplete
                  freeSolo
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") {
                      handleSearch(value);
                    }
                  }}
                  onChange={(_1: any, value: any) => {
                    changeValue(goal?.id, value?.value, "caretakerId");
                    changeValue(goal?.id, value?.label, "caretakerLabel");
                  }}
                  value={goal?.caretakerLabel || null}
                  options={lessons}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value
                  }
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={true}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search lessons by typing..."
                    />
                  )}
                />
              </FormControl>
            );
          })
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!submitLoader ? (
            <>
              <Button onClick={submitHandler} variant="contained">
                Save
              </Button>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>{" "}
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default OnboardingSetupModal;
