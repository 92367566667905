import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../Redux/hooks";

const ChatbotUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const {
    searchText,
    type,
    filterId,
    page,
    sortOrder,
    sortColumn,
    languageFilter,
  } = useAppSelector((state) => state.chatbot);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    if (searchText) {
      params.set("search", searchText);
    }
    if (sortOrder) {
      params.set("order", sortOrder);
    }
    if (sortColumn) {
      params.set("sort", sortColumn);
    }
    if (filterId) {
      params.set("filter", filterId);
    }
    if (type) {
      params.set("type", type);
    }
    if (languageFilter.length > 0) {
      params.set("lang", languageFilter.join(","));
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    searchText,
    type,
    page,
    filterId,
    sortOrder,
    sortColumn,
    languageFilter,
  ]);

  return <></>;
};

export default ChatbotUrlSetter;
