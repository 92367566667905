import { Add } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { addStep } from "../../../../Redux/reducers/chatbotBuilderSlice";
import {
  CbBuilderStepIconMap,
  CbBuilderStepTypes,
} from "../../../../utils/chatbotBuilder";
import { StepBuilderWrapper } from "../chatbotBuilder.style";
import StepCards from "./StepCards";

const StepBuilder = () => {
  const dispatch = useAppDispatch();
  const { editable } = useAppSelector((state) => state.chatbotBuilder);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewStep = (type: any) => {
    dispatch(addStep({ type }));
    handleClose();
  };

  return (
    <Box sx={StepBuilderWrapper}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        {editable ? (
          <Typography variant="h6">Add New Step</Typography>
        ) : (
          <Typography variant="h6">Steps</Typography>
        )}
        {editable && (
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClick}>
            <Add />
          </IconButton>
        )}
      </Box>
      <Typography variant="body1" mb={2}>
        Below are the steps that are added in chat bot creation
      </Typography>
      <StepCards />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "313px",
            padding: "16px",
          },
        }}
      >
        <Typography variant="h6" fontWeight={700} mb={3}>
          Choose a chat type
        </Typography>
        {CbBuilderStepTypes.map((step) => {
          const Icon = CbBuilderStepIconMap[step?.type];
          return (
            <MenuItem
              key={step.type}
              onClick={() => addNewStep(step.type)}
              sx={{
                padding: "10px",
                height: "44px",
                border: "1px solid #e5e7eb",
                borderRadius: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText>
                <span style={{ fontSize: "16px", fontWeight: 500 }}>
                  {step?.label}
                </span>
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default StepBuilder;
