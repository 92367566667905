import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  debounce,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

const FaqSetupModal = ({ showModal, closeModal }: any) => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState<null | string>(null);
  const [setupDetails, setSetupDetails] = useState<any>({});
  const [bots, setBots] = useState<any>([]);
  const [taBots, setTaBots] = useState<any>([]);
  const [msBots, setMsBots] = useState<any>([]);
  const [zhBots, setZhBots] = useState<any>([]);
  const [tlBots, setTlBots] = useState<any>([]);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const body: any = {
        values: [
          {
            id: values?.enId,
            botId: values.enBot?.id || null,
          },
          {
            id: values?.taId,
            botId: values.taBot?.id || null,
          },
          {
            id: values?.msId,
            botId: values.msBot?.id || null,
          },
          {
            id: values?.zhId,
            botId: values.zhBot?.id || null,
          },
          {
            id: values?.tlId,
            botId: values.tlBot?.id || null,
          },
        ],
      };

      const res: AxiosResponse = await http.put("/app/settings/faq", body);
      closeModal();
      toastMessage("success", res.data.message);
      setButtonLoader(false);
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleBotSearch = useMemo(
    () =>
      debounce(async (value: string, lang: string) => {
        try {
          if (value) {
            setSearchLoader(lang);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=faq&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot?.id,
                name: bot?.name,
              };
            });
            if (lang === "ta") {
              setTaBots(newBots);
            } else if (lang === "ms") {
              setMsBots(newBots);
            } else if (lang === "zh") {
              setZhBots(newBots);
            } else if (lang === "tl") {
              setTlBots(newBots);
            } else {
              setBots(newBots);
            }
            setSearchLoader(null);
          }
        } catch (err) {
          setSearchLoader(null);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  useEffect(() => {
    const fetchSetupDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get("/app/settings/faq");
        const response = res.data.data;
        let data = {
          enBot: null,
          enId: "",
          taBot: null,
          taId: "",
          msBot: null,
          msId: "",
          zhBot: null,
          zhId: "",
          tlBot: null,
          tlId: "",
        };
        response.forEach((item: any) => {
          if (item?.lang === "en" && item?.botId) {
            data.enBot = item?.bot;
            data.enId = item?.id;
          }
          if (item?.lang === "ta" && item?.botId) {
            data.taBot = item?.bot;
            data.taId = item?.id;
          }
          if (item?.lang === "ms" && item?.botId) {
            data.msBot = item?.bot;
            data.msId = item?.id;
          }
          if (item?.lang === "zh" && item?.botId) {
            data.zhBot = item?.bot;
            data.zhId = item?.id;
          }
          if (item?.lang === "tl" && item?.botId) {
            data.tlBot = item?.bot;
            data.tlId = item?.id;
          }
        });
        setSetupDetails(data);
        if (data?.enBot) {
          setBots([data.enBot]);
        }
        if (data?.taBot) {
          setTaBots([data?.taBot]);
        }
        if (data?.msBot) {
          setMsBots([data?.msBot]);
        }
        if (data?.zhBot) {
          setZhBots([data?.zhBot]);
        }
        if (data?.tlBot) {
          setTlBots([data?.tlBot]);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchSetupDetails();
  }, []);

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"FAQ Start Setup Modal"}
          onCloseClick={closeModal}
        />
        {!loading ? (
          <Formik
            initialValues={{
              enBot: setupDetails?.enBot || null,
              enId: setupDetails?.enId || "",
              taBot: setupDetails?.taBot || null,
              taId: setupDetails?.taId || "",
              msBot: setupDetails?.msBot || null,
              msId: setupDetails?.msId || "",
              zhBot: setupDetails?.zhBot || null,
              zhId: setupDetails?.zhId || "",
              tlBot: setupDetails?.tlBot || null,
              tlId: setupDetails?.tlId || "",
            }}
            onSubmit={(values) => {
              submitHandler(values);
            }}
          >
            {({ setFieldValue, values }: any) => (
              <Form>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Select Bot</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleBotSearch(value, "en");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("enBot", value);
                    }}
                    options={bots}
                    value={values?.enBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={searchLoader === "en"}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    disableClearable
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Select Bot (Tamil)</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleBotSearch(value, "ta");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("taBot", value);
                    }}
                    options={taBots}
                    value={values?.taBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={searchLoader === "ta"}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Select Bot (Malay)</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleBotSearch(value, "ms");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("msBot", value);
                    }}
                    options={msBots}
                    value={values?.msBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={searchLoader === "ms"}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Select Bot (Chinese)</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleBotSearch(value, "zh");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("zhBot", value);
                    }}
                    options={zhBots}
                    value={values?.zhBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={searchLoader === "zh"}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                  <FormLabel sx={LabelStyle}>Select Bot (Tagalog)</FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") handleBotSearch(value, "tl");
                    }}
                    onChange={(_1: any, value: any) => {
                      setFieldValue("tlBot", value);
                    }}
                    options={tlBots}
                    value={values?.tlBot || null}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    loading={searchLoader === "tl"}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={true}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Bots by typing..."
                      />
                    )}
                  />
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1.5,
                    mt: 3,
                  }}
                >
                  {!buttonLoader ? (
                    <>
                      <Button variant="outlined" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                    </>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default FaqSetupModal;
