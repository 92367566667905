import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../Redux/hooks";
import { setChatbotDefaults } from "../../Redux/reducers/chatbotSlice";

const ChatbotUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "general";
    const search = searchParams.get("search") || "";
    const filterId = searchParams.get("filter") || "";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    const order = searchParams.get("order") || "";
    const sort = searchParams.get("sort") || "";
    const langFilter = searchParams.get("lang")?.split(",") || [];
    dispatch(
      setChatbotDefaults({
        filterId,
        search,
        type,
        page,
        order,
        sort,
        langFilter,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(ChatbotUrlLoader);
