import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  changeStepProperty,
  setCbBuilderLoader,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";

const ReminderConfig = ({ isReminder, reminderBotId, editable }: any) => {
  const [searchLoader, setSearchLoader] = useState(false);
  const [bots, setBots] = useState<any[]>([]);
  const [selectedBot, setSelectedBot] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.chatbotBuilder);

  const changeValue = (value: any, type: "reminderBotId" | "isReminder") => {
    dispatch(changeStepProperty({ type, value }));
  };

  useEffect(() => {
    const fetchUnits = async (id: string) => {
      try {
        dispatch(setCbBuilderLoader(true));
        const res: AxiosResponse = await http.get(`/bots/${id}/steps`);
        setBots([{ id: res.data.data?.id, name: res.data.data?.name }]);
        setSelectedBot({ id: res.data.data?.id, name: res.data.data?.name });
        dispatch(setCbBuilderLoader(false));
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (reminderBotId) {
      fetchUnits(reminderBotId);
    }
  }, [reminderBotId, dispatch, setBots]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=general&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot.id,
                name: bot.name,
              };
            });
            setBots(newBots);
            setSearchLoader(false);
          } else {
            setBots([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isReminder || false}
            onChange={(e) => changeValue(e.target.checked, "isReminder")}
          />
        }
        label="Default Response for the bot"
        sx={{ mb: 3 }}
      />
      {isReminder && (
        <>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Call To Action Bot (Leave empty for reminding the current bot)
          </Typography>
          <Autocomplete
            freeSolo
            filterOptions={(x) => x}
            disabled={!editable}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") {
                handleSearch(value);
              }
            }}
            onChange={(_1: any, value: any) => {
              changeValue(value?.id, "reminderBotId");
              setSelectedBot(value);
            }}
            options={bots}
            getOptionLabel={(option) => option?.name}
            disableClearable
            value={selectedBot || null}
            loading={searchLoader}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            clearOnBlur={true}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Chat bot by typing..."
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default ReminderConfig;
