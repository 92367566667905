import { Box, Typography } from "@mui/material";
import { borderedContainer, footContainerWrapper } from "./style";
import { SleepIcon } from "../../Common/assets/FoodJournalIcons";

const Sleep = ({ data }: any) => {
  const hours = Math.floor(data?.duration / 60);
  const minutes = data?.duration % 60;
  return (
    <Box sx={footContainerWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Sleep
      </Typography>
      <Box sx={borderedContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.75,
            p: 1.75,
          }}
        >
          <SleepIcon />
          {data ? (
            <Typography variant="h6" color="#212121" fontWeight="medium">
              {hours > 0 && `${hours}h`}
              {minutes > 0 && ` ${minutes}m`}
              <Typography
                component="span"
                variant="body1"
                fontWeight="regular"
                color="#676970"
              >
                {" "}
                Sleep Duration
              </Typography>
            </Typography>
          ) : (
            <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
              Data Unavailable
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sleep;
