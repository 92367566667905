import { styled, Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#262626",
  },
  "& 	.MuiTabs-flexContainer": {
    height: "60px",
  },
});

export const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
  color: "#6B7280",
  "&.Mui-selected": {
    color: "#262626",
  },
}));

export const MainTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 2.5 }}>{children}</Box>}
    </div>
  );
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
