import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import BrokenListHeader from "./BrokenListHeader";
import LinkList from "./LinkList";

const BrokenLink = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("type") || "option");
  const [toggleLoader, setToggleLoader] = useState(false);

  const handleChange = (_: any, newValue: string) => {
    setValue(newValue);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    // params.set("page", page.toString());
    if (value) {
      params.set("type", value);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [value, setSearchParams]);

  return (
    <>
      <BrokenListHeader refreshPage={refreshPage} />
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label="Option Link" value="option" {...a11yProps(0)} />
          <StyledTab label="Bot Link" value="bot" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={value} index={"option"}>
          <LinkList type={value} toggleLoader={toggleLoader} />
        </MainTabPanel>
        <MainTabPanel value={value} index={"bot"}>
          <LinkList type={value} toggleLoader={toggleLoader} />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default BrokenLink;
