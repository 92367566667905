import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box className="dot-flashing-wrapper">
      <Box className="dot-flashing" />
    </Box>
  );
};
export default Loader;
