import { useEffect, useState } from "react";
import http from "../../../utils/http";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  LinkProps,
  IconButton,
} from "@mui/material";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import {
  JournalCard,
  JournalWrapper,
  headerContainer,
  profileCard,
} from "./style";
import { langMap } from "../../../utils/lang";
import { Edit } from "@mui/icons-material";
import GoalEditModal from "./GoalEditModal";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const ParticipantDetails = () => {
  const [loading, setLoading] = useState(true);
  const [participantData, setParticipantData] = useState<any>({});
  const [steps, setSteps] = useState("3000");
  const [sleep, setSleep] = useState("540");
  const [type, setType] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParticipantDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/participants/${id}/profile`
        );
        const data = res.data?.data;
        let str = "-";
        if (data?.riskCategory) {
          str = data?.riskCategory?.split("_")?.[1]?.toLowerCase();
          if (str) {
            str === "active" ? (str = str + " ulcer") : (str = str + " risk");
          }
        }

        const details = {
          studyNumber: data?.user?.code || "Study Number",
          lang: data?.user?.lang || "",
          nickname: data?.nickname || "",
          riskCategory: str,
          gender: data?.gender || "-",
          ethnicity: data?.ethnicity || "-",
          isSelf:
            data?.isSelf === null
              ? "-"
              : data?.isSelf
              ? "Patient"
              : "Caregiver",
        };

        const response: AxiosResponse = await http.get(
          `/activity/goal-settings/${id}?type=step`
        );
        setSteps(response?.data?.data?.goal || "3000");

        const sleepResponse: AxiosResponse = await http.get(
          `/activity/goal-settings/${id}?type=sleep`
        );
        setSleep(sleepResponse?.data?.data?.goal || "540");

        setParticipantData(details);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchParticipantDetails();
  }, [id, toggleLoader]);

  const viewJournal = () => {
    navigate(
      `/app/participants/${id}/journal?user=${
        participantData?.studyNumber || "user"
      }`
    );
  };

  const viewReflectionJournal = () => {
    navigate(
      `/app/participants/${id}/self-reflection?user=${
        participantData?.studyNumber || "user"
      }`
    );
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const openModal = (visible: boolean, typeValue: string) => {
    setShowModal(visible);
    setType(typeValue);
  };

  const closeModal = () => {
    setShowModal(false);
    setType("");
  };

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={JournalWrapper}>
        <Box sx={JournalCard}>
          <Box sx={{ ...headerContainer, mb: 5 }}>
            <Breadcrumbs>
              <LinkRouter
                underline="none"
                color="#111928"
                variant="h6"
                fontWeight="medium"
                to={"/app/participants"}
              >
                My Patients
              </LinkRouter>
              <Link
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#EB8900"
              >
                {participantData?.studyNumber}
              </Link>
            </Breadcrumbs>
            <Box sx={{ display: "flex", gap: 2, mr: 2, alignItems: "center" }}>
              <Button variant="outlined" onClick={viewJournal}>
                View Journal
              </Button>
              <Button variant="outlined" onClick={viewReflectionJournal}>
                View Reflection Journal
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {!loading && (
              <Box sx={profileCard}>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Name
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.nickname}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Study Number
                </Typography>
                <Typography variant="subtitle2" mb={3}>
                  {participantData?.studyNumber}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Gender
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.gender}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Language
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.lang ? langMap[participantData?.lang] : "-"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Ethnicity
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.ethnicity}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Participant Type
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.isSelf}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                >
                  Risk Category
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {participantData?.riskCategory}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                  sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                >
                  Steps Target
                  <IconButton
                    sx={{ p: 0.5 }}
                    onClick={() => openModal(true, "Steps")}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {steps}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  mb={1}
                  color="#444444"
                  sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                >
                  Sleep Target (in mins)
                  <IconButton
                    sx={{ p: 0.5 }}
                    onClick={() => openModal(true, "Sleep")}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography
                  variant="subtitle2"
                  textTransform={"capitalize"}
                  mb={3}
                >
                  {sleep}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {showModal && (
        <GoalEditModal
          data={type === "Steps" ? steps : sleep}
          type={type}
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          id={id}
        />
      )}
    </>
  );
};

export default ParticipantDetails;
