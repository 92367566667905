import { MoreVertRounded } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import {
  CardAvatar,
  CardMainText,
  EducationDetails,
  ModuleCardStyle,
} from "../../cms.style";

const EducationModuleCard = ({
  data,
  openMenu,
  index,
  handleNavigate,
  type,
}: any) => {
  return (
    <>
      <Box sx={ModuleCardStyle} onClick={() => handleNavigate(data?.id)}>
        <Avatar variant="rounded" src={data?.imageUrl} sx={CardAvatar}>
          <PhotoSizeSelectActualOutlinedIcon
            sx={{ width: "45px", height: "45px" }}
          />
        </Avatar>
        <Box sx={EducationDetails}>
          <Typography fontSize={"24px"} fontWeight="medium" sx={CardMainText}>
            {data?.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="#4b5563"
            sx={{ textTransform: "capitalize" }}
          >
            {type} {index + 1}
          </Typography>
          {type === "page" && data?.areQuestionsMandatory && (
            <Typography variant="subtitle1" color="#4b5563" mt={1.25}>
              Questions Mandatory
            </Typography>
          )}
          {type === "unit" && data?.isMandatory && (
            <Typography variant="subtitle1" color="#4b5563" mt={1.25}>
              Mandatory
            </Typography>
          )}
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton onClick={(e) => openMenu(e, data)}>
            <MoreVertRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default EducationModuleCard;
