import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";

import {
  changeResponseMsgNotification,
  changeResponseMsgProperty,
  setCbBuilderLoader,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { NoDataContainer } from "../../../Common/styles/table";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";
import { Search } from "@mui/icons-material";
import { InputWrapper } from "../../../Common/styles/form";
import { NumberMap } from "../../../../utils/chatbotBuilder";

type Props = {
  message: any;
  index: number;
  msgIndex: number;
  isFaq?: boolean;
  editable: boolean;
};

export const EducationAddon: React.FC<Props> = ({
  index,
  message,
  msgIndex,
  editable,
}) => {
  const { educationLessonId, educationLessonUnitId, educationLessonPageId } =
    message;
  const { lang } = useAppSelector((state) => state.chatbotBuilder);
  const [lessons, setLessons] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchUnits = async (id: string) => {
      try {
        dispatch(setCbBuilderLoader(true));
        const res: AxiosResponse = await http.get(`/lms/lessons/${id}`);
        const lesson = res.data.data;
        lesson.units.sort((a: any, b: any) => {
          return a.position - b.position;
        });
        setLessons([{ id: lesson?.id, label: lesson?.name }]);
        setSelectedLesson({ id: lesson?.id, label: lesson?.name });
        setUnits(lesson?.units);
        dispatch(setCbBuilderLoader(false));
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (educationLessonId) {
      fetchUnits(educationLessonId);
    }
  }, [educationLessonId, setUnits, setLessons, dispatch]);

  useEffect(() => {
    if (educationLessonUnitId && units.length > 0) {
      const selectedUnit = units.find(
        (uni) => uni.id === educationLessonUnitId
      );
      if (selectedUnit) {
        setPages(selectedUnit?.pages);
      }
    }
  }, [educationLessonUnitId, units, setPages]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active&lang=${lang}`
            );
            const newLessons = res.data?.data?.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson?.id,
                  label: lesson?.name,
                };
              }
            );
            setLessons(newLessons);
            setSearchLoader(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  const handleChange = (
    value: any,
    type:
      | "educationLessonUnitId"
      | "educationLessonPageId"
      | "educationLessonId"
  ) => {
    dispatch(
      changeResponseMsgProperty({
        value,
        index,
        msgIndex,
        type,
      })
    );
    if (type === "educationLessonId") setPages([]);
  };

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "educationLessonUnitId" | "educationLessonPageId"
  ) => {
    if (event.target.checked) {
      dispatch(
        changeResponseMsgProperty({
          value: "all",
          index,
          msgIndex,
          type,
        })
      );

      if (type === "educationLessonUnitId") {
        setPages([]);
      }
    } else {
      dispatch(
        changeResponseMsgProperty({
          value: null,
          index,
          msgIndex,
          type,
        })
      );
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Education Module
          </Typography>
          <Autocomplete
            freeSolo
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") {
                handleSearch(value);
              }
            }}
            onChange={(_1: any, value: any) => {
              handleChange(value?.id, "educationLessonId");
            }}
            disabled={!editable}
            isOptionEqualToValue={(option, value) => option?.label === value}
            options={lessons}
            clearOnBlur={true}
            disableClearable
            value={selectedLesson?.label || null}
            loading={searchLoader}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search module by typing..."
              />
            )}
          />
          <FormControlLabel
            disabled={!educationLessonId}
            control={
              <Checkbox
                checked={educationLessonUnitId === "all"}
                onChange={(e) => handleCheckbox(e, "educationLessonUnitId")}
              />
            }
            label="Entire Module"
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Unit
          </Typography>
          <Select
            value={
              educationLessonUnitId && units.length > 0
                ? educationLessonUnitId
                : ""
            }
            disabled={!editable || educationLessonUnitId === "all"}
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "educationLessonUnitId")
            }
          >
            {educationLessonUnitId === "all" ? (
              <MenuItem disabled value="all">
                all
              </MenuItem>
            ) : units.length > 0 ? (
              units?.map((unit: any) => {
                return (
                  <MenuItem value={unit?.id} key={unit?.id}>
                    {unit?.title}
                  </MenuItem>
                );
              })
            ) : (
              <NoDataContainer sx={{ p: 2.5 }}>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Select>
          <FormControlLabel
            disabled={educationLessonUnitId === "all"}
            control={
              <Checkbox
                checked={educationLessonPageId === "all"}
                onChange={(e) => handleCheckbox(e, "educationLessonPageId")}
              />
            }
            label="Entire Unit"
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Page
          </Typography>
          <Select
            disabled={
              !editable ||
              educationLessonUnitId === "all" ||
              educationLessonPageId === "all"
            }
            value={
              educationLessonPageId && pages.length > 0
                ? educationLessonPageId
                : ""
            }
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "educationLessonPageId")
            }
          >
            {educationLessonPageId === "all" ? (
              <MenuItem value="all" disabled>
                all
              </MenuItem>
            ) : pages.length > 0 ? (
              pages.map((page: any) => {
                return (
                  <MenuItem value={page?.id} key={page?.id}>
                    {page?.title}
                  </MenuItem>
                );
              })
            ) : (
              <NoDataContainer sx={{ p: 2.5 }}>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Select>
        </Box>
      </Box>
    </>
  );
};

export const FAQEducationAddon: React.FC<Props> = ({
  index,
  message,
  msgIndex,
  editable,
}) => {
  const {
    careEducationLessonId,
    careEducationLessonUnitId,
    careEducationLessonPageId,
  } = message;
  const [lessons, setLessons] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.chatbotBuilder);

  useEffect(() => {
    const fetchUnits = async (id: string) => {
      try {
        dispatch(setCbBuilderLoader(true));
        const res: AxiosResponse = await http.get(`/lms/lessons/${id}`);
        const lesson = res.data.data;
        lesson.units.sort((a: any, b: any) => {
          return a.position - b.position;
        });
        setLessons([{ id: lesson?.id, label: lesson?.name }]);
        setSelectedLesson({ id: lesson?.id, label: lesson?.name });
        setUnits(lesson?.units);
        dispatch(setCbBuilderLoader(false));
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (careEducationLessonId) {
      fetchUnits(careEducationLessonId);
    }
  }, [careEducationLessonId, setUnits, setLessons, dispatch]);

  useEffect(() => {
    if (careEducationLessonUnitId && units.length > 0) {
      const selectedUnit = units.find(
        (uni) => uni.id === careEducationLessonUnitId
      );
      if (selectedUnit) {
        setPages(selectedUnit?.pages);
      }
    }
  }, [careEducationLessonUnitId, units, setPages]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/lms/lessons?page=1&size=20&search=${value}&status=active&lang=${lang}`
            );
            const newLessons = res.data?.data?.educationLessons.map(
              (lesson: any) => {
                return {
                  id: lesson?.id,
                  label: lesson?.name,
                };
              }
            );
            setLessons(newLessons);
            setSearchLoader(false);
          } else {
            setLessons([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [lang]
  );

  const handleChange = (
    value: any,
    type:
      | "careEducationLessonUnitId"
      | "careEducationLessonPageId"
      | "careEducationLessonId"
  ) => {
    dispatch(
      changeResponseMsgProperty({
        value,
        index,
        msgIndex,
        type,
      })
    );
    if (type === "careEducationLessonId") setPages([]);
  };

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "careEducationLessonUnitId" | "careEducationLessonPageId"
  ) => {
    if (event.target.checked) {
      dispatch(
        changeResponseMsgProperty({
          value: "all",
          index,
          msgIndex,
          type,
        })
      );

      if (type === "careEducationLessonUnitId") {
        setPages([]);
      }
    } else {
      dispatch(
        changeResponseMsgProperty({
          value: null,
          index,
          msgIndex,
          type,
        })
      );
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
      <Box sx={{ flex: 1, minWidth: "1px" }}>
        <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
          Education Module
        </Typography>
        <Autocomplete
          freeSolo
          filterOptions={(x) => x}
          disabled={!editable}
          onInputChange={(_1: any, value: any, reason: string) => {
            if (reason === "input") {
              handleSearch(value);
            }
          }}
          onChange={(_1: any, value: any) => {
            handleChange(value?.id, "careEducationLessonId");
          }}
          isOptionEqualToValue={(option, value) => option?.label === value}
          options={lessons}
          clearOnBlur={true}
          disableClearable
          value={selectedLesson?.label || null}
          loading={searchLoader}
          loadingText={<CircularProgress size={20} />}
          noOptionsText="No Results"
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="Search module by typing..."
            />
          )}
        />
        <FormControlLabel
          disabled={!careEducationLessonId}
          control={
            <Checkbox
              checked={careEducationLessonUnitId === "all"}
              onChange={(e) => handleCheckbox(e, "careEducationLessonUnitId")}
            />
          }
          label="Entire Module"
        />
      </Box>
      <Box sx={{ flex: 1, minWidth: "1px" }}>
        <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
          Unit
        </Typography>
        <Select
          value={
            careEducationLessonUnitId && units.length > 0
              ? careEducationLessonUnitId
              : ""
          }
          disabled={!editable || careEducationLessonUnitId === "all"}
          fullWidth
          onChange={(e) =>
            handleChange(e.target.value, "careEducationLessonUnitId")
          }
        >
          {careEducationLessonUnitId === "all" ? (
            <MenuItem disabled value="all">
              all
            </MenuItem>
          ) : units.length > 0 ? (
            units?.map((unit: any) => {
              return (
                <MenuItem value={unit?.id} key={unit?.id}>
                  {unit?.title}
                </MenuItem>
              );
            })
          ) : (
            <NoDataContainer sx={{ p: 2.5 }}>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
        </Select>
        <FormControlLabel
          disabled={careEducationLessonUnitId === "all"}
          control={
            <Checkbox
              checked={careEducationLessonPageId === "all"}
              onChange={(e) => handleCheckbox(e, "careEducationLessonPageId")}
            />
          }
          label="Entire Unit"
        />
      </Box>
      <Box sx={{ flex: 1, minWidth: "1px" }}>
        <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
          Page
        </Typography>
        <Select
          disabled={
            !editable ||
            careEducationLessonUnitId === "all" ||
            careEducationLessonPageId === "all"
          }
          value={
            careEducationLessonPageId && pages.length > 0
              ? careEducationLessonPageId
              : ""
          }
          fullWidth
          onChange={(e) =>
            handleChange(e.target.value, "careEducationLessonPageId")
          }
        >
          {careEducationLessonPageId === "all" ? (
            <MenuItem value="all" disabled>
              all
            </MenuItem>
          ) : pages.length > 0 ? (
            pages.map((page: any) => {
              return (
                <MenuItem value={page?.id} key={page?.id}>
                  {page?.title}
                </MenuItem>
              );
            })
          ) : (
            <NoDataContainer sx={{ p: 2.5 }}>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
        </Select>
      </Box>
    </Box>
  );
};

export const BotAddon: React.FC<Props> = ({
  index,
  message,
  isFaq,
  msgIndex,
  editable,
}) => {
  const { botId, botStepId } = message;
  const [searchLoader, setSearchLoader] = useState(false);
  const [bots, setBots] = useState<any[]>([]);
  const [steps, setSteps] = useState<any[]>([]);
  const [selectedBot, setSelectedBot] = useState<any>(null);

  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.chatbotBuilder);

  useEffect(() => {
    const fetchUnits = async (id: string) => {
      try {
        dispatch(setCbBuilderLoader(true));
        const res: AxiosResponse = await http.get(`/bots/${id}/steps`);
        const newSteps = res.data.data.botSteps;
        newSteps
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((step: any) => {
            return {
              id: step.id,
              name: step.name,
            };
          });
        setBots([{ id: res.data.data?.id, label: res.data.data?.name }]);
        setSelectedBot({ id: res.data.data?.id, label: res.data.data?.name });
        setSteps(newSteps);
        dispatch(setCbBuilderLoader(false));
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (botId) {
      fetchUnits(botId);
    }
  }, [botId, setSteps, setBots, dispatch]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/bots?page=1&size=15&status=active&search=${value}&type=${
                isFaq ? "faq" : "general"
              }&lang=${lang}`
            );
            const newBots = res.data.data.bots.map((bot: any) => {
              return {
                id: bot.id,
                label: bot.name,
              };
            });
            setBots(newBots || []);
            setSearchLoader(false);
          } else {
            setBots([]);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [isFaq, lang]
  );

  const handleChange = (value: any, type: "botId" | "botStepId") => {
    dispatch(
      changeResponseMsgProperty({
        value,
        index,
        msgIndex,
        type,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 3, mb: 2 }}>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Chatbot
          </Typography>
          <Autocomplete
            freeSolo
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") {
                handleSearch(value);
              }
            }}
            disabled={!editable}
            onChange={(_1: any, value: any) => {
              handleChange(value?.id, "botId");
            }}
            isOptionEqualToValue={(option, value) => {
              return option?.label === value;
            }}
            options={bots}
            clearOnBlur={true}
            disableClearable
            value={selectedBot?.label || null}
            loading={searchLoader}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Chatbot by typing..."
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1, minWidth: "1px" }}>
          <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
            Step
          </Typography>
          <Select
            value={botStepId && steps?.length > 0 ? botStepId : ""}
            fullWidth
            disabled={!editable}
            onChange={(e) => {
              handleChange(e.target.value, "botStepId");
            }}
          >
            {steps.length > 0 ? (
              steps.map((pag: any) => {
                return (
                  <MenuItem value={pag.id} key={pag.id}>
                    {pag.name}
                  </MenuItem>
                );
              })
            ) : (
              <NoDataContainer sx={{ p: 2.5 }}>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Select>
        </Box>
      </Box>
    </>
  );
};

export const NotificationSetup: React.FC<Props> = ({
  index,
  msgIndex,
  editable,
  message,
}) => {
  const dispatch = useAppDispatch();

  const { isNotificationEnabled, notification } = message;

  const changeResponseMsgNotif = (
    value: string | number,
    type: "title" | "body" | "triggerIn"
  ) => {
    dispatch(changeResponseMsgNotification({ type, index, msgIndex, value }));
  };

  const handleChange = (value: any, type: "isNotificationEnabled") => {
    dispatch(
      changeResponseMsgProperty({
        value,
        index,
        msgIndex,
        type,
      })
    );
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isNotificationEnabled}
            onChange={(e) => {
              handleChange(e.target.checked, "isNotificationEnabled");
            }}
          />
        }
        label="Send Notification"
        sx={{ mb: 2 }}
      />
      {isNotificationEnabled && (
        <>
          <Box
            sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}
          >
            <FormControl sx={InputWrapper}>
              <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                Notification Title
              </Typography>
              <TextField
                fullWidth
                placeholder="Title"
                value={notification?.title}
                onChange={(e) =>
                  changeResponseMsgNotif(e.target.value, "title")
                }
              />
            </FormControl>
            <FormControl sx={InputWrapper}>
              <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                Trigger in days
              </Typography>
              <Select
                value={notification?.triggerIn}
                onChange={(e) => {
                  changeResponseMsgNotif(parseInt(e.target.value), "triggerIn");
                }}
                fullWidth
                id="triggerIn"
              >
                {NumberMap.map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{ display: "flex", mb: 2, alignItems: "center", gap: "20px" }}
          >
            <FormControl sx={InputWrapper}>
              <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                Notification Body
              </Typography>
              <TextField
                fullWidth
                placeholder="Type your text here..."
                value={notification?.body}
                onChange={(e) => changeResponseMsgNotif(e.target.value, "body")}
              />
            </FormControl>
          </Box>
        </>
      )}
    </>
  );
};
