import { pageSize } from "../../Components/Common/styles/table";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setCloudLoader,
  setHighlightsCount,
  setHighlightsData,
  setTagsData,
} from "../reducers/cloudSlice";
import { AppThunk } from "../store";

export const fetchHighlightsData =
  (page: number, searchText: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCloudLoader(true));
      let url = `/highlight?size=${pageSize}&page=${page + 1}`;
      if (searchText) {
        url += `&q=${searchText}`;
      }

      const res = await http.get(url);
      dispatch(setHighlightsData(res.data?.data?.rows));
      dispatch(setHighlightsCount(res.data?.data?.count));
      dispatch(setCloudLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCloudLoader(false));
    }
  };

export const fetchTagsData =
  (searchText: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setCloudLoader(true));
      let url = "/tag";
      if (searchText) {
        url += `?q=${searchText}`;
      }
      const res = await http.get(url);
      dispatch(setTagsData(res.data?.data));
      dispatch(setCloudLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCloudLoader(false));
    }
  };
