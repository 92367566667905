import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    neutral: Palette["primary"];
    neutralLight: Palette["primary"];
  }

  interface Palette {
    neutral?: Palette["primary"];
    neutralLight?: Palette["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    neutralLight: true;
  }
}

export const theme = createTheme({
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: "Gilroy, sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    body1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "150%",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "150%",
    },
    h2: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "150%",
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "150%",
    },
  },
  palette: {
    primary: {
      main: "#164642",
    },
    neutral: {
      main: "#818194",
      contrastText: "#FFFFFF",
      dark: "#6a6a7a",
      light: "#9494a8",
    },
    neutralLight: {
      main: "#f5f5f5",
      contrastText: "#1F2A37",
      dark: "#e0dede",
      light: "#f5f5f5",
    },
    text: {
      primary: "#111928",
    },
    divider: "#e5e7eb",
  },
});
