import { Backdrop, Box, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setActiveStepIndex,
  setCbBuilderLoader,
  setCbBuilderState,
  setEditable,
  setFaq,
  setPublished,
} from "../../../Redux/reducers/chatbotBuilderSlice";
import { stepViewFormat } from "../../../utils/chatbotBuilder";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import { BuilderWrapper } from "./chatbotBuilder.style";

import Header from "./Header";
import StepBuilder from "./StepBuilder/StepBuilder";
import StepEditor from "./StepEditor/StepEditor";

const ChatbotBuilder = () => {
  const [toggle, setToggle] = useState(false);

  const dispatch = useAppDispatch();
  const { loading, steps, activeStepIndex } = useAppSelector(
    (state) => state.chatbotBuilder
  );

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setCbBuilderLoader(true));
        const res: AxiosResponse = await http.get(`/bots/${id}/steps`);
        if (
          res.data.data?.status === "draft" ||
          res.data.data?.type === "faq"
        ) {
          dispatch(setEditable(true));
        } else {
          dispatch(setEditable(false));
        }
        dispatch(setFaq(res.data?.data?.type === "faq"));
        dispatch(setPublished(res.data.data?.status === "active"));
        const steps = await stepViewFormat(res.data?.data?.botSteps);
        dispatch(
          setCbBuilderState({
            loading: false,
            steps: steps,
            lang: res.data.data?.lang || "en",
          })
        );
      } catch (err) {
        errorToastMessage(err as Error);
        navigate(-1);
      }
    };
    fetchDetails();
    return () => {
      dispatch(
        setCbBuilderState({
          loading: false,
          steps: [],
          lang: "en",
        })
      );
    };
  }, [dispatch, navigate, id, toggle]);

  useEffect(() => {
    if (steps.length > 0) {
      if (!steps[activeStepIndex]) {
        dispatch(setActiveStepIndex({ index: 0 }));
      }
    }
  }, [steps, activeStepIndex, dispatch]);

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  return (
    <Box sx={{ height: "100%" }}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Header refreshPage={refreshPage} steps={steps} />
      <Box sx={BuilderWrapper}>
        <StepBuilder />
        <StepEditor />
      </Box>
    </Box>
  );
};

const ChatbotBuilderWrapper = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ChatbotBuilder />
    </DndProvider>
  );
};

export default ChatbotBuilderWrapper;
