import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import * as yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { DualInput } from "./settings.style";
import { onboardingSetting } from "./Settings";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: onboardingSetting[];
  refreshPage: () => void;
};

const EditOnboardingSettingsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState<onboardingSetting[]>(
    JSON.parse(JSON.stringify(data))
  );

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      for (let i = 0; i < formData.length; i++) {
        const value = formData[i].value;
        let schema = yup.string().url();
        if (formData[i].type === "link") {
          const valid = await schema.validate(value);
          if (!value || !valid) {
            toastMessage(
              "warning",
              formData[i].label + " should be a valid link"
            );
            setButtonLoader(false);
            return;
          }
        } else {
          if (!value || !value.trim()) {
            toastMessage("warning", formData[i].label + " cannot be empty");
            setButtonLoader(false);
            return;
          }
        }
      }
      const res: AxiosResponse = await http.put("/app/settings/other", {
        values: formData.map((item) => {
          return {
            id: item.key,
            value: item.value,
          };
        }),
      });
      toastMessage("success", res?.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string
  ) => {
    setFormData((formData) => {
      return formData.map((item) => {
        if (item.key === key) {
          const newItem = {
            ...item,
            value: event.target.value,
          };
          return newItem;
        } else {
          return item;
        }
      });
    });
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={"Edit Onboarding Settings"}
          onCloseClick={closeModal}
        />
        {formData.map((item) => {
          return (
            <FormControl sx={{ ...InputWrapper, mb: 2 }} key={item.key}>
              <FormLabel sx={LabelStyle}>{item.label}</FormLabel>
              <Box sx={DualInput}>
                <TextField
                  fullWidth
                  placeholder="Value"
                  value={item.value}
                  onChange={(e) => changeHandler(e, item.key)}
                />
              </Box>
            </FormControl>
          );
        })}

        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditOnboardingSettingsModal;
