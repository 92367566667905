import { useCallback } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Add, Clear, Delete } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

import { CbBuilderStepCategories } from "../../../../utils/chatbotBuilder";
import {
  MiniUploadWrapper,
  NoAnswerOptionStyle,
} from "../chatbotBuilder.style";
import { ImageUploadIcon } from "../Icons";
import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addAnswerOption,
  deleteAnswerOption,
  editAnswerOption,
  setCbBuilderLoader,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";

type UploadProps = {
  index: number;
  image: any;
  editable: boolean;
};

const UploadItem: React.FC<UploadProps> = ({ index, image, editable }) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          dispatch(setCbBuilderLoader(true));
          const url = await uploadFile(file, "questionnaire_image");
          dispatch(editAnswerOption({ value: url, index, type: "imageUrl" }));
          dispatch(setCbBuilderLoader(false));
        }
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [index, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: editable ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: !editable,
  });

  const clearImage = () => {
    dispatch(editAnswerOption({ value: null, index, type: "imageUrl" }));
  };

  return (
    <>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...MiniUploadWrapper }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {image ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <ImageUploadIcon />
          )}
        </Box>
      </Box>
      {image && (
        <IconButton
          onClick={clearImage}
          title="Clear image"
          color="error"
          disabled={!editable}
        >
          <Clear />
        </IconButton>
      )}
    </>
  );
};

type Props = {
  type: string;
  options: any;
  editable: boolean;
};

const AnswerOptions = ({ type, options, editable }: Props) => {
  const dispatch = useAppDispatch();

  const changeValue = (value: string, index: number) => {
    dispatch(editAnswerOption({ value, index, type: "text" }));
  };

  const addOption = () => {
    dispatch(addAnswerOption());
  };

  const deleteOption = (index: number) => {
    dispatch(deleteAnswerOption({ index }));
  };

  return (
    <>
      {CbBuilderStepCategories?.choice.includes(type) ? (
        <>
          {options?.map((option: any, index: number) => {
            const disabled = type === "yes_no";
            return (
              <Box key={option?.id} sx={{ mb: 2, maxWidth: "70%" }}>
                <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                  Answer Option
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <Box sx={{ minWidth: "1px", flex: 1 }}>
                    <TextField
                      fullWidth
                      value={option?.text || ""}
                      placeholder="Answer option"
                      disabled={disabled}
                      onChange={(e) => changeValue(e.target.value, index)}
                    />
                  </Box>
                  {!disabled && (
                    <>
                      <Box
                        sx={{
                          minWidth: "1px",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <UploadItem
                          index={index}
                          image={option?.imageUrl}
                          editable={editable}
                        />
                      </Box>

                      <IconButton
                        disabled={options.length < 2 || !editable}
                        onClick={() => deleteOption(index)}
                        sx={{
                          minWidth: "1px",
                        }}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </>
      ) : (
        <Box sx={NoAnswerOptionStyle}>
          <Typography variant="subtitle1">
            There are no Answer options for this step type
          </Typography>
        </Box>
      )}
      {type === "multiple_choice" && editable && (
        <Button variant="outlined" startIcon={<Add />} onClick={addOption}>
          Add New
        </Button>
      )}
    </>
  );
};

export default AnswerOptions;
