import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  LinkProps,
  Link,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import { headerContainer, JournalCard, JournalWrapper } from "./style";
import { ExpandMore } from "@mui/icons-material";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReflectionJournal = () => {
  const [loading, setLoading] = useState(false);
  const [journalData, setJournalData] = useState<any>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  const navigate = useNavigate();
  const user = useQuery().get("user") || "";
  const { id } = useParams();

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/lms/lessons/journals?userId=${id}`
        );
        const newData = res.data.data
          .map((lesson: any) => {
            const units = lesson.units
              .map((unit: any) => {
                if (unit) {
                  return {
                    id: unit?.id,
                    name: unit?.title,
                    position: unit?.position,
                    journal: unit?.journal,
                    sliderText: unit?.sliderText,
                  };
                } else {
                  return null;
                }
              })
              .filter((unit: any) => unit);
            units.sort(function (a: any, b: any) {
              return a.position - b.position;
            });
            return {
              id: lesson?.id,
              name: lesson?.name,
              units: units,
            };
          })
          .filter((lesson: any) => lesson?.units?.length > 0);
        setJournalData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [id, setJournalData]);

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={JournalWrapper}>
        <Box sx={JournalCard}>
          <Box sx={{ ...headerContainer, mb: 5 }}>
            <Breadcrumbs>
              <LinkRouter
                underline="none"
                color="#111928"
                variant="h6"
                fontWeight="medium"
                to={"/app/participants"}
              >
                My Patients
              </LinkRouter>
              <LinkRouter
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#111928"
                to={`/app/participants/${id}`}
              >
                {user || ""}
              </LinkRouter>
              <Link
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#EB8900"
              >
                Self Reflection Journal
              </Link>
            </Breadcrumbs>
          </Box>
          {journalData.length > 0 ? (
            journalData.map((lesson: any, index: number) => {
              return (
                <Accordion
                  key={lesson?.id}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px !important",
                    mb: 2.5,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMore sx={{ fontSize: 24 }} />
                      </IconButton>
                    }
                    sx={{
                      ".Mui-expanded": {
                        m: "12px 0px !important",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        py: 0.5,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight={"medium"}
                        color={
                          expanded !== `panel${index}` ? "#111928" : "#EB8900"
                        }
                        mb={0.5}
                      >
                        {lesson?.name}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Divider />
                    <Box sx={{ p: 2.5 }}>
                      <LessonWiseJournal data={lesson?.units} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="regular"
                color={"gray"}
              >
                No Journal Data
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReflectionJournal;

const LessonWiseJournal = ({ data }: any) => {
  return data.map((unit: any, index: number) => {
    return (
      <Box key={unit?.id}>
        {index > 0 && <Divider sx={{ my: 2 }} />}
        <Typography variant="subtitle1" fontWeight={"regular"} color="#384670">
          {"Unit " + unit?.position}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={"medium"}
          color="#202d50"
          textTransform={"capitalize"}
        >
          {unit?.name}
        </Typography>
        {unit?.sliderText ? (
          <>
            <Typography
              variant="h6"
              fontWeight={"regular"}
              color="#384670"
              sx={{ mb: 1 }}
              textTransform={"capitalize"}
            >
              {unit?.sliderText}
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={"regular"}
              color="#384670"
            >
              {typeof unit?.journal?.numberValue === "number"
                ? unit?.journal?.numberValue
                : "-"}
            </Typography>
          </>
        ) : (
          <Typography
            variant="subtitle1"
            fontWeight={"regular"}
            color="#384670"
          >
            No slider input available
          </Typography>
        )}
      </Box>
    );
  });
};
