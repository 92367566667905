import { Backdrop, Box } from "@mui/material";
import { PreviewImageWrapper } from "./style";

const ImagePreview = ({ open, handleClose, url }: any) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={() => {
        handleClose(false, null);
      }}
    >
      <Box
        sx={PreviewImageWrapper}
        onClick={() => {
          handleClose(false, null);
        }}
      >
        <img
          src={url}
          className="previewImage"
          alt="foot"
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    </Backdrop>
  );
};

export default ImagePreview;
