import { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  Switch,
} from "@mui/material";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import http from "../../../utils/http";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any[];
  refreshPage: () => void;
  type: string;
};

const EditSettingsModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
  type,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState<any[]>(data);

  const submitHandler = async () => {
    try {
      setButtonLoader(true);
      let res: AxiosResponse;
      if (type === "lang") {
        res = await http.patch("/settings/app-language", {
          config: formData,
        });
      } else {
        const body: any = {};
        formData.forEach((item) => {
          body[item.id] = item.isEnabled;
        });
        res = await http.patch("/settings/app-widget", {
          data: body,
        });
      }
      toastMessage("success", res?.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      setButtonLoader(false);
    }
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setFormData((formData) => {
      return formData.map((item) => {
        if (item.id === id) {
          const newItem = {
            ...item,
            isEnabled: event.target.checked,
          };
          return newItem;
        } else {
          return item;
        }
      });
    });
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={`Edit ${type === "lang" ? "Language" : "Widget"} Settings`}
          onCloseClick={closeModal}
        />
        {formData.map((item) => {
          return (
            <FormControl
              sx={{ ...InputWrapper, mb: 2, flexDirection: "row" }}
              key={item?.id}
            >
              <FormLabel sx={LabelStyle}>{item?.label}</FormLabel>
              <Switch
                checked={item?.isEnabled}
                onChange={(e) => changeHandler(e, item?.id)}
              />
            </FormControl>
          );
        })}

        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditSettingsModal;
