import { useEffect, useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import { errorToastMessage } from "../../utils/toast";
import EditSettingsModal from "./EditSettingsModal";
import http from "../../utils/http";
import { NoDataContainer, StyledTableCell } from "../Common/styles/table";
import { Edit, Visibility } from "@mui/icons-material";
import { langMap, LANGUAGES } from "../../utils/lang";
import AboutUsPreview from "./AboutUsPreviewModal";
import AboutUsEditModal from "./AboutUsEditModal";
import EditOnboardingSettingsModal from "./EditOnboardingSettingsModal";
import EditInactivitySettingsModal from "./EditInactivitySettingsModal";
import PrivacyPolicyEditModal from "./PrivacyPolicyEditModal";

export interface onboardingSetting {
  label: string;
  value: string;
  key: string;
  type: string;
}

export interface inactivityDataInterface {
  label: string;
  title: string;
  body: string;
  days: string;
  key: string;
  lang: string;
}

const Settings = () => {
  const [jwtLoading, setJwtLoading] = useState(true);
  const [onboardingLoading, setOnboardingLoading] = useState(true);
  const [inactivityLoading, setInactivityLoading] = useState(true);
  const [unitInactivityLoading, setUnitInactivityLoading] = useState(true);

  const [jwtSettingsData, setJwtSettingsData] = useState<any>([]);
  const [onboardingSettingsData, setOnboardingSettingsData] = useState<
    onboardingSetting[]
  >([]);
  const [inactivityData, setInactivityData] = useState<
    inactivityDataInterface[]
  >([]);
  const [unitInactivityData, setUnitInactivityData] = useState<
    inactivityDataInterface[]
  >([]);

  const [showJwtModal, setShowJwtModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showInactivityModal, setShowInactivityJwtModal] = useState(false);
  const [showUnitInactivityModal, setShowUnitInactivityJwtModal] =
    useState(false);

  const [aboutUsLang, setAboutUsLang] = useState("");
  const [aboutUsMode, setAboutUsMode] = useState<"edit" | "preview">("edit");

  const [privacyPolicyLang, setPrivacyPolicyLang] = useState("");

  const [jwtToggleLoader, setJwtToggleLoader] = useState(false);
  const [onboardingToggleLoader, setOnboardingToggleLoader] = useState(false);
  const [activityToggleLoader, setInactivityToggleLoader] = useState(false);
  const [unitActivityToggleLoader, setUnitInactivityToggleLoader] =
    useState(false);

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        setJwtLoading(true);
        const res: AxiosResponse = await http.get("auth-settings");
        const data = res.data?.data;
        setJwtSettingsData([
          {
            name: "Participant Access token",
            value:
              data?.appAccessTokenExpiration?.substring(
                0,
                data?.appAccessTokenExpiration.length - 1
              ) || "15",
            unit:
              data?.appAccessTokenExpiration?.substring(
                data?.appAccessTokenExpiration.length - 1
              ) === "m"
                ? "minutes"
                : "day(s)",
            unitValue: data?.appAccessTokenExpiration?.substring(
              data?.appAccessTokenExpiration.length - 1
            ),
          },
          {
            name: "Participant Refresh token",
            value:
              data?.appRefreshTokenExpiration?.substring(
                0,
                data?.appRefreshTokenExpiration.length - 1
              ) || "15",
            unit:
              data?.appRefreshTokenExpiration?.substring(
                data?.appRefreshTokenExpiration.length - 1
              ) === "m"
                ? "minutes"
                : "day(s)",
            unitValue: data?.appRefreshTokenExpiration?.substring(
              data?.appRefreshTokenExpiration.length - 1
            ),
          },
          {
            name: "Admin Panel Access token",
            value:
              data?.cmsAccessTokenExpiration?.substring(
                0,
                data?.cmsAccessTokenExpiration.length - 1
              ) || "15",
            unit:
              data?.cmsAccessTokenExpiration?.substring(
                data?.cmsAccessTokenExpiration.length - 1
              ) === "m"
                ? "minutes"
                : "day(s)",
            unitValue: data?.cmsAccessTokenExpiration?.substring(
              data?.cmsAccessTokenExpiration.length - 1
            ),
          },
          {
            name: "Admin Panel Refresh token",
            value:
              data?.cmsRefreshTokenExpiration?.substring(
                0,
                data?.cmsRefreshTokenExpiration.length - 1
              ) || "15",
            unit:
              data?.cmsRefreshTokenExpiration?.substring(
                data?.cmsRefreshTokenExpiration.length - 1
              ) === "m"
                ? "minutes"
                : "day(s)",
            unitValue: data?.cmsRefreshTokenExpiration?.substring(
              data?.cmsRefreshTokenExpiration.length - 1
            ),
          },
        ]);
        setJwtLoading(false);
      } catch (err) {
        setJwtLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchSettingsData();
  }, [jwtToggleLoader]);

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        setOnboardingLoading(true);
        const res: AxiosResponse = await http.get(`/app/settings/other`);
        const formattedData: onboardingSetting[] = res.data?.data.map(
          (item: any) => {
            let name = item?.name?.replace(/([A-Z])/g, " $1");
            name = name.charAt(0).toUpperCase() + name.slice(1);
            let url = "";
            if (item?.value?.startsWith("https://storage.googleapis.com/")) {
              try {
                const obj = new URL(item.value);
                url = obj.origin + obj.pathname;
              } catch (err) {}
            }
            return {
              label: name + " - " + langMap[item.lang],
              key: item.id,
              value: url || item.value,
              type: item.name.toLowerCase().includes("link") ? "link" : "text",
            };
          }
        );
        setOnboardingSettingsData(formattedData);
        setOnboardingLoading(false);
      } catch (err) {
        setOnboardingLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchSettingsData();
  }, [onboardingToggleLoader]);

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        setInactivityLoading(true);
        const res: AxiosResponse = await http.get(
          `/app/settings/app-inactivity-reminder`
        );
        const formattedData: inactivityDataInterface[] = res.data.data.map(
          (item: any) => {
            let name = item?.name?.replace(/([A-Z])/g, " $1");
            name = name.charAt(0).toUpperCase() + name.slice(1);
            return {
              label: name + " - " + langMap[item.lang],
              title: item?.value?.title || "",
              body: item?.value?.body || "",
              lang: item?.lang,
              days: item?.value?.days || 15,
              key: item?.id,
            };
          }
        );
        setInactivityData(formattedData);
        setInactivityLoading(false);
      } catch (err) {
        setInactivityLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchSettingsData();
  }, [activityToggleLoader]);

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        setUnitInactivityLoading(true);
        const res: AxiosResponse = await http.get(
          `/app/settings/unit-inactivity-reminder`
        );
        const formattedData: inactivityDataInterface[] = res.data.data.map(
          (item: any) => {
            let name = item?.name?.replace(/([A-Z])/g, " $1");
            name = name.charAt(0).toUpperCase() + name.slice(1);
            return {
              label: name + " - " + langMap[item.lang],
              title: item?.value?.title || "",
              body: item?.value?.body || "",
              lang: item?.lang,
              days: item?.value?.days || 15,
              key: item?.id,
            };
          }
        );
        setUnitInactivityData(formattedData);
        setUnitInactivityLoading(false);
      } catch (err) {
        setUnitInactivityLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchSettingsData();
  }, [unitActivityToggleLoader]);

  const openJwtModal = () => {
    setShowJwtModal(true);
  };

  const closeJwtModal = () => {
    setShowJwtModal(false);
  };

  const refreshJwtPage = () => {
    setJwtToggleLoader((prev) => !prev);
  };

  const openOnboardingModal = () => {
    setShowOnboardingModal(true);
  };

  const closeOnboardingModal = () => {
    setShowOnboardingModal(false);
  };

  const refreshOnboardingPage = () => {
    setOnboardingToggleLoader((prev) => !prev);
  };

  const openInactivityModal = () => {
    setShowInactivityJwtModal(true);
  };

  const closeInactivityModal = () => {
    setShowInactivityJwtModal(false);
  };

  const refreshInactivityPage = () => {
    setInactivityToggleLoader((prev) => !prev);
  };

  const openUnitInactivityModal = () => {
    setShowUnitInactivityJwtModal(true);
  };

  const closeUnitInactivityModal = () => {
    setShowUnitInactivityJwtModal(false);
  };

  const refreshUnitInactivityPage = () => {
    setUnitInactivityToggleLoader((prev) => !prev);
  };

  const editAboutUs = (lang: string) => {
    setAboutUsMode("edit");
    setAboutUsLang(lang);
  };

  const previewAboutUs = (lang: string) => {
    setAboutUsMode("preview");
    setAboutUsLang(lang);
  };

  const closeAboutUs = () => {
    setAboutUsLang("");
  };

  const editPrivacyPolicy = (lang: string) => {
    setPrivacyPolicyLang(lang);
  };

  const previewPrivacyPolicy = (lang: string) => {
    window.open(`/privacy-policy/${lang}`, "_blank");
  };

  const closePrivacyPolicy = () => {
    setPrivacyPolicyLang("");
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Settings
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {!jwtLoading && (
            <Button variant="contained" onClick={openJwtModal}>
              Edit JWT Settings
            </Button>
          )}
          {!onboardingLoading && onboardingSettingsData.length > 0 && (
            <Button variant="contained" onClick={openOnboardingModal}>
              Edit Onboarding Settings
            </Button>
          )}
          {!inactivityLoading && inactivityData.length > 0 && (
            <Button variant="contained" onClick={openInactivityModal}>
              Edit App Inactivity Settings
            </Button>
          )}
          {!unitInactivityLoading && unitInactivityData.length > 0 && (
            <Button variant="contained" onClick={openUnitInactivityModal}>
              Edit Unit Inactivity Settings
            </Button>
          )}
        </Box>
      </StyledHeader>

      <Box
        sx={{
          p: 2.5,
          height: "calc(100% - 85px)",
          overflow: "auto",
        }}
      >
        <Typography fontSize={24} fontWeight="bold" mb={2}>
          JWT expiry settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Name</StyledTableCell>
              <StyledTableCell>Expiry Time</StyledTableCell>
            </TableRow>
          </TableHead>
          {!jwtLoading && (
            <TableBody>
              {jwtSettingsData?.map(
                (row: { name: string; value: string; unit: string }) => (
                  <TableRow key={row?.name}>
                    <StyledTableCell>{row?.name}</StyledTableCell>
                    <StyledTableCell>
                      {row?.value + " " + row?.unit}
                    </StyledTableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          )}
        </Table>
        {jwtLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!jwtLoading && jwtSettingsData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        <Typography fontSize={24} fontWeight="bold" my={2}>
          App Inactivity settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Name</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          {!inactivityLoading &&
            inactivityData?.map((row) => {
              return (
                <TableBody key={row.key}>
                  <TableRow key={"title"}>
                    <StyledTableCell>{row?.label + " Title"}</StyledTableCell>
                    <StyledTableCell>{row?.title}</StyledTableCell>
                  </TableRow>
                  <TableRow key={"body"}>
                    <StyledTableCell>{row?.label + " Body"}</StyledTableCell>
                    <StyledTableCell>{row?.body}</StyledTableCell>
                  </TableRow>
                  <TableRow key={"days"}>
                    <StyledTableCell>{row?.label + " Days"}</StyledTableCell>
                    <StyledTableCell>{row?.days}</StyledTableCell>
                  </TableRow>
                </TableBody>
              );
            })}
        </Table>
        {inactivityLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!inactivityLoading && inactivityData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        <Typography fontSize={24} fontWeight="bold" my={2}>
          Unit Inactivity settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Name</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          {!unitInactivityLoading &&
            unitInactivityData?.map((row) => {
              return (
                <TableBody key={row.key}>
                  <TableRow key={"title"}>
                    <StyledTableCell>{row?.label + " Title"}</StyledTableCell>
                    <StyledTableCell>{row?.title}</StyledTableCell>
                  </TableRow>
                  <TableRow key={"body"}>
                    <StyledTableCell>{row?.label + " Body"}</StyledTableCell>
                    <StyledTableCell>{row?.body}</StyledTableCell>
                  </TableRow>
                  <TableRow key={"days"}>
                    <StyledTableCell>{row?.label + " Days"}</StyledTableCell>
                    <StyledTableCell>{row?.days}</StyledTableCell>
                  </TableRow>
                </TableBody>
              );
            })}
        </Table>
        {unitInactivityLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!unitInactivityLoading && unitInactivityData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        <Typography fontSize={24} fontWeight="bold" my={2}>
          About Us
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Language</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {LANGUAGES.map(({ label, value }) => {
              return (
                <TableRow key={value}>
                  <StyledTableCell>{label}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      startIcon={<Edit fontSize="small" />}
                      sx={{ mr: 2 }}
                      onClick={() => editAboutUs(value)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<Visibility fontSize="small" />}
                      onClick={() => previewAboutUs(value)}
                    >
                      Preview
                    </Button>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Typography fontSize={24} fontWeight="bold" my={2}>
          Privacy Policy
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Language</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {LANGUAGES.map(({ label, value }) => {
              return (
                <TableRow key={value}>
                  <StyledTableCell>{label}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      startIcon={<Edit fontSize="small" />}
                      sx={{ mr: 2 }}
                      onClick={() => editPrivacyPolicy(value)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<Visibility fontSize="small" />}
                      onClick={() => previewPrivacyPolicy(value)}
                    >
                      Preview
                    </Button>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Typography fontSize={24} fontWeight="bold" my={2}>
          Onboarding settings
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: "30%" }}>Name</StyledTableCell>
              <StyledTableCell>Value</StyledTableCell>
            </TableRow>
          </TableHead>
          {!onboardingLoading && (
            <TableBody>
              {onboardingSettingsData?.map((row) => (
                <TableRow key={row?.key}>
                  <StyledTableCell>{row?.label}</StyledTableCell>
                  <StyledTableCell>{row?.value || "-"}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {onboardingLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {!onboardingLoading && onboardingSettingsData.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
      </Box>
      {showJwtModal && (
        <EditSettingsModal
          showModal={showJwtModal}
          closeModal={closeJwtModal}
          data={jwtSettingsData}
          refreshPage={refreshJwtPage}
        />
      )}

      {showOnboardingModal && (
        <EditOnboardingSettingsModal
          showModal={showOnboardingModal}
          closeModal={closeOnboardingModal}
          data={onboardingSettingsData}
          refreshPage={refreshOnboardingPage}
        />
      )}

      {showInactivityModal && (
        <EditInactivitySettingsModal
          showModal={showInactivityModal}
          closeModal={closeInactivityModal}
          data={inactivityData}
          refreshPage={refreshInactivityPage}
          type="app"
          title="App"
        />
      )}

      {showUnitInactivityModal && (
        <EditInactivitySettingsModal
          showModal={showUnitInactivityModal}
          closeModal={closeUnitInactivityModal}
          data={unitInactivityData}
          refreshPage={refreshUnitInactivityPage}
          type="unit"
          title="Unit"
        />
      )}

      {aboutUsLang &&
        (aboutUsMode === "preview" ? (
          <AboutUsPreview closeModal={closeAboutUs} lang={aboutUsLang} />
        ) : (
          <AboutUsEditModal lang={aboutUsLang} closeModal={closeAboutUs} />
        ))}

      {privacyPolicyLang && (
        <PrivacyPolicyEditModal
          closeModal={closePrivacyPolicy}
          lang={privacyPolicyLang}
        />
      )}
    </>
  );
};

export default Settings;
