import { Box, Typography } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import { useAppDispatch } from "../../../../Redux/hooks";
import { changeSectionValue } from "../../../../Redux/reducers/cmsBuilderSlice";

import { quillFormats, quillModules } from "../../../../utils/reactquill";
import TagsAndHighlights from "./TagsAndHighlights";

type Props = {
  section: any;
  index: number;
};

const ContentText: React.FC<Props> = ({ index, section }) => {
  const dispatch = useAppDispatch();

  const handleChange = (data: any) => {
    dispatch(
      changeSectionValue({
        value: data,
        type: "value",
        index,
      })
    );
  };

  return (
    <>
      <Typography variant="h6" mb={2} fontWeight={"medium"}>
        Text
      </Typography>
      <Box sx={{ mb: 2 }}>
        <ReactQuill
          modules={quillModules}
          formats={quillFormats}
          placeholder="Text content"
          defaultValue={section.value}
          className="quill-container"
          onChange={handleChange}
        />
      </Box>
      <TagsAndHighlights section={section} sectionIndex={index} />
    </>
  );
};

export default ContentText;
