import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../Redux/hooks";
import { setCloudDefaults } from "../../Redux/reducers/cloudSlice";

const CloudUrlLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type") || "tag";
    const page = parseInt(searchParams.get("page") || "0") || 0;
    const search = searchParams.get("search") || "";

    dispatch(
      setCloudDefaults({
        search,
        page,
        type,
      })
    );
  }, [searchParams, dispatch]);

  return <></>;
};

export default React.memo(CloudUrlLoader);
