import { Box } from "@mui/material";

import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import AdminHeader from "./AdminHeader";
import AdminList from "./AdminList";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setAdministratorsType } from "../../../Redux/reducers/administratorsSlice";
import AdminUrlSetter from "./AdminUrlSetter";
import AdminUrlLoader from "./AdminUrlLoader";

const Administrators = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded } = useAppSelector((state) => state.administrators);

  const handleChange = (_: any, newValue: string) => {
    dispatch(setAdministratorsType(newValue));
  };

  return urlLoaded ? (
    <>
      <AdminHeader />
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Master Admin"
            value="master_admin"
            {...a11yProps(0)}
          />
          <StyledTab label="Admin" value="admin" {...a11yProps(1)} />
          <StyledTab label="Doctor" value="doctor" {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 146px)",
          overflow: "auto",
        }}
      >
        <MainTabPanel value={type} index={type}>
          <AdminList />
        </MainTabPanel>
      </Box>
      <AdminUrlSetter />
    </>
  ) : (
    <AdminUrlLoader />
  );
};

export default Administrators;
