import { SxProps } from "@mui/material";

export const BuilderWrapper: SxProps = {
  padding: "20px",
  height: "calc(100% - 85px)",
  overflow: "auto",
  display: "flex",
  gap: "20px",
  alignItems: "flex-start",
  position: "relative",
  scrollBehavior: "smooth",
};

export const QuestionPickerWrapper: SxProps = {
  minHeight: "250px",
  maxHeight: "100%",
  overflow: "hidden",
  padding: "20px",
  width: "360px",
  borderRadius: "16px",
  boxShadow: 2,
  top: 0,
  position: "sticky",
  "&:hover": {
    overflow: "auto",
  },
};

export const QuestionPickerItemStyle: SxProps = {
  mb: 2,
  padding: 2,
  borderRadius: "16px",
  cursor: "pointer",
  height: "50px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  border: 1,
  borderColor: "divider",
};

export const QuestionPickerItemSelectedStyle: SxProps = {
  ...QuestionPickerItemStyle,
  borderColor: "primary.main",
};

export const EditorWrapper: SxProps = {
  flex: 1,
  minWidth: "1px",
};

export const editorBlock: SxProps = {
  border: 1,
  borderColor: "divider",
  padding: 2,
  borderRadius: "16px",
  boxShadow: 2,
};

export const editorBlockHeader: SxProps = {
  borderBottom: 1,
  borderBottomColor: "divider",
  mb: 2,
  paddingBottom: 2,
  fontWeight: "bold",
};

export const SubQuestionHeader: SxProps = {
  borderBottom: 1,
  borderBottomColor: "divider",
  mb: 2,
  paddingBottom: 2,
  display: "flex",
  alignItems: "center",
};
