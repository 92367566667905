import React from "react";
import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import {
  ImageContainer,
  NoDataContainer,
  medicationDetails,
  medicationName,
} from "./style";
import { CalendarToday } from "@mui/icons-material";

const Appointments = ({ data }: any) => {
  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "divider",
        p: 2.5,
        m: 2.5,
      }}
    >
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Appointments
      </Typography>
      {data.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(268px, 1fr))",
            gridGap: "20px",
          }}
        >
          {data.map((item: any, index: number) => (
            <Box key={index} sx={medicationDetails}>
              <Box sx={medicationName}>
                <Box
                  sx={{
                    ...ImageContainer,
                    bgcolor: "rgba(33, 107, 100, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CalendarToday htmlColor="#216B64" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="medium"
                    color="#216B64"
                    textTransform={"capitalize"}
                  >
                    {item?.doctorName}
                  </Typography>
                  <Box
                    sx={{
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="#212121"
                      fontWeight="medium"
                    >
                      {item?.specialization}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="#212121"
                      fontWeight="medium"
                    >
                      {item?.location?.hospitalName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      color: "#676970",
                      padding: "6px",
                      fontSize: "15px",
                      fontWeight: 500,
                      backgroundColor: "#F2F2F3",
                      borderRadius: "5px",
                      alignSelf: "flex-start",
                    }}
                  >
                    {DateTime.fromISO(item?.appointmentOn).toFormat("hh:mm a")}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={NoDataContainer}>
          <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
            Data Unavailable
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Appointments;
