import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { useAppDispatch } from "../../../Redux/hooks";
import {
  setParticipantToggle,
  setSelectedRows,
} from "../../../Redux/reducers/participantsSlice";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  selectedRows: GridRowSelectionModel;
};

const AssignDoctorModal = ({ showModal, closeModal, selectedRows }: Props) => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedDoc, setSelectedDoc] = useState<any>(null);

  const dispatch = useAppDispatch();

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setLoading(true);
            let url = `/doctors/list?search=${value}`;

            const res: AxiosResponse = await http.get(url);
            const doctors = res.data?.data.map((doctor: any) => {
              return {
                id: doctor?.id,
                label: doctor?.code,
              };
            });
            setOptions(doctors);
            setLoading(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setLoading(false);
        }
      }, 500),
    []
  );

  const submitHandler = async () => {
    try {
      if (!selectedDoc) {
        toastMessage("warning", "Please select a doctor to assign");
        return;
      }
      setButtonLoader(true);
      const body: any = {
        addIds: selectedRows,
        removeIds: [],
      };
      const res = await http.post(
        `/doctors/doctor/${selectedDoc?.id}/participants`,
        body
      );
      toastMessage("success", res.data.message);
      closeModal();
      dispatch(setParticipantToggle());
      dispatch(setSelectedRows([]));
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Assign Doctor"} onCloseClick={closeModal} />
        <FormControl sx={InputWrapper}>
          <FormLabel sx={LabelStyle} htmlFor="title">
            Select Doctor
          </FormLabel>
          <Autocomplete
            filterOptions={(x) => x}
            onInputChange={(_1: any, value: any, reason: string) => {
              if (reason === "input") handleSearch(value);
            }}
            onChange={(_1: any, value: any) => {
              setSelectedDoc(value);
            }}
            options={options}
            value={selectedDoc || null}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            loading={loading}
            loadingText={<CircularProgress size={20} />}
            noOptionsText="No Results"
            clearOnBlur={true}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search doctors by typing..."
              />
            )}
          />
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
            mt: 5,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignDoctorModal;
