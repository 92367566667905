import { SxProps } from "@mui/material";

export const JournalWrapper: SxProps = {
  pt: 1.5,
  pb: 5,
  px: 2.5,
  bgcolor: "#f9fafb",
  height: "100%",
  overflow: "auto",
};
export const JournalCard: SxProps = {
  borderRadius: "8px",
  p: 2.5,
  border: 1,
  borderColor: "divider",
  bgcolor: "#FFFFFF",
};

export const headerContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  mb: 5,
  pt: 1.25,
};

export const profileCard: SxProps = {
  borderRadius: "8px",
  border: 1,
  width: "35%",
  p: 3,
  borderColor: "divider",
};
