import { Box, Typography } from "@mui/material";
import { borderedContainer, footContainerWrapper } from "./style";
import { StepsIcon } from "../../Common/assets/FoodJournalIcons";

const Steps = ({ data }: any) => {
  return (
    <Box sx={footContainerWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Steps
      </Typography>
      <Box sx={borderedContainer}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.75, p: 1.75 }}>
          <StepsIcon />
          {data ? (
            <Typography variant="h6" color="#212121" fontWeight="medium">
              {data?.steps}
              <Typography
                component="span"
                variant="body1"
                fontWeight="regular"
                color="#676970"
              >
                {" "}
                Steps
              </Typography>
            </Typography>
          ) : (
            <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
              Data Unavailable
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Steps;
