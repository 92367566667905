import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Loader from "./Loader";
import { Avatar, Box, Typography } from "@mui/material";
import { BotMessageWrapper } from "../style";

const BotMessage: React.FC<any> = ({ step, showNextType, fetchNextBot }) => {
  const [loader, setLoader] = useState(true);
  const [hideReference, setHideReference] = useState(true);

  useEffect(() => {
    const delay = step?.delay || 1000;
    const timeout = setTimeout(() => {
      setLoader(false);
      if (step.nextBotId && step.nextStepId) {
        fetchNextBot(step.nextStepId);
      } else {
        showNextType(step.end);
      }
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [step, setLoader, showNextType, fetchNextBot]);

  const showReference = () => {
    setHideReference(false);
  };

  return loader || step.text ? (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {/* <img src={glowLogo2} alt="bot-avatar" className="bot-avatar" /> */}
      <Avatar sx={{ height: 40, width: 40, mr: 1 }} />
      {loader ? (
        <Loader />
      ) : (
        <Box sx={BotMessageWrapper}>
          {step?.type === "image" && (
            <img
              alt="bot-message"
              src={step?.attachmentUrl}
              className="bot-image"
            />
          )}
          {step?.type === "video" && (
            <ReactPlayer
              className="bot-video"
              controls
              url={step?.attachmentUrl}
            />
          )}
          {step.type === "link" ? (
            <Typography
              component={"a"}
              sx={{ whiteSpace: "pre-wrap" }}
              href={step?.attachmentUrl}
              target="_blank"
              rel="noreferrer"
              variant="subtitle1"
            >
              {step?.text}
            </Typography>
          ) : step.type === "reference" && hideReference ? (
            <Typography
              sx={{
                whiteSpace: "pre-wrap",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={showReference}
              variant="subtitle1"
            >
              Show the list of references
            </Typography>
          ) : (
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap" }}>
              {step?.text}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  ) : null;
};

export default React.memo(BotMessage);
